
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import styled from 'styled-components';
import { GraphUpArrow, BoxArrowUpRight, CheckSquare, ExclamationSquareFill } from 'react-bootstrap-icons';
import ResultsPageOverview from "./ResultsPageOverview";

import ResultsPageSegments from "./ResultsPageSegments";
import ResultsPageKeyphrases from "./ResultsPageKeyphrases";
import { suppressDeprecationWarnings } from "moment";
import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

const ReportRowItem = styled.div`
    margin-top:4px;
    float:left;
    width: 125px;
    margin-right: -125px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1em;
`;
const ReportRowDetails = styled.div`
    margin-left:125px;
    overflow:hidden;
`;
const ReportRow = styled.div`
    margin-bottom:10px;
    min-height: 20px;
`;

class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";


    
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a> <a className="btn btn-outline-secondary" href={"/pricing/"}>See Pricing!</a> 
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayDomainAnalysisV1({jobDetails, reportParts}) {

    const renderTooltip = ({props, text}) => (
        <Tooltip {...props}>{text}</Tooltip>
    );

  return (
    <>
      {jobDetails?.results && 
      <div className="reportPart content" id="summary">
          
        <h1>Summary</h1>

            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Domain Overview <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"An overview of the content found on this URL"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Domain Name:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.domain?.name}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Title:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.summary?.title}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Canonical URL:</ReportRowItem>
                            <ReportRowDetails>
                                <span style={{"whiteSpace": "nowrap"}}><a href={jobDetails?.results?.url} target="_blank">
                                    <BoxArrowUpRight /> {jobDetails?.results?.url}</a>
                                </span>
                            </ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Description:</ReportRowItem>
                            <ReportRowDetails>{(!jobDetails?.results?.summary?.description)? <span className="text-danger">Missing description</span> : jobDetails?.results?.summary?.description}</ReportRowDetails>
                        </ReportRow>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Sitemaps <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"An overview of the content found on this URL"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Sitemaps:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.domain?.sitemaps && jobDetails?.results?.domain?.sitemaps.map((sm,i)=>{
                                return( <span key={"sm_"+i} style={{"whiteSpace": "nowrap"}}>{sm}<br/></span> )
                            })}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Total Page Count:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.sitemap && jobDetails?.results?.sitemap['n-pages']}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>First Mod:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.sitemap?.firstmod}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Last Mod:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.sitemap?.lastmod}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Last Mod Page:</ReportRowItem>
                            <ReportRowDetails>
                                { jobDetails?.results?.sitemap?.lastmod_page &&
                                    <span style={{"whiteSpace": "nowrap"}}>
                                        <Link to={"/tools/keyphrase-extractor/?url="+jobDetails?.results?.sitemap?.lastmod_page}><GraphUpArrow/> {jobDetails?.results?.sitemap?.lastmod_page}</Link>
                                    </span>
                                }
                            </ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>


        <h1 id="call-to-action" className="pt-4">Call To Action</h1>
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Offers Should Match Search Intent<OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Details about the offers detected on this website's homepage"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Headline (h1):</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.summary?.h1}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>e-commerce:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust?.produts}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Services:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust?.services}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Blog:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.trust?.blog) && (jobDetails?.results?.trust?.blog['passed-quick-check'])? <span><CheckSquare/> Included</span> : <span className="text-danger">Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Phone:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.social?.phone)? <span>Has "tel:" link</span> : <span></span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Affiliates:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.monetization?.affiliate && jobDetails?.results?.monetization?.affiliate.map((af,i)=>{
                                return( <span key={"af_"+i} style={{"whiteSpace": "nowrap","textTransform":"uppercase"}}>{af}<br/></span> )
                            })}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Ad-networks:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.monetization?.adnetworks && jobDetails?.results?.monetization?.adnetworks.map((an,i)=>{
                                return( <span key={"an_"+i} style={{"whiteSpace": "nowrap","textTransform":"uppercase"}}>{an}<br/></span> )
                            })}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>

        <h1 id="technical" className="pt-4">Technical</h1>

            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Content Management <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Details about the server and host"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Generator:</ReportRowItem>
                            <ReportRowDetails><span className='text-uppercase'>{jobDetails?.results?.generator?.type}</span></ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Server <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Details about the server and host"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Response:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.request?.status_code}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>content-encoding:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.request?.headers && jobDetails?.results?.request?.headers['content-encoding']}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>content-type:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.request?.headers && jobDetails?.results?.request?.headers['content-type']}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Server:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.request?.headers?.server}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Is Secure:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.security?.is_secure)? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> NOT SECURE</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Robots.txt:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.robots && jobDetails?.results?.robots['robots.txt'] && jobDetails?.results?.robots['robots.txt']['can_fetch']? <>Google Can Fetch</> : <span className="text-danger">Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Robots (meta):</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.robots?.meta}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        DNS <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Details about the server and host"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>A record:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.dns?.a? <><CheckSquare/> {jobDetails?.results?.dns?.a.length}</> : <><ExclamationSquareFill/> None</>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>AAAA record:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.dns?.aaaa? <><CheckSquare/> {jobDetails?.results?.dns?.aaaa.length}</> : <><ExclamationSquareFill/> None</>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>MX record:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.dns?.mx? <><CheckSquare/> {jobDetails?.results?.dns?.mx.length}</> : <span className="text-danger"><ExclamationSquareFill/> None</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>TXT record:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.dns?.txt? <><CheckSquare/> {jobDetails?.results?.dns?.txt.length}</> : <><ExclamationSquareFill/> None</>}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>

        { jobDetails?.results?.trust && <>
        <h1 id="trust" className="pt-4">Trust</h1>
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Indicators <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Detected text suggesting these pages exist"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>About:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust && (jobDetails?.results?.trust['about']) && (jobDetails?.results?.trust['about']['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Contact:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust && (jobDetails?.results?.trust['contact']) && (jobDetails?.results?.trust['contact']['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Affiliate Disclosure:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust && (jobDetails?.results?.trust['affiliate-disclosure']) && (jobDetails?.results?.trust['affiliate-disclosure']['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Privacy Policy:</ReportRowItem>
                            <ReportRowDetails>{jobDetails?.results?.trust && (jobDetails?.results?.trust['privacy-policy']) && (jobDetails?.results?.trust['privacy-policy']['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>
        
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Social Proof <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"Indicates that this Domain has social or not"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Facebook:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.social?.facebook) && (jobDetails?.results?.social?.facebook['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Instagram:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.social?.instagram) && (jobDetails?.results?.social?.instagram['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>Twitter:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.social?.twitter) && (jobDetails?.results?.social?.twitter['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                        <ReportRow>
                            <ReportRowItem>LinkedIn:</ReportRowItem>
                            <ReportRowDetails>{(jobDetails?.results?.social?.linkedin) && (jobDetails?.results?.social?.linkedin['passed-quick-check'])? <span><CheckSquare/> Passed</span> : <span className="text-danger"><ExclamationSquareFill/> Missing</span>}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>
        </>}
        <h1 id="meta" className="pt-4">Metadata</h1>

            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Homepage Meta <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"An overview of the content found on this URL"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <ReportRow>
                            <ReportRowItem>Description:</ReportRowItem>
                            <ReportRowDetails>{(!jobDetails?.results?.summary?.description)? <span className="text-danger"><ExclamationSquareFill/> Missing description</span> : <><CheckSquare/> Passed</>}</ReportRowDetails>
                        </ReportRow>
                    </div>
                </div>
            </div>

      </div>
    }
    </>
  );
}

export default DisplayDomainAnalysisV1;
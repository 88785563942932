import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Layout from "../../components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, createAnonymousUser } from "../authentication/firebase";
import { propTypes } from 'react-bootstrap/esm/Image';



const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";


const Datasources = () => {
    const [user, loading, error] = useAuthState(auth);
    const [accessToken, setAccessToken] = useState('');
    const [getMessage, setGetMessage] = useState({})
    const [userEntity, setUserEntity] = useState({})
  

    // user
    useEffect(() => {
      if (loading) return;
      if (!user) {
        // get anonymous user if not already logged in
        createAnonymousUser()
      } else {
        setAccessToken(user.accessToken)
      }
    }, [user, loading]);


    return (
      <>
        <Layout pageTitle="Catalog" allowAnonymous={false} hideTermsAccepted={false}>
          
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title">Catalog &gt; Datasources</h1>
              </div>
            </div>
            <ul className="nav nav-tabs page-header-tabs">
                <li className="nav-item">
                    <Link className="nav-link" to="/catalog/">
                    Topics
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/catalog/queries/">
                    Queries
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/catalog/content/">
                    Content
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link active" to="/catalog/datasources/">
                    Datasources
                    </Link>
                </li>
            </ul>
          </div>

          <div className="col-lg-12">

          </div>

        </Layout>
      </>
    );
};
  
export default Datasources;
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Layout from "../../components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, createAnonymousUser } from "../authentication/firebase";
import { propTypes } from 'react-bootstrap/esm/Image';



const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";


const Tools = () => {
    const [user, loading, error] = useAuthState(auth);
    const [accessToken, setAccessToken] = useState('');
    const [getMessage, setGetMessage] = useState({})
    const [userEntity, setUserEntity] = useState({})
  

    // user
    useEffect(() => {
      if (loading) return;
      if (!user) {
        // get anonymous user if not already logged in
        //createAnonymousUser()
      } else {
        setAccessToken(user.accessToken)
      }
    }, [user, loading]);

    const allTools_hidden = [
      {
        "id": "domain-to-clusters",
        "group": "playbook",
        "tool_type": "playbook",
        "name": "Extract and Cluster all Ranking Queries for a Domain",
        "description": "Input a domain and get a list of all ranking keywords grouped by cluster",
        "active":false,
        "button_text":"Coming Soon"
      },
      {
        "active": false,
        "id": "blog-buddy",
        "group": "playbook",
        "tool_type": "playbook",
        "name": "Blog Buddy",
        "description": "Identify pages to create or update based on competitors and semantic topical analysis.",
        "previews":[
        ],
        "pricing":{
          "summary":"1 job + add-ons",
          "credits":1
        },
        "callouts":[
          {"text": "First Topic Cluster FREE"}
        ],
        "button_secondary_text":"Quickstart Guide [ +Video ]",
        "button_secondary_href":"https://contentcurator.com/tools/keyword-clustering/"
      },
      {
        "id": "social-post-generator",
        "group": "tools",
        "tool_type": "tools",
        "name": "Social Post Generator",
        "description": "Uses the same style, format, and tone of the author to create a new social post on any topic.",
        "infoUrl": "",
        "pricing":{
          "summary":"1 job",
          "credits":1
        },
        "active":false,
        "button_text":"Coming Soon"
      },
    ]
    const allTools = [
      {
        "id": "keyword-clustering",
        "group": "playbook",
        "tool_type": "playbook",
        "name": "Keyword Clustering",
        "description": "Group keywords using using organic SERP similarity",
        "previews":[
          {
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgIDIu4v3Cgw/results/6572937771483136", 
            "title":"1000 Keywords about 'stem toys'"
          }
        ],
        "pricing":{
          "summary":"1 job + 1 credit per kw + add-ons",
          "credits":1
        },
        "callouts":[
          {"text": "First 500 Keywords FREE"}
        ],
        "button_secondary_text":"Quickstart Guide [ +Video ]",
        "button_secondary_href":"https://contentcurator.com/tools/keyword-clustering/"
      },
      {
        "id": "serp-similarity",
        "group": "playbook",
        "tool_type": "playbook",
        "name": "Bulk Serp Similarity",
        "description": "Paste a list of keywords to summarize as groups of queries and their top SERP features",
        "previews":[
          {
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgIDIm-HVCgw/results/5292242948325376",
            "title": "35 queries about 'STEM LEGO'"
          }
        ],
        "pricing":{
          "summary":"1 job + 1 credit per kw + add-ons",
          "credits":1
        },
        "callouts":[
          {"text": "First 500 Keywords FREE"}
        ],
        "button_secondary_text":"Basic Example [ +Video ]",
        "button_secondary_href":"https://contentcurator.com/keyword-clustering-example-for-seo-and-topical-authority-stem-toys/"
      },
      {
        "id": "niche-discovery",
        "group": "tools",
        "tool_type": "tools",
        "name": "Niche Competitor Discovery",
        "description": "A top down exploration to find new topics and niche websites within a niche.",
        "pricing":{
          "summary":"1 job",
          "credits":1
        },
        "previews":[
          {
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgICo1dqTCQw/results/4622648487182336",
            "title": "Travel > Asia > Thailand"
          }
        ]
      },
      {
        "id": "domain-analysis",
        "group": "tools",
        "tool_type": "tools",
        "name": "Domain Analysis",
        "description": "Enter any URL to run a technical analysis at the domain level.", 
        "active":true,
        "pricing":{
          "summary":"1 job",
          "credits":1
        }
      },
      {
        "id": "page-inspection",
        "group": "tools",
        "tool_type": "tools",
        "name": "Page Inspection",
        "description": "Enter one URL for an article, post, page and get a full page inspection.",
        "infoUrl": "",
        "pricing":{
          "summary":"1 job",
          "credits":1
        }
      },
      {
        "id": "brief-draft",
        "group": "tools",
        "tool_type": "tools",
        "name": "AI Brief and First Draft Article",
        "description": "Use AI to write a brief and a first draft article.",
        "infoUrl": "",
        "pricing":{
          "summary":"1 job",
          "credits":1
        },
        "preiviews":[
          {
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgICo-suJCAw/results/5448409435602944",
            "title":"How to make $ with AI?"
          }
        ]
      },
      {
        "id": "keyword-generator",
        "group": "tools",
        "tool_type": "tools",
        "name": "Keyword List Generator",
        "description": "Quickly build a list of keywords using AI, PAA, and Google Suggest.",
        "infoUrl": "",
        "previews":[
          {
            "title":"Keywords about tourism hui han",
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgICohMb3CQw/results/5641923482091520"
          }
        ],
        "pricing":{
          "summary":"1 job",
          "credits":1
        },
      },
      {
        "id": "bulk-keyword-expansion",
        "group": "tools",
        "tool_type": "tools",
        "name": "Bulk Keyword Expansion",
        "description": "Upload a list of keywords and get back all the PAA and Related Queries for each.",
        "previews":[
        ],
        "pricing":{
          "summary":"1 job + 1 credit per kw + add-ons",
          "credits":1
        },
        "callouts":[
          {"text": "First 500 Keywords FREE"}
        ],
      },
      {
        "id": "bulk-url-topics",
        "group": "tools",
        "tool_type": "tools",
        "name": "Bulk Extract Topics from URLs",
        "description": "Upload a list of keywords to create clusters using SERP clustering with semantic similarity",
        "previews":[
          {
            "url":"/analysis/agtjYm90LWVuZ2luZXIpCxIHcHJvamVjdBiAgICostWICwwLEghhbmFseXNpcxiAgIDI7tvqCAw/reports/6567028131364864", 
            "title":"Topics extracted from 4 URLs about Tourism Hua Hin"
          }
        ],
        "pricing":{
          "summary":"1 job + 1 credit per URL",
          "credits":1
        },
        "callouts":[
          {"text": "Limit 20 URLs as a guest. First 500 URLs FREE"}
        ],
        "button_secondary_text":"Learn More",
        "button_secondary_href":"https://contentcurator.com/tools/bulk-url-topics/"
      },
    ]

    const available = {}
    const availablePlaybooks = {}
    const availableTools = {}
    const availableIntegrations = {}

    // loop over all tools and add to the object as appropraite
    allTools.forEach(function (arrayItem) {
      available[arrayItem['id']] = arrayItem
      if(arrayItem['group']=='playbook'){
        availablePlaybooks[arrayItem['id']] = arrayItem
      }
      if(arrayItem['group']=='tools'){
        availableTools[arrayItem['id']] = arrayItem
      }
      if(arrayItem['group']=='integrations'){
        availableIntegrations[arrayItem['id']] = arrayItem
      }
    });




    const displayToolBox = ({i, short=false, tool_type, tool_id, tool}) => {

      return (
        <div className="col mb-5" key={"tool_"+i}>
          <div className="card h-100">
            <div className="card-body pb-0">
              <div className="row align-items-center mb-2">
                <div className="col-12">
                  
                    { (tool.active==false)? 
                      <b>{tool.name}</b> :
                      <h4 className="mb-1"><Link to={'/'+tool_type+'/'+tool_id+'/'}>{tool.name}</Link></h4>
                    }
                    
                  
                </div>

                <div className="col-3 text-end">
                </div>
              </div>

              <p>{tool.description}</p>

            {!short && 
              
              <ul className="list-pointer list-pointer-sm list-pointer-secondary">
                
                { tool?.callouts && 
                  <li className="list-pointer-item">{tool?.callouts[0]?.text}</li>
                }
                { tool?.pricing?.summary &&
                <li className="list-pointer-item">Pricing: {tool?.pricing?.summary}</li>
                }
                {
                tool?.previews?.map( (preview,i) => {return <li className="list-pointer-item">Sample Output: <Link to={preview?.url}>{preview?.title}</Link></li> })
                }
                
              </ul>
            }
            </div>
            {!short && 
            <div className="card-footer border-0 pt-0 pb-0">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="row align-items-center">
                    { (tool?.active==false)? 
                      <div className="badge text-primary p-2">{tool?.button_text? tool.button_text : "Not Available"}</div> :
                      <Link className="badge bg-soft-primary text-primary p-2" to={'/'+tool_type+'/'+tool_id}>
                        Start Now
                      </Link>
                    }
                    { (tool?.button_secondary_text) &&
                      <a className="badge text-secondary p-2" href={tool?.button_secondary_href}>
                        {tool?.button_secondary_text}
                      </a>
                    }
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      )
    }

    const displayToolBox_playbook = ({i, featured, short=false, tool_type, tool_id, tool}) => {

      return (
        <div className="col mb-3 h-100 align-middle" key={"tool_"+"asdf"}>
          <div className="float-container">
            <div className="float-child" style={{"width":"20%", "float":"left", "max-width":"50px"}}>
              {featured? 
              <img src="/images/arrow.png" className="mt-7" style={{"width":"70%", "float":"left"}}></img>
              :
              <img src="/images/arrow-blue.png" className="mt-7" style={{"width":"70%", "float":"left"}}></img>
              }
            </div>
            <div className=" card h-100 align-middle float-child" style={{"width":"80%", "float":"left", "border":"0.25rem solid #007aff"}}>

              <Link to={'/'+tool_type+'/'+tool_id+'/'}>
                <div className="card-body pb-0">
                  <div className="row align-items-center mb-2">
                    <div className="col-12">
                        { i && <div style={{"fontSize":"normal", "color":"gray"}}>STEP {i}</div> }
                        { (tool.active==false)? 
                          <b>{tool.name}</b> :
                          <h4 className="mb-1" style={{"fontSize":"x-large"}}><Link to={'/'+tool_type+'/'+tool_id+'/'}>{tool.name}</Link></h4>
                        }
                        
                      
                    </div>

                    <div className="col-3 text-end">
                    </div>
                  </div>

                  <p style={{"fontSize":"large", "color":"black"}}>{tool.description}</p>

                </div>
              </Link>

            </div>
          </div>
        </div>
      )
    }



    const displayToolBox_playbook_step = ({i, featured, short=false, tool_type, tool_id, tool}) => {

      return (
        <div className="col mb-3 h-100 align-middle" key={"tool_"+"asdf"}>
          <div className="float-container">
            <div className="float-child" style={{"width":"20%", "float":"left", "max-width":"50px"}}>
              {featured? 
              <img src="/images/arrow.png" className="mt-7" style={{"width":"70%", "float":"left"}}></img>
              :
              <img src="/images/arrow-blue.png" className="mt-7" style={{"width":"70%", "float":"left"}}></img>
              }
            </div>
            <div className=" card h-100 align-middle float-child" style={{"width":"80%", "float":"left", "border":"0.25rem dotted rgba(231,234,243,.7)"}}>

              <Link to={'/'+tool_type+'/'+tool_id+'/'}>
                <div className="card-body pb-0">
                  <div className="row align-items-center mb-2">
                    <div className="col-12">
                        { i && <div style={{"fontSize":"normal", "color":"gray"}}>STEP {i}</div>}
                        { (tool.active==false)? 
                          <b>{tool.name}</b> :
                          <h4 className="mb-1" style={{"fontSize":"x-large"}}><Link to={'/'+tool_type+'/'+tool_id+'/'}>{tool.name}</Link></h4>
                        }
                        
                      
                    </div>

                    <div className="col-3 text-end">
                    </div>
                  </div>

                  <p style={{"fontSize":"large", "color":"black"}}>{tool.description}</p>

                </div>
              </Link>

            </div>
          </div>
        </div>
      )
    }



    return (
      <>
        <Layout pageTitle="Tools" allowAnonymous={true} hideTermsAccepted={true} isPublic={true} fullScreen={false} customReportingLayout={true}>
            {false && user && user.isAnonymous && 
                <div className="alert alert-soft-primary mb-5 mb-lg-5" role="alert">
                  <div className="d-lg-flex align-items-end">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Megaphone announcment" data-hs-theme-appearance="default"/>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h3 className="alert-heading mb-1">CURRENTLY USING GUEST ACCESS</h3>
                        
                          <ul className="list-checked list-checked-sm list-checked-primary mt-3 mb-0">
                            <li className="list-checked-item">Register now to earn FREE reports every month!</li>
                            <li className="list-checked-item">Access pay-as-you-go pricing</li>
                          </ul>
                      </div>
                    </div>

                          <div className="flex-grow-1 text-end mt-3">
                            <Link to={"/free-trial/?restart=true&url="+encodeURIComponent(window.location.pathname+window.location.search)}>
                              <button type="button" className="btn btn-primary">Start a Free Trial</button>
                            </Link>
                          </div>
                  </div>
                </div>
              }

              <div className="pt-10 pb-10" style={{"backgroundColor":"#007aff"}}>
                <div className="content container-fluid">

                    <div className="row text-center">
                      <div className="display-2 mb-10" style={{"color":"white"}}>Playbook</div>
                    </div>

                    <div className="row justify-content-lg-center">
                      <div className="col" style={{'maxWidth':"1100px"}}>
                      <div className="">
                        <div className="row align-items-end h-100">
                          <div className="container align-middle h-100">
                            <div className="row align-middle justify-content-center h-100">
                                <div className="col-lg-3 align-middle" style={{"maxWidth":"270px"}}>
                                  
                                  <div className="text-left">
                                    <span style={{"fontSize":"normal", "color":"white"}}>QUICK START</span>
                                    <h2 style={{"fontSize":"xx-large", "color":"white"}}>Create New Articles</h2>
                                    
                                    <p  style={{"fontSize":"x-large", "color":"white", "fontWeight":"600"}}></p>
                                    <div className="text-center pt-3 mb-7">
                                      <Link to="/tools/keyword-generator" className="btn btn-outline-light">Start With Any Topic</Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-3">
                                  { 
                                    displayToolBox_playbook({
                                      "i":1, 
                                      "featured":true,
                                      "short":true,
                                      "tool_type":available['keyword-generator']['tool_type'], 
                                      "tool_id": "keyword-generator", 
                                      "tool":available["keyword-generator"]})
                                  }
                                </div>
                                <div className="col-lg-3">
                                  { 
                                    displayToolBox_playbook({
                                      "i":2, 
                                      "featured":true,
                                      "short":true,
                                      "tool_type":available['keyword-clustering']['tool_type'], 
                                      "tool_id": "keyword-clustering", 
                                      "tool":available["keyword-clustering"]})
                                  }
                                </div>
                                <div className="col-lg-3">
                                  { 
                                    displayToolBox_playbook({
                                      "i":3, 
                                      "featured":true,
                                      "short":true,
                                      "tool_type":available['brief-draft']['tool_type'], 
                                      "tool_id": "brief-draft", 
                                      "tool":available["brief-draft"]})
                                  }
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                </div>

              </div>



        <div className="content container-fluid">
          <div className="mb-7 row justify-content-lg-center">
            <div className="col" style={{'maxWidth':"1100px"}}>


              <div className="row text-center">
                <div className="display-3 mt-10 mb-10">All Tools</div>
              </div>
          

            <div className="card">
              <div className="card-body">
              
                        <div className="row align-items-end h-100">
                          <div className="container align-middle h-100">
                            <div className="row align-middle h-100">

                                <div className="col-lg-3 align-middle">
                                  
                                  <span style={{"fontSize":"normal"}}>&nbsp;</span>
                                  <h2 style={{"fontSize":"xx-large"}}>Keyword Clustering</h2>
                                  <p  style={{"fontSize":"x-large", "color":"white", "fontWeight":"600"}}></p>
                                  <div className="text-center mb-3">
                                    <Link to="/tools/keyword-clustering" className="btn btn-outline-primary">Upload Keywords</Link>
                                  </div>
                                </div>

                                <div className="col-lg-3 mb-3">
                                  { 
                                    displayToolBox_playbook_step({
                                      "short":true,
                                      "tool_type":available['keyword-generator']['tool_type'], 
                                      "tool_id": "keyword-generator", 
                                      "tool":available["keyword-generator"]})
                                  }
                                </div>
                                <div className="col-lg-6">
                                  { 
                                    displayToolBox_playbook({
                                      "short":true,
                                      "tool_type":available['keyword-clustering']['tool_type'], 
                                      "tool_id": "keyword-clustering", 
                                      "tool":available["keyword-clustering"]})
                                  }
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>

          <div className="col-lg-12">









          <div className="row align-items-center mb-5">
            <div className="col">
              <h3 className="mb-0 mt-7">{Object.keys(availablePlaybooks).length} Advanced Tools</h3>
            </div>

          </div>

          <div className="tab-content" id="profileTeamsTabContent">
            <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-2">

                {
                  // each playbook
                  availablePlaybooks && Object.keys(availablePlaybooks).map( (tool_id,i) => {return displayToolBox({"i":i, "tool_type":availablePlaybooks[tool_id]['tool_type'], "tool_id": tool_id, "tool":availablePlaybooks[tool_id]})})
                }



              </div>
            </div>
          </div>





          <div className="row align-items-center mb-5">
            <div className="col">
              <h3 className="mb-0 mt-7">{Object.keys(availableTools).length} Tools</h3>
            </div>

          </div>

          <div className="tab-content" id="profileTeamsTabContent">
            <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">

                {
                  // each tool
                  availableTools && Object.keys(availableTools).map( (tool_id,i) => {return displayToolBox({"i":i, "tool_type":availableTools[tool_id]['tool_type'], "tool_id": tool_id, "tool":availableTools[tool_id]})})
                }



              </div>
            </div>
          </div>






          {availableIntegrations>0 &&
          <>
          <div className="page-header">
              <div className="row align-items-end">
              </div>
            </div>
          <div className="row align-items-center mb-5">
            <div className="col">
              <h3 className="mb-0">{Object.keys(availableIntegrations).length} Integrations</h3>
            </div>
          </div>

          <div className="tab-content" id="profileTeamsTabContent">
            <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">

                {
                  // each tool
                  availableIntegrations && Object.keys(availableIntegrations).map( (tool_id,i) => {return displayToolBox({"i":i, "tool_type":availableIntegrations[tool_id]['tool_type'], "tool_id": tool_id, "tool":availableIntegrations[tool_id]})})
                }



              </div>
            </div>
          </div>
          </>}




          </div>
                
              </div>
        </div>
        </div>
        </Layout>
      </>
    );
};
  
export default Tools;
import React from 'react';
import Modal from "react-bootstrap/Modal";
import "./TermsPopup.css";
import axios from 'axios'

const TermsPopup =({user}) =>{

    const [isOpen, setIsOpen] = React.useState(true);

    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
    
    const hideModal = () => {
      // notify backend
      axios.post( api_url+'/v1/user/terms', {'accepted':true}, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}});
      setIsOpen(false);
    };

  
    return(
        <>
          <Modal size='lg' show={isOpen} onHide={hideModal} backdrop='static' centered={true}>
            <Modal.Body>
                <div className="text-center mb-5">
                  <h4 className="h1">Welcome to Content Curator</h4>
                </div>

                <div className="d-flex">
                    <span>By continuing to use this website you agree to the <a href="/terms" target="_blank">Terms of Service</a>.</span>
                    
                </div>

                <hr className="my-4"/>

                <div className="d-flex">
                    <iframe src="/terms" style={{"overflow":"scroll"}}></iframe>
                </div>

                <hr className="my-4"/>

                <div className="d-flex">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked readOnly/>
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                  &nbsp; I agree to the terms of the user agreement.
                  </label>
                </div>

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={hideModal}>Continue</button>
            </Modal.Footer>
          </Modal>
        </>
    )
}

export default TermsPopup;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  linkUserWithEmailAndPassword,
  signInWithGoogle,
  linkUserWithGoogle,
  logout
} from "../../pages/authentication/firebase";
import { Helmet } from "react-helmet"

const RegisterStandalone =({redirectUrl}) =>{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
  
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
  
  

    const register = () => {
        if (!name){
          setErrorMessage("Please enter your name.")
        } else {
          if( !user ){
            // regular registration
            console.log("no User...")
            registerWithEmailAndPassword(name, email, password).then( (response) => {
              console.log("response", JSON.stringify(response))
              if (JSON.stringify(response).includes("email-already-in-use")){
                setErrorMessage("Email address already used.")
              };
            });
    
          
          } else {
            // link new registration
            // console.log(user)
            linkUserWithEmailAndPassword(name, email, password).then( (response) => {
    
    
              console.log("register response", response);
              if (JSON.stringify(response).includes("email-already-in-use")){
                setErrorMessage("Email address already used.")
                
                // todo check credential
                // if it is good then add to user_entity so that if they login then link the old account
              } else if (JSON.stringify(response).includes("Cannot read properties of null")){
                setErrorMessage("Email address already used.")
                
                // todo check credential
                // if it is good then add to user_entity so that if they login then link the old account
              } else {
                if( searchParams.get('url') ){
                  console.log(" go to "+searchParams.get('url'))
                  navigate(searchParams.get('url'))
                } else if (redirectUrl) {
                  console.log( "go to redirectUrl" )
                  navigate(redirectUrl)
                } else {
                  console.log( "go home" )
                  navigate("/")
                }
              }
            });
          }
        }
        
      };
  const registerGoogle = (user) => {
    // console.log(user)
    if( user == undefined || user == null ){
      // regular registration ( or login if already exists )
      signInWithGoogle(name, email, password);
    } else {
      // link new registration
      linkUserWithGoogle(user).then( (response) => {
        // console.log(response);
        if (JSON.stringify(response).includes("credential-already-in-use")){
          setErrorMessage("Google account already used.")

          // todo check credential
          // if it is good then add to user_entity so that if they login then link the old account
        } else {
          navigate("/")
        }
      });
    }
  };

  return (
    <>
    
    <div className="mx-auto">
          { errorMessage && <>
            <div className="card card-lg mb-5 bg-soft-danger">
              <div className="card-body">
                <div className="text-danger">{errorMessage} Please <Link to={"/login/?restart=true"+redirectUrl}>Login</Link> or <Link to="/reset/">Reset Password</Link></div> 
              </div>
            </div>
          </>}
    <div className="card card-lg mb-5">
            <div className="card-body">
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">Create your account</h1>
                    <p>Already have an account? <Link className="link" to="/login">Login</Link></p>
                  </div>

                  <div className="d-grid mb-4">
                    <a className="btn btn-outline-primary btn-lg" onClick={() => registerGoogle(user)}>
                      <span className="d-flex justify-content-center align-items-center">
                        <img className="avatar avatar-xss me-2" src="/assets/svg/brands/google-icon.svg" alt="Image Description"/>
                        Sign up with Google
                      </span>
                    </a>
                  </div>

                  <span className="divider-center text-muted mb-4">OR</span>
                </div>

                <label className="form-label" htmlFor="fullNameSrEmail">Full name</label>

                <div className="row">
                  <div className="mb-4">
                    <input type="text" className="form-control form-control-lg" name="fullName" id="fullNameSrEmail" placeholder="Mark" aria-label="Full Name" required
                      value={name}
                      onChange={(e) => setName(e.target.value)}/>
                    <span className="invalid-feedback">Please enter your first name.</span>
                  </div>

                </div>

                <div className="mb-4">
                  <label className="form-label" htmlFor="signupSrEmail">Your email</label>
                  <input type="email" className="form-control form-control-lg" name="email" id="signupSrEmail" placeholder="Markwilliams@site.com" aria-label="Markwilliams@site.com" required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
                  <span className="invalid-feedback">Please enter a valid email address.</span>
                </div>

                <div className="mb-4">
                  <label className="form-label" htmlFor="signupSrPassword">Password</label>

                  <div className="input-group input-group-merge" data-hs-validation-validate-className="">
                    <input type="password" className="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required="" minLength="8" data-hs-toggle-password-options="{
                            &quot;target&quot;: [&quot;.js-toggle-password-target-1&quot;, &quot;.js-toggle-password-target-2&quot;],
                            &quot;defaultClass&quot;: &quot;bi-eye-slash&quot;,
                            &quot;showClass&quot;: &quot;bi-eye&quot;,
                            &quot;classChangeTarget&quot;: &quot;.js-toggle-password-show-icon-1&quot;
                          }"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}/>
                    <a className="js-toggle-password-target-1 input-group-append input-group-text" href="javascript:;">
                      <i className="js-toggle-password-show-icon-1 bi-eye-slash"></i>
                    </a>
                  </div>

                  <span className="invalid-feedback">Your password is invalid. Please try again.</span>
                </div>

                <div className="form-check mb-4">
                  <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" required=""/>
                  <label className="form-check-label" htmlFor="termsCheckbox">
                    I accept the <a href="/terms" target="_blank">Terms of Service</a>
                  </label>
                  <span className="invalid-feedback">Please accept our Terms of Service.</span>
                </div>

                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-primary btn-lg" onClick={register}>Create an account</button>

                  { errorMessage && <>
                        <div className="text-danger">{errorMessage} <br/>Please <Link to={"/login/?restart=true"+redirectUrl}>Login</Link> or <Link to="/reset/">Reset Password</Link></div> 
                  </>}
                </div>
            </div>
    </div>
    </div>
    </>
    )
}

export default RegisterStandalone;
import React from "react";
import { logout } from "../../../pages/authentication/firebase";
import { useNavigate, Link } from "react-router-dom";
import { QuestionCircle, PlusCircle } from 'react-bootstrap-icons'; 

const NavbarSimple = ({ loading, user, userEntity}) => {

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  var style_icon = {
      "height": "22px"
    }

    var name = "" //const [name, setName] = useState("");
    const navigate = useNavigate();
  
    if( user?.isAnonymous ){
      name = "Guest Access"
    } else if (userEntity?.firstName) {
      name = userEntity?.firstName + " " + userEntity?.lastName;
    } else {
      name = ""
    }

  return (
    <>
        <header id="header" className="navbar navbar-expand-lg navbar-height mb-7 bg-white">
            <div className="navbar-nav-wrap">
            <div className="row align-items-end" style={{"flexWrap":"unset"}}>
                <div className="col-sm mb-2 mb-sm-0">
                <Link to={"/"}>
                    <img src="/images/contentcurator.svg" style={style_icon}></img>
                </Link>
                </div>
                <ul className="navbar-nav">
                  <li className="nav-item d-none d-lg-inline-block"><Link to="/tools/">Playbook</Link></li>
                </ul>
            </div>

            <div className="navbar-nav-wrap-content-end">
                <ul className="navbar-nav">

        {!loading && user && <>
        { user && !user?.isAnonymous ? <>
                { (user?.isAnonymous) &&
                    <>
                    <Link className="nav-item d-none d-sm-inline-block" to="/register/">
                        <button className="btn btn-link">Register for Free Reports</button>
                    </Link>
                    </>
                }
                { (user && userEntity && userEntity?.access?.credits) &&
                    <>
                      <Link className="nav-item d-none d-sm-inline-block text-uppercase" to="/billing/" style={{color:'gray'}}>
                        <b>{Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 0
                          }).format(userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription) }</b> JOBS &bull;&nbsp;
                        <b>{userEntity.access.credits.gas < 10000 ? Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 0
                          }).format(userEntity.access.credits.gas) : Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 0
                          }).format(userEntity.access.credits.gas)}</b> KW CREDITS</Link>
                    </>
                }
                <li className="nav-item me-2">
                    <Link to="/support/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
                    <QuestionCircle/>
                    </button></Link>
                </li>
                <li className="nav-item">
            <div className="dropdown">
              <a className="navbar-dropdown-account-wrapper" id="accountNavbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                { (user?.photoURL)? 
                  <div className="avatar avatar-sm avatar-circle">
                    <img className="avatar-img" src={user?.photoURL}/>
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div> :
                  <div className="avatar avatar-sm avatar-dark avatar-circle">
                    <span className="avatar-initials"></span>
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                }
              </a>

              <div className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                <div className="dropdown-item-text">
                  <div className="d-flex align-items-center">
                    { (user?.photoURL)? 
                    <div className="avatar avatar-sm avatar-circle">
                      <img className="avatar-img" src={user?.photoURL}/>
                    </div> :
                    <div className="avatar avatar-sm avatar-dark avatar-circle">
                      <span className="avatar-initials"></span>
                    </div>
                    }
                    <div className="flex-grow-1 ms-3">
                      <h5 className="mb-0">{name}</h5>
                      <p className="card-text text-body"><b>{user?.email}</b><br/>
                          <b>{Intl.NumberFormat('en-US', {
                              notation: "compact",
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription) }</b> JOBS
                            &bull;&nbsp;
                            <b>{userEntity?.access?.credits.gas < 10000 ? Intl.NumberFormat('en-US', {
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits.gas) : Intl.NumberFormat('en-US', {
                              notation: "compact",
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits.gas)}</b> KW CREDITS</p>
                    </div>
                  </div>
                </div>

                <div className="dropdown-divider"></div>
                

                <Link className="dropdown-item" to="/reports/">Recent Activity</Link>
                { false &&
                <Link className="dropdown-item" to="/catalog/">Content Catalog</Link>}
                <Link className="dropdown-item" to="/tools/">Tools</Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/billing/">Billing</Link>
                <Link className="dropdown-item" to={"/pricing/"}>Pricing</Link>
                <Link className="dropdown-item" to="/support/">Support</Link>


                <div className="dropdown-divider"></div>

                { user?.isAnonymous &&
                  <Link className="dropdown-item" to="/register">
                    <button className="btn btn-sm btn-primary">Save your progress</button>
                  </Link>
                }
                <a className="dropdown-item" href="/login/" onClick={logout}>Sign out</a>
              </div>
            </div>
          </li>
               
          </> :
          <>
          { !loading && (user && !user?.isAnonymous && userEntity && userEntity?.access?.credits) &&
            <>
              <Link className="nav-item d-none d-lg-inline-block" to="/billing/" style={{color:'gray'}}>
                {Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 0
                  }).format(userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription) } JOBS &bull;&nbsp; 
                {userEntity.access.credits.gas < 10000 ? Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 0
                  }).format(userEntity.access.credits.gas) : Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 0
                  }).format(userEntity.access.credits.gas)} KW CREDITS</Link>
            </>
                  }
                  <li className="nav-item d-inline-block m-0">
                      <Link to="/tools/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
                      <PlusCircle/>
                      </button></Link>
                  </li>
          <li className="nav-item me-1 d-none d-sm-inline-block me-2">
              <Link to="/support/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
              <QuestionCircle/>
              </button></Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block me-2">
              <Link className="nav-item" to="/login/?restart=true"><button className="btn btn-white">
                Login
              </button></Link>
          </li>
          <li className="nav-item">
            <Link className="btn btn-sm btn-primary" to="/register">
              Sign-up
            </Link>
          </li>
          
          </>}
        </>}
                </ul>
            </div>
            </div>
        </header>
    </>
  );
};
  
export default NavbarSimple;
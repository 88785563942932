
import React, { useEffect, useState } from "react";

import styled from 'styled-components';
import { GraphUpArrow } from 'react-bootstrap-icons';
import ResultsPageOverview from "./ResultsPageOverview";

import ResultsPageSegments from "./ResultsPageSegments";
import ResultsPageKeyphrases from "./ResultsPageKeyphrases";


const Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;



class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a> <a className="btn btn-outline-secondary" href={"/pricing/"}>See Pricing!</a> 
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayKeyphraseExtractorV1({jobDetails, reportParts}) {

  return (
    <>
      <div className="reportPart content" id="overview">
        {jobDetails?.results && 
              <>
                <h1>Overview</h1>
                <ResultsPageOverview jobDetails={jobDetails} summaryJson={jobDetails?.results?.summary} detailsJson={jobDetails?.results?.details}></ResultsPageOverview>
              </>
            }


          {jobDetails?.results?.keyphrases &&
              <>
                <h1 id="keyphrases" className="pt-4">Keyphrases</h1>
                <ResultsPageKeyphrases jobDetails={jobDetails}></ResultsPageKeyphrases>
              </>
            }
            {jobDetails['results'] && jobDetails['results']['segments'] &&
              <>
                <h1 id="segments" className="pt-4">Segments</h1>
                <ResultsPageSegments jobDetails={jobDetails}></ResultsPageSegments>
                
              </>
            }
            {jobDetails['results'] && jobDetails['results']['schemas'] &&
              <>
                <h1 id="schemas" className="pt-4">Schemas</h1>
                {jobDetails['results']['schemas'].map((message, index) => {
                return <span key={"schema-"+index.toString()}>
                    <h3 id={"schema-"+message['@type'].toLowerCase()}>{message['@type']} ({message['data'] && message['data'].length})</h3>
                    {message['data'].map((d, dindex) => {
                      return <div className="col-lg-12 col-md-12 mb-3 mb-lg-5" key={"schema_"+index+"_"+dindex}>
                          <div className="card h-100">
                              <div className="card-header card-header-content-between">
                                  <h4 className="card-header-title" style={{"overflow":"hidden"}}>{d?.['@id']? d?.['@id'] : "Item without Id"}</h4>
                              </div>

                              <div className="card-body">
                                <pre key={dindex} style={{"display":"block"}}>{JSON.stringify(d, null, 1)}</pre>
                              </div>
                          </div>
                        </div>
                    })}
                  </span>;
              })}

              {jobDetails?.results?.schemas && jobDetails['results']['schemas'].length==0 && <div className="col-lg-12 mb-3 mb-lg-5">No Schema Detected</div>}
              </>
          }
          { jobDetails?.results &&
            <>
            <div>
              <h1 id="links" className="pt-4">Links</h1>
              {jobDetails.results?.links && 
              
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">href</th>
                    <th scope="col">Internal Link</th>
                    <th scope="col">Text</th>
                  </tr>
                </thead>
                <tbody>
                  {jobDetails.results.links.map((link, index) => {
                    return <tr key={"link_"+index+1}>
                      <th scope="row">{(index+1)? index+1 : ""}</th>
                      <td>{link?.href}</td>
                      <td>{link?.internal? "Internal" : "External"}</td>
                      <td>{link?.text}</td>
                    </tr>
                  })}
                </tbody>
              </table>
              }
            </div>

            <div>
              <h1 id="multimedia" className="pt-4">Multimedia</h1>

              {jobDetails.results?.multimedia?.images && 
              <>
              <h2 id="multimedia-images" className="pt-4">Images</h2>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Alt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobDetails.results.multimedia.images.map((img, index) => {
                      return <tr key={"img_"+index+1}>
                        <th scope="row">{(index+1)? index+1 : ""}</th>
                        <td>{img?.filename}</td>
                        <td>{img?.alt}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
                </>
              }
            </div>
            </>
            }
      </div>
    </>
  );
}

export default DisplayKeyphraseExtractorV1;

import React, { useEffect, useState } from "react";

function ResultsPageKeyphrases({jobDetails}) {

    const displayKeyphrases = (title, kws) => {
        var maxVal = 100
        kws.map((message, index) => {
            if(index==0 && message?.pct_rank) maxVal = message?.pct_rank
            if(index==0 && message?.freq) maxVal = message?.freq
        })
        return <>
                    <div className="col-lg-4 col-md-12 mb-3 mb-lg-5">
                        <div className="card h-100">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">{title}</h4>
                            </div>

                            <div className="card-body">
                                {kws.map((message, index) => {
                                    var thisVal = message?.pct_rank? message?.pct_rank: message?.freq
                                    if(index <15){
                                        return <div key={"keyword_"+title+"_"+index}>
                                                    {message?.key_phrase}{message?.kw}
                                                    <div className="progress rounded-pill mb-2" style={{"opacity":"0.5"}}>
                                                        <div className="progress-bar" role="progressbar" style={{"width":(thisVal/maxVal)*100+"%"}} aria-valuenow={message?.pct_rank} aria-valuemin="0" aria-valuemax="100" data-bs-toggle="tooltip" data-bs-placement="top" title=""></div>
                                                    </div>
                                                </div>;

                                    }
                                })}
                            </div>
                        </div>
                    </div>
        </>
    }


  return (
    <>
        
        <div className="row">
        {jobDetails?.results?.keyphrases?.semantics && displayKeyphrases("Semantic Topics", jobDetails?.results?.keyphrases?.semantics)}

        {jobDetails?.results?.keyphrases?.wordcount && displayKeyphrases("Wordcount", jobDetails?.results?.keyphrases?.wordcount)}
            
        {jobDetails?.results?.keyphrases?.bigrams && displayKeyphrases("Bigrams and Trigrams", jobDetails?.results?.keyphrases?.bigrams)}
        </div>
    </>
  );
}

export default ResultsPageKeyphrases;
import React, { useEffect, useState } from "react";
import Layout from '../components/Layout';
import Spinner from 'react-bootstrap/Spinner';
import { auth, createAnonymousUser, resendEmailtoUser } from "./authentication/firebase";


const Verify = () => {


  // get user


  // resend email
  const resendEmail = async event => {
      try {
          console.log("resend")
          resendEmailtoUser()
      } catch (err) {
          console.log("error")
      }
      }


  return (
    <>
      <Layout>
        <div className="row justify-content-lg-center">
          <div className="col-lg-9">
            <div className="d-grid gap-3 gap-lg-5">

              <div>
                <h1>Please Verify Your Email</h1>
              </div>


              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md mb-4 mb-md-0">
                      <div className="mb-4">
                        <span className="card-subtitle">STATUS</span>
                        <h3><Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Processing . . . waiting for email verification.</h3>
   
                         Your job is currenty running.
                      </div>

                    </div>

                  </div>
                </div>



                
              </div>

                      <div className="alert alert-soft-warning mb-7" role="alert">
                        Please verify your account by clicking the link in our email. <a href="#" onClick={resendEmail}>Resend Verification Email</a>
                      </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
  
export default Verify;
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { sendEmailVerification } from "firebase/auth";
import { auth, createAnonymousUser, resendEmailtoUser } from "../authentication/firebase";
import axios from 'axios';
import UpdateBillingAddress from '../../components/billing/updateBillingAddress';
import StripeCheckout from '../../components/billing/StripeCheckout';
import "./subscribe.css";
import Helmet from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import RegisterStandalone from '../../components/billing/register_standaloneform';

const Subscribe = () => {
  // get the user
  const [user, loading, error] = useAuthState(auth);
  const [userEntity, setUserEntity] = useState({})
  const [accessToken, setAccessToken] = useState('');
  const [warn, setWarn] = useState(null);
  const [err, setErr] = useState(null);

  // for updating ui
  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

  // states
  // user is mising name or email -> if not then show them a custom 'account details page' to register -> if email is validated then create stripe_customer_id
  // user has email validated -> if not then show email verification popup to continue -> once validated then create 'stripe_customer_id'
  // if email is validated and customer is missing 'stripe_customer_id' for some reason but has fullname and email? -> create customer id quickly -> report error if not able to
  // has customer id but no 'billing info' then create quickly ( using the last selected plan ) -> create a new one if/when they change their mind on subscriptions page.

  // using billing info
  // -> if due today then trigger invoice and show cc form --> record subscription id on user once successful
  // -> if none due then record subscription id --> request card on file cc form --> if they cancel or unsuccessful then request another form of payment. Dont' mark active until valid card on file.


    // user
    useEffect(() => {
      if (loading) return;
      if (!user) {
        // get anonymous user if not already logged in
        createAnonymousUser()
      } else {
        setAccessToken(user.accessToken)

        // get the userEntity
        axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setUserEntity(response.data.user_entity)

        }).catch(error => {
          setWarn("PLEASE REFRESH: Your session has expired or our backend services are currently busy.")
        });
        
      }
    }, [user, loading]);


  const [searchParams, setSearchParams] = useSearchParams();
  var selectedSubscription = searchParams.get("plan");

  if( selectedSubscription == "none" || selectedSubscription == "null"){
    selectedSubscription = null
  }
  var selectedCredits = searchParams.get("credits");
  var editLink = searchParams.get("edit");
  var autoLaunch = searchParams.get("launch");
  if(autoLaunch == 'null'){
    autoLaunch = null
  }


  const [currentSubscription, setCurrentSubscription] = useState('free');
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const [showBillingAddress, setShowBillingAddress] = useState(false)
  const [runOnce, setRunOnce] = useState(true)
  const [billingInfo, setBillingInfo] = useState(null);

  const navigate = useNavigate();
  
  // email verification module
  const [isOpen, setIsOpen] = React.useState(false);
  const hideModal = () => {
    setIsOpen(false);
  };

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";



  const [subscriptionOption, setSubscriptionOption] = useState({
    'monthly_subscription_fee':0,
    'credit_discount':0
  });
  
  const [pricePer1000Credits, setPricePer1000Credits] = useState(4.98);
  const minCreditPurchase = 4000
  const selectedCredits_orig = selectedCredits
  if( selectedCredits != 0 ){
    if( selectedCredits < minCreditPurchase ){
      selectedCredits = minCreditPurchase
    }
  }

  // if nothing then redirect to pricing page
  if( selectedSubscription === null && selectedCredits === null ){
    navigate("/pricing/")
  }

  

  const subscriptionOptions = [
    {
      'name': 'free',
      'title': "Pay As You Go Membership",
      'description': "No monthly commitment.",
      'monthly_subscription_fee': 0,
      'credit_product_id': 'prod_Nf0irOJ3up0bXF',
      'credit_product_id_dev': 'prod_Nf0irp8KHElYfa',
      'credit_price_id':'price_1MtghTDGl99pwV48gTF5H0im',
      'credit_price_id_dev': 'price_1MtghFDGl99pwV48mmrxPn4u',
      'credit_discount': 0,
      'prepaid_reports_included_per_month': 5
    },
    {
      'name': 'lite_starter',
      'title': 'Lite Membership',
      'invoice_upfront': true,
      'description': 'Cancel anytime.',
      'monthly_subscription_fee': 9,
      'price_ids':[
        'price_1NaB25DGl99pwV48fuChaXe2'
      ],
      'price_ids_dev':[
        'price_1NaB00DGl99pwV482dzRbwq1'
      ],
      'per_unit_price':[],
      'credit_product_id': 'prod_Nf0irOJ3up0bXF',
      'credit_product_id_dev': 'prod_Nf0irp8KHElYfa',
      'credit_price_id':'price_1MtghTDGl99pwV48gTF5H0im',
      'credit_price_id_dev': 'price_1MtghFDGl99pwV48mmrxPn4u',
      'credit_discount': 0,
      'prepaid_reports_included_per_month': 10
    },
    {
      'name': 'basic_starter',
      'title': 'Basic Plan',
      'trial_days':7,
      'trial_jobs':12,
      'invoice_upfront': true,
      'description': 'Cancel anytime.',
      'monthly_subscription_fee': 29,
      'price_ids':[
        'price_1Nf88qDGl99pwV489wNzAIQH'
      ],
      'price_ids_dev':[
        'price_1Nf8BADGl99pwV48r0L90xvx'
      ],
      'per_unit_price':[],
      'credit_product_id': 'prod_Nf0irOJ3up0bXF',
      'credit_product_id_dev': 'prod_Nf0irp8KHElYfa',
      'credit_price_id':'price_1MtghTDGl99pwV48WpNI19Y6',
      'credit_price_id_dev': 'price_1MtghFDGl99pwV48LFIeqQyw',
      'credit_discount': 0,
      'prepaid_reports_included_per_month': 50
    },
    {
      'name': 'pro_starter',
      'title': 'Professional Plan',
      'trial_days':7,
      'trial_jobs':25,
      'invoice_upfront': true,
      'description': 'Cancel anytime.',
      'monthly_subscription_fee': 49,
      'price_ids':[
        'price_1Mu8InDGl99pwV48EThptfk2'
      ],
      'price_ids_dev':[
        'price_1Mu8ChDGl99pwV48OT9gHP2p'
      ],
      'per_unit_price':[],
      'credit_product_id': 'prod_Nf0irOJ3up0bXF',
      'credit_product_id_dev': 'prod_Nf0irp8KHElYfa',
      'credit_price_id':'price_1MtghTDGl99pwV48wLSnIfCS',
      'credit_price_id_dev': 'price_1MtghFDGl99pwV487H0TPACe',
      'credit_discount': 0.5,
      'prepaid_reports_included_per_month': 100
    },
    {
      'name': 'team_starter',
      'title': 'Business Plan',
      'invoice_upfront': true,
      'description': 'Cancel anytime.',
      'monthly_subscription_fee': 299,
      'price_ids':[
        'price_1LktTADGl99pwV48JU24Ttb6'
      ],
      'price_ids_dev':[
        'price_1LiQXwDGl99pwV48xg5UvoNc'
      ],
      'per_unit_price':[],
      'credit_product_id': 'prod_Nf0irOJ3up0bXF',
      'credit_product_id_dev': 'prod_Nf0irp8KHElYfa',
      'credit_price_id':'price_1MtghTDGl99pwV48wLSnIfCS',
      'credit_price_id_dev': 'price_1MtghFDGl99pwV487H0TPACe',
      'credit_discount': 0.624,
      'prepaid_reports_included_per_month': 600
    },
    {
      'name': 'volumebased',
      'price_ids':[
        'price_1LSAGmDGl99pwV48mzqbO9Nk'
      ],
      'invoice_upfront': false,
      'title': "Volume Based Pricing",
      'description': "Simply pay for the tokens you use.",
      'monthly_subscription_fee': 0.00,
      'billing_threshold_amount_gte':10000,
      'per_unit_price':[
        {
          "desc": "Less than 30 Total Tokens Used",
          "volume_start": 1,
          "volume_end": 29,
          "price": 1.99
        },
        {
          "desc": "30 to 89",
          "volume_start": 30,
          "volume_end": 89,
          "price": 1.70
        },
        {
          "desc": "90 to 199",
          "volume_start": 90,
          "volume_end": 199,
          "price": 1.49
        },
        {
          "desc": "200 to 499",
          "volume_start": 200,
          "volume_end": 499,
          "price": 1.25
        },
        {
          "desc": "If the total quantity is 500 or more",
          "volume_start": 500,
          "volume_end": null,
          "price": .99
        }
      ]
    }
  ]

  var subscriptionOptionsDict = {};
  for( const x of subscriptionOptions){
    subscriptionOptionsDict[x['name']] = x
  }


  useEffect(() => {
    if(loading) return;

    // set current membership plan using subscription membership_id
    var membership_id = userEntity?.access?.subscription?.membership_id
    if( membership_id && selectedSubscription == null){
      setCurrentSubscription(membership_id)
      setSubscriptionOption( subscriptionOptionsDict[membership_id] )
    }

  }, [userEntity, loading]);



  useEffect(() => {
    if (loading) return;
    if (!user) {
      // go home if not a user
      //navigate("/");
    } else {
      
      
      
      // if is an anonymous user then have them register first and then come right back
      if( user?.isAnonymous ){
        //navigate("/register?url="+encodeURIComponent("/checkout?plan="+searchParams.get("plan")))
      }


      const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

      if( runOnce ){


        // find the subscription option
        for( var i=0; i < subscriptionOptions.length; i++ ){
          if( subscriptionOptions[i]['name'] === selectedSubscription ){
            setSubscriptionOption( subscriptionOptions[i] )
          }
        }
        
        // get billing information
        axios.get(api_url + '/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setUserEntity(response.data.user_entity)

          // if there is a stripe customer id then skip to payment
          if ( false && !user?.isAnonymous && runOnce ){
            setShowPaymentMethod(true)

            // get or create customer in stripe and save to existing user_entity in datastore
            axios.post(
              api_url+'/v1/user/stripe', 
              {
                'userEntity': response.data.user_entity,
                'subscriptionOption': subscriptionOption,
                'selectedCredits': selectedCredits,
                'selectedSubscription': selectedSubscription,
                'metadataCredits': {'autoLaunch': autoLaunch}
              }, { headers: {'Authorization': user.accessToken} }
            ).then((response)=> {
              setBillingInfo(response.data)
            })

            // only let this run once.
            setRunOnce(false)
          }

          console.log("userEntity",response.data.user_entity)
          if ( response.data.user_entity?.stripe_customer_id_dev || response.data.user_entity?.stripe_customer_id ){

            // get or create customer in stripe and save to existing user_entity in datastore
            axios.post(
              api_url+'/v1/user/stripe', 
              {
                'userEntity': response.data.user_entity,
                'subscriptionOption': subscriptionOption,
                'selectedCredits': selectedCredits,
                'selectedSubscription': selectedSubscription,
                'metadataCredits': {'autoLaunch': autoLaunch}
              }, { headers: {'Authorization': user.accessToken} }
            ).then((response)=> {
              setBillingInfo(response.data)
            })

            setShowPaymentMethod(true)
          } else {
            // no stripe customer id so get one by asking for info
            setShowBillingAddress(true)
          }
        }).catch(error => {
          console.log(error)
        });

        
        // if email not verified in user then prompt for email
        if(  !loading && user && (user?.emailVerified === false ) ){
          // popup reminds them to confirm email
          setIsOpen(true);
        }

        // use email from user_entity
        // if none then is auth user email verified? use that
        // add email to form
      }
    }
  }, [user, loading]);


  const VerifyEmailModal =({loading, user, isOpen}) =>{
    if (loading || (user && user?.isAnonymous) || !isOpen) return;

    const resend_again = (user) => {
      // change the inner text of button to 'sent'
      document.querySelector('#resend_button').innerText = 'Sent...';
      sendEmailVerification(user)
    }
    return(
      <Modal size='lg' show={isOpen} backdrop='static' centered={true}>
        <Modal.Body>
            <div className="text-center mb-5">
              <h4 className="h1">Please confirm your Email</h4>
            </div>

            <div className="d-flex">
              <p>A verification email has been sent to <b>{user?.email}</b>. Please click the link in your inbox to verify your email address.</p>
            </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-link" onClick={() => resend_again(user)} id="resend_button">Resend Verification Email</button>
          <button className="btn btn-primary" onClick={() => window.location.reload(false)}>Continue</button>
        </Modal.Footer>
      </Modal>
    );
  }



  const showPaymentOptions = () => {
    
    // validate form for completion

    // briefly show spinning dial
    // create new or update existing billing user -> return stripe user id
    // update user_entity with details including stripe userid
    
    // show errors if any

    setShowPaymentMethod(true)


  }
  const hidePaymentMethod = () => {
    setShowPaymentMethod(false)
    }

  // determine order of missing user items
  const hasBillingAddress = false

  function addZeroes(num) {
    num = Math.round(num * 100 )/100
    const dec = String(num).split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  var pageNumber = 3
  if (!user || user && user?.isAnonymous){
    pageNumber = 1
  } else {
    if ( !hasBillingAddress ){
      pageNumber = 2
    }
  }


  const selectedSubscription_url = () => {
    if( selectedSubscription ){
      return("plan="+selectedSubscription+"&")
    } else {
      return("")
    }
  }


  const doDowngrade = () => {
    // get or create customer in stripe and save to existing user_entity in datastore
    axios.post(
      api_url + '/v1/user/stripe', 
      {
        'userEntity':userEntity,
        'selectedCredits': 0,
        'selectedSubscription': 'free'
      }, { headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken} }
    ).then((response)=> {
      navigate("/billing/")
    })

  }
  return (

    <>
    { userEntity && userEntity?.stripe_customer_id &&
    <>
      <Helmet>
        <script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
      </Helmet>

      <Helmet 
        script={[{ 
          type: 'text/javascript', 
          innerHTML: `
        (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
        (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

        tap('create', '43455-1fc84b', { integration: "stripe" });
        tap('trial', '`+userEntity?.stripe_customer_id+`');`
      }]}
      />
    </>
    }
    <Layout hideControls={false} pageTitle="Checkout" fullScreen={true} hideTermsAccepted={true} allowAnonymous={true}>
    
    <div className="content container-fluid" style={{"overflowY":"auto"}}>
    { !loading && user && !user?.isAnonymous && isOpen &&
    <> <VerifyEmailModal loading={loading} user={user} isOpen={isOpen}/></>
    }
    <div className="d-grid gap-3 gap-lg-5">

      <div>
        <h1>Checkout</h1>
      </div>
                { err &&
                    <div className="alert alert-soft-danger p-5" role="alert">
                    <b>Error</b>: {err} Please contact customer service for additional details if it continues happening.
                  </div>
                  }
                  { warn &&
                    <div className="alert alert-soft-warning mb-7" role="alert">
                      <span style={{"color":"gray"}}>{warn}</span>
                    </div>
                  }
<div className="w-md-75 mx-md-auto d-none d-md-block" style={{"maxWidth":"800px"}}>
  <ul id="checkoutStepFormProgress" className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-3 mb-sm-7">

    <li className={ pageNumber >=1 ? "step-item active focus" : "step-item"}>
      <span className="step-content-wrapper">
        <span className="step-icon step-icon-soft-dark">{1}</span>
        <div className="step-content">
          <span className="step-title">Register</span>
        </div>
      </span>
    </li>

    <li className={ pageNumber >=2 ? "step-item active focus" : "step-item"}>
      <span className="step-content-wrapper">
        <span className="step-icon step-icon-soft-dark">{2}</span>
        <div className="step-content">
          <span className="step-title">Account Details</span>
        </div>
      </span>
    </li>

    <li className="step-item">
      <span className="step-content-wrapper">
        <span className="step-icon step-icon-soft-dark">{3}</span>
        <div className="step-content">
          <span className="step-title">Payment</span>
        </div>
      </span>
    </li>
  </ul>
</div>
</div>
    <div className="row justify-content-lg-center ">
      <div className="">

        <div className="row">
          <div className="col-lg-4 order-lg-2 mb-5 mb-lg-0">

            
            <div id="checkoutStepOrderSummary">
              <div className="card mb-3">

                { !loading && user && userEntity && subscriptionOption?.trial_days && selectedSubscription && !userEntity?.access?.subscription?.membership_id && <>
                  <div className="card-header text-center pt-7 pb-4" style={{"backgroundColor":"rgba(55,125,255,.1)"}}>
                    <h1>7-Day Free Trial</h1>
                    <p>Free trial includes {subscriptionOption?.trial_jobs} Jobs
                    {selectedCredits>0 && <>
                    <br/>First 500 KWs Free when Clustering
                    </>}</p>
                  </div>
                  </>
                }

                { autoLaunch &&
                <>
                  <div className="card-body">
                    <ul className="list-checked list-checked-lg list-checked-primary mb-0">
                      <li className="list-checked-item">Your job will automatically launch</li>
                    </ul>
                  </div>
                  <hr className="my-0 mt-0"/>
                </>}

                <div className="card-body">
                  {selectedSubscription != null && <>
                
                    <div className="row align-items-center mb-2">
                      <span className="col-8">
                        <div className="d-flex">
                          <div className="row align-items-center">
                            <h5 className="">Monthly {subscriptionOption?.title} { subscriptionOption?.trial_days  && !userEntity?.access?.subscription?.membership_id && <>+ Trial</>} </h5>
                          </div>
                        </div>
                      </span>
                      <h4 className="col-4 text-end text-dark mb-0">${addZeroes(subscriptionOption?.monthly_subscription_fee)}</h4>
                    </div>
                    

                    { subscriptionOption?.per_unit_price && subscriptionOption?.per_unit_price?.length>0 &&
                    <><div className="row align-items-center mb-3">
                      <h4 className="mb-0">Volume Based Price Per Credit</h4>
                    </div>
                    { subscriptionOption?.per_unit_price.map( (option, index) =>
                      <div className="row align-items-center mb-3" key={index}>
                        <span className="col-8">{option['desc']}</span>
                        <span className="col-4 text-end text-dark mb-0">+ ${addZeroes(option['price'])} Each</span>
                      </div>
                    
                    )}</>
                    }

                    { currentSubscription != selectedSubscription &&
                    <div className="row align-items-center mb-5">
                      <div className="col-12 ">
                        <ul className="list-checked list-checked-secondary mb-0">
                          { !loading && userEntity?.access?.subscription?.membership_id &&
                          <li className="list-checked-item">Replaces current plan.</li>
                          }
                          <li className="list-checked-item">Includes {subscriptionOptionsDict[selectedSubscription]['prepaid_reports_included_per_month']} Jobs per Month.</li>
                          { !loading && !user?.isAnonymous && userEntity && userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription > 0 &&
                          <li className="list-checked-item">Jobs carry over when upgrading.</li>
                          }
                          { subscriptionOption?.description &&
                          <li className="list-checked-item">{subscriptionOption?.description}</li>
                          }
                        </ul>
                      </div>
                    </div>
                    }

                  </>}
                  {selectedCredits != null && selectedCredits>0 && <>
                    
                    
                    
                    { subscriptionOption?.credit_discount>0?
                    <>
                      <div className="row align-items-center mb-1">
                        <span className="col-8">
                          <div className="d-flex">
                            <div className="row align-items-center">
                              <h5 className="">One Time {selectedCredits} Credits</h5>
                            </div>
                          </div>
                        </span>
                        <h4 className="col-4 text-end text-dark mb-0">${addZeroes(selectedCredits * (1-subscriptionOption?.credit_discount) * pricePer1000Credits/1000)}</h4>
                      </div>

                      <div className="col-12 ">
                        <ul className="list-checked list-checked-secondary mb-0">
                          <li className="list-checked-item">Includes {subscriptionOption?.credit_discount*100}% off with the <b>{subscriptionOption?.title}</b></li>
                          { selectedCredits_orig < minCreditPurchase && <li className="list-checked-item">Minimum Purchase of 4k</li>}
                          <li className="list-checked-item">KW credits never expire.</li>
                        </ul>
                      </div>
                    </>
                    : <>
                      <div className="row align-items-center mb-1">
                        <span className="col-8">
                          <div className="d-flex">
                            <div className="row align-items-center">
                              <h5 className="">One Time {selectedCredits} Credits</h5>
                            </div>
                          </div>
                        </span>
                        <h4 className="col-4 text-end text-dark mb-0">${addZeroes(selectedCredits * pricePer1000Credits/1000)}</h4>
                      </div>
                      <div className="col-12 ">
                        <ul className="list-checked list-checked-secondary mb-0">
                          { selectedCredits_orig < minCreditPurchase && <li className="list-checked-item">Minimum Purchase of 4k</li>}
                          <li className="list-checked-item">KW credits never expire.</li>
                        </ul>
                      </div>
                    </>
                    }
                  </>}
                  <hr className="my-4"/>
                  <div className="row align-items-center">
                    <h3 className="col-8 text-dark fw-semi-bold">Total:</h3>
                    <h3 className="col-4 text-end text-dark mb-0">${addZeroes( (selectedSubscription != null)*subscriptionOption?.monthly_subscription_fee + selectedCredits * (1-subscriptionOption?.credit_discount) * pricePer1000Credits/1000)}</h3>
                  </div>
                  { subscriptionOption?.trial_days && <>
                    { selectedCredits && selectedCredits>0 && <div>${addZeroes(selectedCredits *(1-subscriptionOption?.credit_discount)* pricePer1000Credits/1000)} paid today</div>}
                    {selectedSubscription && !userEntity?.access?.subscription?.membership_id && <div>${addZeroes(subscriptionOption?.monthly_subscription_fee)} monthly beginning in 7 days</div>}
                  </>}
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="bi-headset"></i>
                  </div>

                  <div className="flex-grow-1 ms-2">
                    <span className="fw-semi-bold me-1">Need Help?</span>
                    <Link className="link" to="/support/">Contact Support</Link>
                  </div>
                </div>


              </div>
            </div>
          </div>


          <div className="col-lg-8 mb-10">


            <div className="mb-10" id="checkoutStepFormContent">

            {
              // downgrade to free plan
              selectedCredits==0 && selectedSubscription=='free' && <>
              <div className="text-center">
                <h2>Confirm you would like to downgrade to Pay as you Go</h2>

                <button className="btn btn-link">View Pricing</button>
                <button className="btn btn-primary" onClick={()=>doDowngrade()}>Downgrade</button>
              </div>
              </>
            }
            { (selectedCredits>0 || selectedSubscription!='free') && <>
            { !loading && (!user || user && user?.isAnonymous) ? 
            
            /// still anonymous so have them login 
            <>
              <RegisterStandalone redirectUrl={"/checkout/?plan="+selectedSubscription+"&credits="+selectedCredits+"&edit="+editLink+"&launch="+autoLaunch}/>
            </>
            :
            <>

              {console.log("showBillingAddress",showBillingAddress)}
              {console.log("showPaymentMethod",showPaymentMethod)}
              { showBillingAddress && !showPaymentMethod &&
              <>
                  
                  <UpdateBillingAddress
                    user = {user}
                    userEntity = {userEntity}
                    setShowPaymentMethod={showPaymentOptions}
                    planInShoppingCart = {selectedSubscription}
                    selectedSubscription = {selectedSubscription}
                    subscriptionOption = {subscriptionOption}
                    selectedCredits = {selectedCredits}
                    setBillingInfo = {setBillingInfo}
                    editLink = {editLink}
                    autoLaunch = {autoLaunch}
                  />
              </>
          
              }
              
              { ((hasBillingAddress) || showPaymentMethod ) && billingInfo==null &&
                  <>
                  <h1>Payment</h1>
                    Please wait while we prepare your payment options... <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                  </>
              }

              { ((hasBillingAddress) || showPaymentMethod ) && billingInfo &&
                  <>
                  <h1>Payment</h1>
                      <StripeCheckout 
                        hidePaymentMethod={hidePaymentMethod}
                        billingInfo = {billingInfo}
                      />
                  </>
              }
            
            </>
            // end if is registered or not
            }
            </>}


            </div>
          </div>
        </div>
        
      </div>
    </div>
    </div>
    </Layout>
    </>
  );
};
  
export default Subscribe;

import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Layout from '../../components/Layout';
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../authentication/firebase";
import axios from 'axios';

import Helmet from 'react-helmet';

import { Check } from 'react-bootstrap-icons';

const Subscriptions = () => {
  // get the user
  const [user, loading, error] = useAuthState(auth);
  const [userEntity, setUserEntity] = useState({})
  const [currentSubscription, setCurrentSubscription] = useState('none')
  const navigate = useNavigate();
  
  useEffect(() => {
    if (loading) return;


    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

    if (!user) {
      // go home if not a user
      //navigate("/")
    } else {

      // get billing information
      axios.get(api_url + '/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)
      }).catch(error => {
        console.log(error)
      });

    }
  }, [user, loading]);




  const subscriptionOptions = [
    {
      'name': 'free',
      'title': "Pay as you Go Plan",
      'discountOnCredits': 0,
      'monthlyPrice': 0,
      'prepaid_reports_included_per_month': 5
    },
    {
      'name': 'basic_starter',
      'title': "Basic",
      'discountOnCredits': 0,
      'monthlyPrice': 39,
      'prepaid_reports_included_per_month': 50
    },
    {
      'name': 'pro_starter',
      'title': "Profesional",
      'discountOnCredits': 0.5,
      'monthlyPrice': 49,
      'prepaid_reports_included_per_month': 100
    },
    {
      'name': 'team_starter',
      'title': "Agency",
      'discountOnCredits': 0.624,
      'monthlyPrice': 299,
      'prepaid_reports_included_per_month': 600
    },
    {
      'name': 'lite_starter',
      'title': "Lite Membership",
      'discountOnCredits': 0,
      'monthlyPrice': 9,
      'prepaid_reports_included_per_month': 10
    }
  ]

  var subscriptionOptionsDict = {};
  for( const x of subscriptionOptions){
    subscriptionOptionsDict[x['name']] = x
  }

  // default billing
  const minCreditPurchase = 4000
  var currentUrl = "/pricing/"
  const [essentialQuantity, setEssentialQuantity] = useState(minCreditPurchase);
  const [bulkQuantity_50k, setBulkQuantity_50k] = useState(50000);
  const [bulkQuantity, setBulkQuantity] = useState(100000);
  const [bulkQuantity_500k, setBulkQuantity_500k] = useState(500000);
  const [bulk_8kQuantity, setBulk_8kQuantity] = useState(8000);


  // set innitial state
  const defaultCreditOption = 'bulk_0'
  var quantity = 0

  const [selectedCreditsOption, setSelectedCreditsOption] = useState(defaultCreditOption)

  const [pricePer1000Credits, setPricePer1000Credits] = useState(4.98);
  
  // todo get this from user
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState(currentSubscription)


  const [onetimeCreditsToPurchase, setOnetimeCreditsToPurchase] = useState(quantity);
  const [onetimeCreditDiscount, setOntimeCreditDiscount] = useState(0);


  // on click 
  const chooseSubscriptionOption = (plan) => {
    if(plan != selectedSubscriptionOption ){
      setSelectedSubscriptionOption(plan)
      setOntimeCreditDiscount(subscriptionOptionsDict[plan]['discountOnCredits'])
    }
  }

  useEffect(() => {
    if(loading) return;
    

    // set current membership plan using subscription membership_id
    var membership_id = userEntity?.access?.subscription?.membership_id
    if( membership_id ){
      setOntimeCreditDiscount(subscriptionOptionsDict[membership_id]['discountOnCredits'])
      setCurrentSubscription(membership_id)
      setSelectedSubscriptionOption(membership_id)
    } else {
      setCurrentSubscription('free')
      setSelectedSubscriptionOption(null)
      setOntimeCreditDiscount(subscriptionOptionsDict['free']['discountOnCredits'])
    }

  }, [userEntity, loading]);


  // on click 
  const chooseCreditsOption = (creditoption_id) => {
    if(creditoption_id != selectedCreditsOption ){
      setSelectedCreditsOption(creditoption_id)
      
      if(creditoption_id == 'bulk_0'){
        // set the onetimeCreditPrice
        setCustom(0)
      }
      // set the number of credits selected
      if(creditoption_id == 'essential'){
        // set the onetimeCreditPrice
        setCustom(essentialQuantity)
      }
      if(creditoption_id == 'bulk_8k'){
        // set the onetimeCreditPrice
        setCustom(bulk_8kQuantity)
      }
      if(creditoption_id == 'bulk_50k'){
        // set the onetimeCreditPrice
        setCustom(bulkQuantity_50k)
      }
      if(creditoption_id == 'bulk_100k'){
        // set the onetimeCreditPrice
        setCustom(bulkQuantity)
      }
      if(creditoption_id == 'bulk_500k'){
        // set the onetimeCreditPrice
        setCustom(bulkQuantity_500k)
      }

    }
  }

  const setCustom = (quantity) => {
    setOnetimeCreditsToPurchase(quantity)
    document.getElementById('customSlider').value= (quantity/1000000)**(1/2.5)*100;
    document.getElementById('customInput').value= quantity;
  }
  const chooseCustomSlider = (event) => {
    setSelectedCreditsOption("custom")
    quantity = Math.round(((event.target.value)/100)**2.5 * 1000000)
    setOnetimeCreditsToPurchase(quantity)

    document.getElementById('customInput').value= quantity;
  }
  const chooseCustomInput = (event) => {
    setSelectedCreditsOption("custom")
    quantity = event.target.value
    document.getElementById('customSlider').value= ((quantity/1000000)**(1/2.5))*100;
    setOnetimeCreditsToPurchase(quantity)


    
  }

  const selectedSubscriptionOption_url = () => {
    if( selectedSubscriptionOption && (selectedSubscriptionOption != currentSubscription) ){
      return("plan="+selectedSubscriptionOption+"&")
    } else {
      return("")
    }
  }


  return (
    <>
      <Layout fullScreen={true} hideTermsAccepted={true} allowAnonymous={true}>
        <div className="content container-fluid" style={{"overflowY":"auto"}}>
        <div className="row justify-content-lg-center">
          <div className="col" style={{'maxWidth':"1100px"}}>
          <div className="mt-10 mb-10">

                <div className="row text-center">
                  <div className="display-2">
                    Pricing

                    </div>
                </div>
          </div>







    <div className="row mb-3">
      <div className="col-12">
                <div id="upgrade">
                  <div className="row mt-5">
                    <div className="col">
                      <h2 className="mb-3">Choose a Monthly Plan</h2> 
                    </div>
                    <div className="col">
                          <div className="align-bottom" style={{"textAlign":"right"}}>
                            <a href="#compare">Compare Plans</a>
                          </div>
                    </div>
                  </div>
                </div>

                <Helmet>
                  <script src="/assets/vendor/hs-toggle-switch/dist/hs-toggle-switch.min.js"></script>
                  <script src="/assets/vendor/tom-select/dist/js/tom-select.complete.min.js"></script>
                </Helmet>
                <div className="mb-5">

                <div className="row mb-3">

                  <div className="col-md mb-3" id="advancedSettings">
                    <div className="h-100">
                    <div className="card card-lg form-check form-check-select-stretched h-100">
                      <div className="card-header text-center">
                        <input type="radio" className="form-check-input" name="billingPricingRadio" 
                        id={subscriptionOptions[0]['name']} 
                        checked={subscriptionOptions[0]['name'] === selectedSubscriptionOption} 
                        value={subscriptionOptions[0]['name']} 
                        onChange={() => chooseSubscriptionOption(subscriptionOptions[0]['name'])}/>
                        <label className="form-check-label" htmlFor={subscriptionOptions[0]['name']}></label>

                        <span className="card-subtitle"></span>
                        <h2 className="card-title display-4 text-dark mb-2">
                        Pay as you&nbsp;Go
                        </h2>
                      </div>

                      <div className="card-body d-flex justify-content-center p-4">
                        <ul className="list-checked list-checked-primary mb-0">
                          <li className="list-checked-item">1 Job = 300 KW Credits</li>
                          <li className="list-checked-item">1k KW Credits for $4.98</li>
                        </ul>
                      </div>
                        {!loading && user && !user.isAnonymous && userEntity && (currentSubscription == 'free' ) &&
                          <div className="text-center zi-1 mb-3">
                            <Link to="/billing/" className="btn btn-link text-uppercase">Current Plan</Link>
                          </div>
                        }
                        {(!user || user && user?.isAnonymous) &&
                        <>
                          <div className="text-center zi-1 mb-3">
                            <Link to="/register/" className="btn btn-soft-primary">Sign-up</Link>
                          </div>
                        </>
                        }

                    </div>
                    </div>
                  </div>





                  <div className="col-md mb-3">
                    <div className="h-100">
                    <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                      <div className="card-header text-center">
                        <input type="radio" className="form-check-input" name="billingPricingRadio" 
                        id="billingPricingRadio2" 
                        value={subscriptionOptions[1]['name']} 
                        checked={subscriptionOptions[1]['name'] === selectedSubscriptionOption} 
                        onChange={() => chooseSubscriptionOption(subscriptionOptions[1]['name'])}/>
                        <label className="form-check-label" htmlFor="billingPricingRadio2"></label>

                        <span className="card-subtitle">Basic</span>
                        <h2 className="card-title display-4 text-dark">
                          $<span id="pricingCount2">29</span>
                          <span className="fs-6 text-muted">/ month</span>
                        </h2>
                        <p className="card-text">Basic Starter</p>
                      </div>

                      <div className="card-body d-flex justify-content-center p-4">
                        <ul className="list-checked list-checked-primary mb-0">
                          <li className="list-checked-item">50 Jobs per Month</li>
                        </ul>
                      </div>
                        {currentSubscription == subscriptionOptions[1]['name'] ?
                          <div className="text-center zi-1 mb-3">
                          <Link to="/billing/" className="btn btn-link text-uppercase">Current Plan</Link>
                          </div> :
                          <>
                          <div className="text-center zi-1 mb-3">
                            <Link to={"/checkout/?plan=basic_starter&credits="+onetimeCreditsToPurchase+"&edit=/pricing/"} className="btn btn-soft-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                          </div>
                          </>
                        }

                    </div>
                    </div>
                  </div>







                    <div className="col-md mb-3">
                      <div className="h-100">
                      <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                        <div className="card-header text-center">
                          <input type="radio" className="form-check-input" name="billingPricingRadio" 
                          id="billingPricingRadio3" 
                          value={subscriptionOptions[2]['name']} 
                          checked={subscriptionOptions[2]['name'] === selectedSubscriptionOption} 
                          onChange={() => chooseSubscriptionOption(subscriptionOptions[2]['name'])}/>
                          <label className="form-check-label" htmlFor="billingPricingRadio3"></label>

                          <span className="card-subtitle">Professional</span>
                          <h2 className="card-title display-4 text-dark">
                            $<span id="pricingCount2">49</span>
                            <span className="fs-6 text-muted">/ month</span>
                          </h2>
                          <p className="card-text">Cancel Anytime</p>
                        </div>

                        <div className="card-body d-flex justify-content-center p-4">
                          <ul className="list-checked list-checked-primary mb-0">
                            <li className="list-checked-item">100 Jobs per Month</li>
                            <li className="list-checked-item">Save 50% on credits</li>
                          </ul>
                        </div>

                        {currentSubscription == subscriptionOptions[2]['name'] ?
                          <div className="text-center zi-1 mb-3">
                          <Link to="/billing/" className="btn btn-link text-uppercase">Current Plan</Link>
                          </div> :
                          <>
                          <div className="text-center zi-1 mb-3">
                            {!userEntity?.access?.subscription?.membership_id && <><b style={{"fontSize":"12px"}}>POPULAR!</b><br></br></>}
                            <Link to={"/checkout/?plan=pro_starter&credits="+onetimeCreditsToPurchase+"&edit=/pricing/"} className="btn btn-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                          </div>
                          </>
                        }
                      </div>

                      </div>
                    </div>

                    <div className="col-md mb-3">
                      <div className="h-100">
                      <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                        <div className="card-header text-center">
                          <input type="radio" className="form-check-input" name="billingPricingRadio" 
                          id="billingPricingRadio5" 
                          value={subscriptionOptions[3]['name']} 
                          checked={subscriptionOptions[3]['name'] === selectedSubscriptionOption} 
                          onChange={() => chooseSubscriptionOption(subscriptionOptions[3]['name'])}/>
                          <label className="form-check-label" htmlFor="billingPricingRadio5"></label>

                          <span className="card-subtitle">Business</span>
                          <h2 className="card-title display-4 text-dark">
                            $<span id="pricingCount2">299</span>
                            <span className="fs-6 text-muted">/ month</span>
                          </h2>
                          <p className="card-text">Cancel Anytime</p>
                        </div>

                        <div className="card-body d-flex justify-content-center p-4">
                          <ul className="list-checked list-checked-primary mb-0">
                            <li className="list-checked-item">600 Jobs per Month</li>
                            <li className="list-checked-item">Save 62% on credits</li>
                          </ul>
                        </div>
                        {currentSubscription == subscriptionOptions[3]['name'] ?
                          <div className="text-center zi-1 mb-3">
                          <Link to="/billing/" className="btn btn-link text-uppercase">Current Plan</Link>
                          </div> :
                          <div className="text-center zi-1 mb-3">
                            <Link to={"/checkout/?plan=team_starter&credits="+onetimeCreditsToPurchase+"&edit=/pricing/"} className="btn btn-soft-primary">Buy Now</Link>
                          </div>
                        }

                      </div>
                      </div>
                    </div>



                    </div>

                  </div>
                </div>

                </div>





                <h2 className="mt-3 mb-3">Purchase Additional Keyword Credits</h2>
                


                <div className="">
                    <div className="">


                  <div className="row mb-3">
                    <div className="col-12 col-md-4">
                      
                    <div className="col-md mb-3">
                        <div className="">

                          <div className="text-center mt-0">
                            <div className="row">
                              <div className="col col-sm" style={{"maxWidth":"500px"}}>
                                <input id="customInput" className="form-control form-control-lg mb-5 zi-1" style={{"fontSize":"30px"}}type="text" placeholder={quantity} onChange={chooseCustomInput}></input> 
                                                        
                                <input type="range" className="form-range" id="customSlider" onChange={chooseCustomSlider}/>
                              </div>
                            </div>
                              
                            </div>
                            <div className="card-body d-flex mb-0 pb-0">
                              <ul className="list-checked list-checked-primary mb-0">

                              { onetimeCreditsToPurchase>0 ?
                                <>
                                  <li className="list-checked-item fw-bolder">{Intl.NumberFormat('en-US', {
                                      maximumFractionDigits: 1
                                    }).format(onetimeCreditsToPurchase)} KW Credits</li>
                                  { onetimeCreditDiscount>0? 
                                  <><li className="list-checked-item fw-bolder display-7"><s>${Intl.NumberFormat('en-US', {
                                    maximumFractionDigits: 2
                                  }).format((onetimeCreditsToPurchase*pricePer1000Credits/1000*100)/100)} pay as you go</s></li>
                                  <li className="list-checked-item"> ${Intl.NumberFormat('en-US', {
                                      maximumFractionDigits: 2
                                    }).format((onetimeCreditsToPurchase*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100)} one time price</li></>
                                  :
                                  <li className="list-checked-item fw-bolder display-7">${Intl.NumberFormat('en-US', {
                                    maximumFractionDigits: 2
                                  }).format((onetimeCreditsToPurchase*pricePer1000Credits/1000*100)/100)} pay as you go</li>
                                  }
                                  { onetimeCreditDiscount>0? <><li className="list-checked-item">{(Math.round(onetimeCreditDiscount*100)).toFixed(0)}% discount</li></>:
                                  <li className="list-checked-item">Discounts available with monthly plan</li>}
                                  <li className="list-checked-item">KW credits never expire</li>
                                </>
                                :
                                <>
                                  <li className="list-checked-item">Only Pay when you need extra credits</li>
                                  <li className="list-checked-item">Discounts available with monthly plan</li>
                                  <li className="list-checked-item">KW credits never expire</li>
                                </>
                              }
                              </ul>
                            </div>
                          </div>
                        </div>
                    </div>



                    <div className="col-12 col-md-4">

                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_0" 
                            checked={'bulk_0' === selectedCreditsOption} 
                            value='bulk_0' 
                            onChange={() => chooseCreditsOption('bulk_0')}/>
                            <label className="form-check-label" htmlFor="bulk_0"></label>

                            <span className="card-subtitle">Pay when you run the job</span>
                            <h2 className="card-title display-5 text-dark">
                              0 KW Credits
                            </h2>
                          </div>

                        </div>

                      </div>
                      <div className="col-md mb-3 d-none d-lg-block">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="essential" 
                            checked={'essential' === selectedCreditsOption} 
                            value='essential' 
                            onChange={() => chooseCreditsOption('essential')}/>
                            <label className="form-check-label" htmlFor="essential"></label>

                            <span className="card-subtitle">The Minimum</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 1
                              }).format(essentialQuantity)} KW Credits
                            </h2>
                          </div>

                        </div>
                      </div>


                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_8k" 
                            checked={'bulk_8k' === selectedCreditsOption} 
                            value='bulk_8k' 
                            onChange={() => chooseCreditsOption('bulk_8k')}/>
                            <label className="form-check-label" htmlFor="bulk_8k"></label>

                            <span className="card-subtitle">Starter Pack</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 1
                              }).format(bulk_8kQuantity)} KW Credits
                            </h2>
                          </div>

                        </div>
                      </div>


                    </div>
                    <div className="col-12 col-md-4">



                    <div className="col-md mb-3 d-none d-lg-block">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_50k" 
                            checked={'bulk_50k' === selectedCreditsOption} 
                            value='bulk_50k' 
                            onChange={() => chooseCreditsOption('bulk_50k')}/>
                            <label className="form-check-label" htmlFor="bulk_50k"></label>

                            <span className="card-subtitle">Buy in Bulk</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 1
                              }).format(bulkQuantity_50k)} KW Credits
                            </h2>
                          </div>

                        </div>

                      </div>

                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_100k" 
                            checked={'bulk_100k' === selectedCreditsOption} 
                            value='bulk_100k' 
                            onChange={() => chooseCreditsOption('bulk_100k')}/>
                            <label className="form-check-label" htmlFor="bulk_100k"></label>

                            <span className="card-subtitle">Buy in Bulk</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 1
                              }).format(bulkQuantity)} KW Credits
                            </h2>
                          </div>

                        </div>

                      </div>

                      <div className="col-md mb-3 d-none d-lg-block">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_500k" 
                            checked={'bulk_500k' === selectedCreditsOption} 
                            value='bulk_500k' 
                            onChange={() => chooseCreditsOption('bulk_500k')}/>
                            <label className="form-check-label" htmlFor="bulk_500k"></label>

                            <span className="card-subtitle">Buy in Bulk</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 1
                              }).format(bulkQuantity_500k)} KW Credits
                            </h2>
                          </div>

                        </div>

                      </div>



                    </div>
                  </div>

                  </div>
                  </div>


                  <div className="mt-4 mb-6">
                    <Link 
                    style={{"width":"100%"}}
                    to={"/checkout/?"+selectedSubscriptionOption_url()+"credits="+onetimeCreditsToPurchase+"&edit="+currentUrl} 
                    className="btn btn-lg btn-primary float-right" >Buy Now</Link>
                  
                  </div>
                    <div>
                  </div>






    <div className="container mt-10 p-0" id="compare">



              
      <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9 pt-10">
        <div className="display-3">Feature Comparison</div>
        <h2 >Pick the plan that works best for you</h2>
      </div>


      
      <div className="table-responsive">
        <table className="table text-successtable-border border-light text-center">
          <thead className="">
            <tr>
              <td></td>
              <td className="text-center">

                {!loading && user  && !user.isAnonymous && userEntity && (currentSubscription == 'free' ) &&
                    <Link to="/billing/" className="mt-7 btn btn-link text-uppercase">Current Plan</Link>
                }
                {(!user || user && user?.isAnonymous) &&
                <Link to="/register/" className="mt-7 btn btn-soft-primary">Sign-up</Link> }
                { currentSubscription && currentSubscription != 'free' &&
                  <Link to="/checkout/?plan=free&credits=0&edit=/pricing/" className="btn mt-7 btn-soft-link">Downgrade</Link>
                } 
              </td>
              <td className="text-center">

                {currentSubscription == subscriptionOptions[1]['name'] ?
                  <Link to="/billing/" className="mt-7 btn btn-link text-uppercase">Current Plan</Link>:
                  <Link to="/checkout/?plan=basic_starter&credits=0&edit=/pricing/" className="btn mt-7 btn-soft-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                }
                
              </td>
              <td className="text-center">

                {currentSubscription == subscriptionOptions[2]['name'] ?
                  <Link to="/billing/" className="btn btn-link text-uppercase">Current Plan</Link>:
                  <>
                    {!userEntity?.access?.subscription?.membership_id && <><b style={{"fontSize":"12px"}}>POPULAR!</b><br></br></>}
                    <Link to="/checkout/?plan=pro_starter&credits=0&edit=/pricing/" className="btn mt-1 btn-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                  </>
                }
                
              </td>
              <td className="text-center">
                {currentSubscription == subscriptionOptions[3]['name'] ?
                  <Link to="/billing/" className="mt-7 btn btn-link text-uppercase">Current Plan</Link>:
                  <>
                    <Link to="/checkout/?plan=team_starter&credits=0&edit=/pricing/" className="mt-7 btn btn-soft-primary">Buy Now</Link>
                  </>
                }
                
              </td>
            </tr>
          </thead>
          <thead className="border-light">
            <tr>
              <th style={{"textAlign":"left"}} scope="col"><h3 className="mt-5">Overview</h3></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Pay as you&nbsp;Go</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Basic</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Professional</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Business</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="fw-bold">Free</td>
              <td className="fw-bold">$29/mo</td>
              <td className="fw-bold">$49/mo</td>
              <td className="fw-bold">$299/mo</td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Included Jobs per Month</th>
              <td>5 jobs/mo</td>
              <td>50 jobs/mo</td>
              <td>100 jobs/mo</td>
              <td>600 jobs/mo</td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Savings on Additional Keyword Credits</th>
              <td></td>
              <td></td>
              <td>Save 50% Off</td>
              <td>Save 62% Off</td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Chat Support</th>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Onboarding Support</th>
              <td></td>
              <td></td>
              <td></td>
              <td><Check size="30" color="#377dff"></Check></td>
            </tr>
          </tbody>
          <thead className="border-light">
            <tr>
              <th style={{"textAlign":"left"}} scope="col"><h3 className="mt-7">Niche Discovery</h3></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Pay as you&nbsp;Go</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Basic</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Professional</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Business</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Output Domains Per Job</th>
              <td>50 Max</td>
              <td>50 Max</td>
              <td>100 Max</td>
              <td>100 Max</td>
            </tr>
          </tbody>
          <thead className="border-light">
            <tr>
              <th style={{"textAlign":"left"}} scope="col"><h3 className="mt-7">Keyword Generator</h3></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Pay as you&nbsp;Go</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Basic</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Professional</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Business</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Output Keywords Per Job</th>
              <td>100 Max</td>
              <td>100 Max</td>
              <td>2000 Max</td>
              <td>2000 Max</td>
            </tr>
          </tbody>
          <thead className="border-light">
            <tr>
              <th style={{"textAlign":"left"}} scope="col"><h3 className="mt-7">Keyword Clustering</h3></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Pay as you&nbsp;Go</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Basic</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Professional</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Business</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Max Keywords Per Job</th>
              <td>100k</td>
              <td>500k</td>
              <td>1 Million</td>
              <td>No Limit</td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">First 500 KWs Included</th>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
              <td><Check size="30" color="#377dff"></Check></td>
            </tr>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Additional Keyword Credits ($/1000)</th>
              <td>$4.98</td>
              <td>$4.98</td>
              <td>$2.49</td>
              <td>$1.87</td>
            </tr>
          </tbody>
          <thead className="border-light">
            <tr>
              <th style={{"textAlign":"left"}} scope="col"><h3 className="mt-7">Brief Draft</h3></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Pay as you&nbsp;Go</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Basic</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Professional</strong></th>
              <th className="text-center" scope="col"><strong style={{"color":"lightgray"}}>Business</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{"textAlign":"left"}} scope="row">Priority Queue</th>
              <td>Slowest</td>
              <td>Normal</td>
              <td>Normal</td>
              <td><Check size="30" color="#377dff"></Check>Fastest</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td className="text-center">

                {!loading && user  && !user.isAnonymous && userEntity && (currentSubscription == 'free' ) &&
                    <Link to="/billing/" className="btn mt-7 btn-link text-uppercase">Current Plan</Link>
                }
                {(!user || user && user?.isAnonymous) &&
                <Link to="/register/" className="mt-7 btn btn-soft-primary">Sign-up</Link> }
                { currentSubscription && currentSubscription != 'free' &&
                  <Link to="/checkout/?plan=free&credits=0&edit=/pricing/" className="btn mt-7 btn-soft-link">Downgrade</Link>
                } 
              </td>
              <td className="text-center">

                {currentSubscription == subscriptionOptions[1]['name'] ?
                  <Link to="/billing/" className="btn mt-7 btn-link text-uppercase">Current Plan</Link>:
                  <Link to="/checkout/?plan=basic_starter&credits=0&edit=/pricing/" className="btn mt-7 btn-soft-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                }
                
              </td>
              <td className="text-center">

                {currentSubscription == subscriptionOptions[2]['name'] ?
                  <Link to="/billing/" className="btn mt-7 btn-link text-uppercase">Current Plan</Link>:
                  <>
                    <Link to="/checkout/?plan=pro_starter&credits=0&edit=/pricing/" className="btn mt-7 btn-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                    <br></br>{!userEntity?.access?.subscription?.membership_id && <><b style={{"fontSize":"12px"}}>POPULAR!</b></>}
                  </>
                }
                
              </td>
              <td className="text-center">
                {currentSubscription == subscriptionOptions[3]['name'] ?
                  <Link to="/billing/" className="btn mt-7 btn-link text-uppercase">Current Plan</Link>:
                  <>
                    <Link to="/checkout/?plan=team_starter&credits=0&edit=/pricing/" className="mt-7 btn btn-soft-primary">Buy Now</Link>
                  </>
                }
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      
    </div>





          <div className="content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
              <div className="display-4">Frequently Asked Questions?</div>
            </div>

            <div className="w-lg-65 mx-lg-auto">
              <div className="accordion accordion-flush accordion-lg" id="accordionFAQ">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsOne">
                    <a className="accordion-button" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      How do I cancel my membership subscription?
                    </a>
                  </div>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingBasicsOne" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      You can cancel your membership at any time however you will have the benefits of your current monthly membership through the
                      remainder of the billing cycle. Contact support and notify them that you wish to cancel.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsOne1">
                    <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne">
                      Can I downgrade my subscription?
                    </a>
                  </div>
                  <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingBasicsOne1" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      Yes, you will have the benefits of your current monthly membership through the
                      remainder of the billing cycle. 
                      If your new membership has any additional included reports you will get those immediately. You will still keep any existing included reports you already have.


                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsTwo">
                    <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Do the keyword credits expire?
                    </a>
                  </div>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingBasicsTwo" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      No, KW credits do not expire. Never however you are encouraged to use them ;)
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsThree">
                    <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Do jobs expire?
                    </a>
                  </div>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingBasicsThree" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      Yes. Every membership includes a limited number of jobs. These expire and get renewed on your billing cycle.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsFour">
                    <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      What if I run out of jobs?
                    </a>
                  </div>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingBasicsFour" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      You can always run each tool using pay-as-you go. Prices are listed on the tool themselves. 1 job = 300 KW CREDITS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
    </Layout>
    </>
  );
};
  
export default Subscriptions;

import React, { useEffect, useState, useRef } from "react";
 
import { Link } from "react-router-dom";

import styled from 'styled-components';

import { BiHelpCircle, BiCheckCircle, BiCircle} from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


import { useTour } from '@reactour/tour'


var Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;
const ReportRowItem = styled.div`
    margin-top:4px;
    float:left;
    width: 205px;
    margin-right: -125px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1em;
`;
const ReportRowDetails = styled.div`
    margin-left:205px;
    overflow:hidden;
`;
const ReportRow = styled.div`
    margin-bottom:10px;
    min-height: 20px;
`;

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


class PaywallOverlay extends React.Component {



  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a> <a className="btn btn-outline-secondary" href={"/pricing/"}>See Pricing!</a> 
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayPageKeywordExpansionV1({jobDetails, reportParts, isRedacted, user, downloadFile, currentStep}) {

  const { setIsOpen } = useTour()

  // get url parameters
  // if demo==true
  // open the tour
  const queryParameters = new URLSearchParams(window.location.search)
  const demo = queryParameters.get("demo")
  console.log(currentStep)
  if( demo=='true' && currentStep<=1){
    setIsOpen(true)
  }



  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  const renderTooltip = ({props, text}) => (
    <Tooltip {...props}>{text}</Tooltip>
  );





  return (
    <>

        {
            reportParts.length>0 && reportParts.map( (reportPart,i)=> {

              const resultsByDisplayType = ({reportPart,results}) => {
                // determine how to display it

                if( reportPart.displayTemplate=='raw-results' ){
                  // display everything in json
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}} key={"row_"+i}><pre style={{"overflow":"unset"}}>{JSON.stringify(results, null, 2) }</pre></div> )

                } else if( reportPart.style=='summary' || (!('displayTemplate' in reportPart) && reportPart.id=='summary' ) ){
                  console.log("results", results)
                  // simple summary one row for each in a dict
                  return(
                    <>
                      <div className="d-lg-flex justify-content-end">
                        <div className="flex-fill mb-3 mb-lg-4">
                            <div id="topsummary" className="card">

                                <div className="card-body">
                                    <ReportRow>
                                        <ReportRowItem>
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip({"text":"How many keyword queries you passed in"})}
                                            ><span className="all_kws_count">Input Keywords: < BiHelpCircle/></span>
                                          </OverlayTrigger>
                                        </ReportRowItem>
                                        <ReportRowDetails>{results?.summary?.n_queries}</ReportRowDetails>
                                    </ReportRow>
                                    <ReportRow>
                                        <ReportRowItem>
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip({"text":"How many keyword queries you passed in"})}
                                            ><span className="all_kws_count">Output Related Keywords: < BiHelpCircle/></span>
                                          </OverlayTrigger>
                                        </ReportRowItem>
                                        <ReportRowDetails>{results?.summary?.keyword_expansion_summary?.n_output_keywords}</ReportRowDetails>
                                    </ReportRow>
                                    <ReportRow>
                                        <ReportRowItem>
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip({"text":"How many keyword queries you passed in"})}
                                            ><span className="all_kws_count">Output Related Unique Keywords: < BiHelpCircle/></span>
                                          </OverlayTrigger>
                                        </ReportRowItem>
                                        <ReportRowDetails>{results?.summary?.keyword_expansion_summary?.n_unique_output_keywords}</ReportRowDetails>
                                    </ReportRow>
                                </div>
                            </div>

                            {user && <>
                            {user && user?.isAnonymous ? <>
                              <Link to={"/register/"}><div className="btn btn-soft-primary mt-2 mb-4 col-12">Register Now</div></Link>
                            <div className="content">
                              <div className="alert alert-soft-dark" role="alert">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Image Description" data-hs-theme-appearance="default"/>
                                  </div>

                                  <div className="flex-grow-1 ms-3">
                                    <h3 className="alert-heading mb-1">Attention!</h3>
                                    <p className="mb-0">You are using guest access. See <a href={"/pricing/"}>pricing</a> or <Link to="/register/">start free trial</Link> to unlock all the data and get more jobs!</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                            </>
                            :
                              <div className="btn btn-soft-primary mt-2 mb-4 col-12" onClick={downloadFile}>Download All Keywords</div>

                            }</>}
                        </div>

                      </div>
                    </>
                  )

                
                
                } else if( (reportPart.style=='keywords') || (!('displayTemplate' in reportPart) && reportPart.id=='keywords' ) || (reportPart.id=="data") ){
                  
                  // determine bgcolor
                  var shifted = results?.data.slice(0, results?.data.length)
                  shifted?.shift()
                  
                  // 
                  var bgcolor = []
                  var toggle = false

                  var show_level3 = []
                  var bgcolor_level3 = []
                  var toggle_level3 = true
                  var show_level2 = []
                  var bgcolor_level2 = []
                  var toggle_level2 = true
                  var show_level1 = []
                  var bgcolor_level1 = []
                  var toggle_level1 = true


                  var kws_grouped_by_pages = {}
                  var kwslinkable_grouped_by_pages = {}
                  var bestpage_grouped_by_pages = {}
                  // todo record page and what clusters shown in ... should only be in 1 otherwise split


                  for (let i = 0; i < results.data.length; i++) {


                    // best page
                    if(results.data[i].domain_to_track_url != null ){
                      // is it better than the one already?
                      if( results.data[i]['cluster_1'] in bestpage_grouped_by_pages){
                        // check if better
                        if( results.data[i].domain_to_track_organic_position < bestpage_grouped_by_pages[results.data[i]['cluster_1']].domain_to_track_organic_position ){
                          
                          // check if same url
                          bestpage_grouped_by_pages[results.data[i]['cluster_1']] = results.data[i]

                          // if not then record same urls for canibalization

                        }
                      } else {
                        // just add it
                        bestpage_grouped_by_pages[results.data[i]['cluster_1']] = results.data[i]
                      }

                    }
                    // aggregate
                    if( results.data[i]['cluster_1'] in kws_grouped_by_pages){
                      kws_grouped_by_pages[results.data[i]['cluster_1']].push(results.data[i])
                    } else {
                      kws_grouped_by_pages[results.data[i]['cluster_1']] = [results.data[i]]
                    }
                    // aggregate for link to briefdraft
                    if( results.data[i]['cluster_1'] in kwslinkable_grouped_by_pages){
                      kwslinkable_grouped_by_pages[results.data[i]['cluster_1']] = kwslinkable_grouped_by_pages[results.data[i]['cluster_1']]+"%0A" + results.data[i].keyword
                    } else {
                      kwslinkable_grouped_by_pages[results.data[i]['cluster_1']] = results.data[i].keyword
                    }
                    

                    if (toggle){
                      bgcolor.push("rgb(250 250 250)")
                    } else {
                      bgcolor.push("#fff")
                    }

                    if (toggle_level3){
                      show_level3.push("inline")
                    } else {
                      show_level3.push("none")
                    }
                    if (toggle_level2){
                      show_level2.push("inline")
                    } else {
                      show_level2.push("none")
                    }
                    if (toggle_level1){
                      show_level1.push("inline")
                    } else {
                      show_level1.push("none")
                    }

                    toggle_level3 = true
                    toggle_level2 = true
                    toggle_level1 = true

                    // toggle color by pillar
                    if( results.data[i]?.keyword != shifted[i]?.keyword ){
                      toggle = !toggle
                    }


                    if( results.data[i]?.cluster_3 == shifted[i]?.cluster_3 ){
                      toggle_level3 = false
                    }
                    if( results.data[i]?.cluster_2 == shifted[i]?.cluster_2 ){
                      toggle_level2 = false
                    }
                    if( results.data[i]?.cluster_1 == shifted[i]?.cluster_1 ){
                      toggle_level1 = false
                    }
                  }



                  var internallinkable_grouped_by_category = {}
                  var stylelinkable_grouped_by_category = {}

                      // create parameter for all internal links in this category cluster
                      // is there a link?
                  for( let keyword in bestpage_grouped_by_pages){

                    if( bestpage_grouped_by_pages[keyword]['cluster_3'] in internallinkable_grouped_by_category){
                      internallinkable_grouped_by_category[bestpage_grouped_by_pages[keyword]['cluster_3']] = internallinkable_grouped_by_category[bestpage_grouped_by_pages[keyword]['cluster_3']] +"%0A"+ keyword + " | " +bestpage_grouped_by_pages[keyword]["domain_to_track_url"]
                    } else {
                      internallinkable_grouped_by_category[bestpage_grouped_by_pages[keyword]['cluster_3']] = keyword + " | " +bestpage_grouped_by_pages[keyword]["domain_to_track_url"]
                    }
                  }

                  var cat_number = 0
                  var cluster_page_id = -1
                  var create_article_id = -1
                  return(
                    <>

                      { results?.data &&
                      <>
                        <div style={{"position":"relative", "minHeight":"600px"}} key={"row_"+i}>
                          <div className="mb-3"><a href="javascript:void(0)" onClick={downloadFile}>Download All</a></div>
                          <div style={{"zIndex":-100,"overflowX":"scroll"}}>
                            <table className="table table-sm table-nowrap" style={{"tableLayout": "fixed"}}>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" width="200px" style={{"maxWidth":"200px",
                                                "whiteSpace": "nowrap",
                                                "overflow": "hidden",
                                                "textOverflow": "ellipsis"}}>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip({"text":"Hub that the keywords belong to"})}
                                    >
                                      <span>Hub < BiHelpCircle></BiHelpCircle></span>
                                    </OverlayTrigger>
                                  </th>
                                  <th scope="col" width="200px" style={{"maxWidth":"200px",
                                                "whiteSpace": "nowrap",
                                                "overflow": "hidden",
                                                "textOverflow": "ellipsis"}}>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip({"text":"The original keyword that was used for the SERP"})}
                                    >
                                      <span>Input Keyword < BiHelpCircle></BiHelpCircle></span>
                                    </OverlayTrigger>
                                  </th>
                                  <th scope="col">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip({"text":"Type of Keyword"})}
                                    >
                                      <span>Related Type < BiHelpCircle></BiHelpCircle></span>
                                    </OverlayTrigger>
                                  </th>
                                  <th scope="col">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip({"text":"Cluster 1 represents groups of keywords at the 'page' level."})}
                                    >
                                      <span>Related Keyword < BiHelpCircle></BiHelpCircle></span>
                                    </OverlayTrigger>
                                  </th>
                                  <th scope="col" style={{"width":"180px"}}>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip({"text":"If you entered a domain to check rank then checked means you already have an article. otherise click to create a brief draft."})}
                                    >
                                      <span>Create Article < BiHelpCircle></BiHelpCircle></span>
                                    </OverlayTrigger>
                                  </th>

                                </tr>
                              </thead>
                              <tbody>
                                {results?.data.slice(0, 500).map((link, index) => {
                                    if(show_level1[index]=='inline' || true){
                                          cluster_page_id += 1
                                          return <tr id={"cluster_page_"+cluster_page_id} key={"link_"+index+1} style={{}}>
                                            
                                            <td id={"cluster_"+link?.cluster_2.replaceAll(" ","-")} style={{"maxWidth":"150px",
                                                      "whiteSpace": "nowrap",
                                                      "overflow": "hidden",
                                                      "border-bottom-color":"transparent",
                                                      "border-bottom-color":(show_level2[index+1]=='inline'||show_level3[index+1]=='inline')?"inherit":"transparent",
                                                      "textOverflow": "ellipsis"}}>
                                              
                                              <span style={{"maxWidth":"150px", "display":show_level2[index]}}>
                                                    <span title={link?.cluster_2} >
                                                      {link?.cluster_2}{link?.topic}{link?.category}
                                                    </span>

                                              </span>
                                            </td>
                                            <td id={"cluster_"+link?.cluster_2.replaceAll(" ","-")} style={{"maxWidth":"150px",
                                                      "whiteSpace": "nowrap",
                                                      "overflow": "hidden",
                                                      "border-bottom-color":"transparent",
                                                      "textOverflow": "ellipsis"}}>
                                              
                                              <span style={{"maxWidth":"150px"}}>
                                                    <span title={link?.input_query} >
                                                      {link?.input_query}
                                                    </span>

                                              </span>
                                            </td>
                                            <td id={"cluster_"+link?.cluster_2.replaceAll(" ","-")} style={{"maxWidth":"150px",
                                                      "whiteSpace": "nowrap",
                                                      "overflow": "hidden",
                                                      "border-bottom-color":"transparent",
                                                      "textOverflow": "ellipsis"}}>
                                              
                                              <span style={{"maxWidth":"150px"}}>
                                                    <span title={link?.cluster_2} >
                                                      {link?.keyword_type}
                                                    </span>

                                              </span>
                                            </td>
                                            <td style={{"minWidth":"140px","maxWidth":"150px",
                                                      "whiteSpace": "nowrap",
                                                      "overflow": "hidden",
                                                      "textOverflow": "ellipsis", 'backgroundColor':bgcolor[index]}}>
                                                
                                                  <span className="h4">
                                                    {link?.keyword}
                                                  </span>


                                                <p style={{"display":"none"}}>All Keywords:<br></br>{kws_grouped_by_pages[link?.cluster_1].map((kw,i)=>{
                                                  return( <>{kw?.keyword}<br></br> </>)
                                                })}</p>

                                            </td>
                                            <td style={{'backgroundColor':bgcolor[index]}}>
                                                { link?.domain_to_track_url &&  link?.domain_to_track_url !== null ?
                                                  <>
                                                    <BiCheckCircle 
                                                      size="30px" color="lightgray" 
                                                      title={bestpage_grouped_by_pages[link?.cluster_1]?.domain_to_track_url}></BiCheckCircle>
                                                    <Link title="Create Brief and First Draft" to={"/tools/brief-draft/?topic="+link?.keyword+"&category="+link?.input_query+"&keywords="+kwslinkable_grouped_by_pages[link?.cluster_1]+"&related_urls="+internallinkable_grouped_by_category[link?.cluster_3]} style={{"maxWidth":"150px", "display":show_level1[index]}}>
                                                        <span className="badge bg-soft-warning text-uppercase" style={{"color":"darkgray"}}>+ Brief Draft</span>
                                                    </Link>
                                                  </>
                                                  :
                                                  <>
                                                    
                                                    <span id={"create_article_"+(create_article_id +=1)}>
                                                    <BiCircle 
                                                      size="30px" color="green" 
                                                      title={bestpage_grouped_by_pages[link?.cluster_1]?.domain_to_track_url}></BiCircle>
                                                    <Link title="Create Brief and First Draft" to={"/tools/brief-draft/?topic="+link?.keyword+"&category="+link?.input_query+"&keywords=&related_urls="+internallinkable_grouped_by_category[link?.cluster_3]} style={{"maxWidth":"150px"}}>
                                                        <span className="badge bg-soft-primary text-dark text-uppercase">+ Brief Draft</span>
                                                    </Link>
                                                    </span>
                                                  </>
                                                }
                                            </td>
                                          </tr>
                                        
                                    }
                                  })

                                    
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    }
                    </>
                  )

                }
                
                // todo uses the id that must match!!!
                if( reportPart.id in results ){
                  // return json display of just this section
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}}><pre style={{"overflow":"unset"}}>{JSON.stringify(results[reportPart.id], null, 2) }</pre></div> )
                }
                
              }
              return(
                <>
                  <div className="reportPart content" id={reportPart['id']}>
                    <h2>{reportPart['name']}</h2>
                    { resultsByDisplayType({"reportPart":reportPart,"results":jobDetails?.results}) }
                  </div>
                </>
                )
              })
          }


    </>
  );
}

export default DisplayPageKeywordExpansionV1;
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Catalog from './pages/catalog';
import Datasources from './pages/catalog/datasources';
import ContentCatalog from './pages/catalog/content';
import QueriesCatalog from './pages/catalog/queries';

import Tools from './pages/tools';
import ToolDetails from './pages/tools/ToolDetails';

import Start from './pages/start';
import Continue from "./pages/continue";
import Verify from "./pages/verify";

import Terms from "./pages/terms";
import Support from "./pages/support";
import SupportThankYou from './pages/supportThankYou';

import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import FreeTrial from "./pages/authentication/FreeTrial";
import Reset from "./pages/authentication/Reset";
import FinishSignUp from './pages/authentication/firebaseFinishSignup';

import Pricing from './pages/account/Pricing';
import Checkout from './pages/account/Checkout';
import OrderComplete from './pages/account/orderComplete';
import ConfirmEmail from './pages/account/confirmEmail';

import Dashboard from "./Dashboard";
import JobDetails from "./pages/jobs/JobDetails";

import Analysis from "./pages/analysis/Analysis";

import AccountBilling from './pages/account/billing'
import AccountSettings from './pages/account/settings'
import AccountUsage from './pages/account/usage'



function App() {

    return (
        <Router>
            <Routes>

                <Route exact path="/" element={<Dashboard />} />

                <Route exact path="/catalog/" element={<Catalog />} />
                <Route exact path="/catalog/datasources/" element={<Datasources />} />
                <Route exact path="/catalog/content/" element={<ContentCatalog />} />
                <Route exact path="/catalog/queries/" element={<QueriesCatalog />} />

                <Route exact path="/playbook" element={<Tools />} />
                <Route exact path="/playbook/:tool_id" element={<ToolDetails />} />
                <Route exact path="/tools" element={<Tools />} />
                <Route exact path="/tools/:tool_id" element={<ToolDetails />} />
                <Route path='/start' element={<Start/>} />
                <Route path='/continue' element={<Continue/>} />
                <Route path='/verify' element={<Verify/>} />

                <Route exact path="/analysis" element={<Dashboard />} />
                <Route exact path="/analysis/:analysis_key/" element={<Analysis />} />
                <Route exact path="/analysis/:analysis_key/:action/" element={<Analysis />} />
                <Route exact path="/analysis/:analysis_key/:action/:job_id" element={<Analysis />} />

                <Route exact path="/activity/" element={<Dashboard />} />
                <Route exact path="/reports/" element={<Dashboard />} />
                <Route exact path="/jobs/" element={<Dashboard />} />

                <Route path="/reports/:job_id" element={<JobDetails />} />
                <Route path="/activity/:job_id" element={<JobDetails />} />
                <Route path="/jobs/:job_id" element={<JobDetails />} />

                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/free-trial" element={<FreeTrial />} />
                <Route exact path="/finishSignUp" element={<FinishSignUp />} />
                <Route exact path="/reset" element={<Reset />} />

                <Route exact path="/support" element={<Support />} />
                <Route exact path="/thank-you" element={<SupportThankYou />} />
                
                <Route exact path="/account" element={<AccountUsage />} />
                <Route exact path="/account/usage" element={<AccountUsage />} />
                <Route exact path="/account/settings" element={<AccountSettings />} />

                <Route exact path="/billing" element={<AccountBilling />} />
                <Route exact path="/subscribe/" element={<Checkout />} />
                <Route exact path="/checkout/" element={<Checkout />} />
                <Route exact path="/pricing/" element={<Pricing />} />
                <Route exact path="/order-complete/" element={<OrderComplete />} />
                <Route exact path="/confirm-email/" element={<ConfirmEmail />} />
                
            </Routes>
        </Router>
    );
}

export default App;

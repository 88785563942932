import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../../pages/authentication/firebase";
import { Speedometer } from 'react-bootstrap-icons';
import { QuestionCircle, PlusCircle } from 'react-bootstrap-icons';

const Navbar = ({user, userEntity, loading, hideUpgradeNotification, showComposer, toggleShowComposer, hideControls, logoLinkToPublicHome}) => {

  const public_url = process.env.REACT_APP_PUBLIC_URL? process.env.REACT_APP_PUBLIC_URL : "http://127.0.0.1:8000";
  
  var name = "" //const [name, setName] = useState("");
  const navigate = useNavigate();

  if( user?.isAnonymous ){
    name = "Guest Access"
  } else if (userEntity?.firstName) {
    name = userEntity?.firstName + " " + userEntity?.lastName;
  } else {
    name = ""
  }
  // use userEntity to determine subscription level
  const showUpgradeNotification = userEntity && !userEntity?.hasActiveSubscription && !hideUpgradeNotification;
  
  var style_icon = {
    "height": "22px"
  }

  return (
    <>
  <header id="header" className="navbar navbar-expand-lg navbar-height navbar-container navbar-bordered bg-white px-2 px-md-5">
    <div className="navbar-nav-wrap">
      <a className="navbar-brand" href={"https://contentcurator.com"}>
        <img src="/images/contentcurator.svg" style={style_icon}></img>
      </a>


      {hideControls ? 
        <div className="navbar-nav-wrap-content-start">
          <ul className="navbar-nav d-none d-sm-inline-block" style={{"flexDirection":"row", "padding":"1rem 1rem"}}>
          <li className="nav-item d-inline-block"><Link to="/tools/">Playbook</Link></li>
          </ul>
        </div>
      
      : <div className="navbar-nav-wrap-content-start">
        <ul className="navbar-nav d-none d-sm-inline-block" style={{"flexDirection":"row", "padding":"1rem 1rem"}}>
          { false &&
          <>
            <li className="nav-item d-none d-sm-inline-block"><a href="javascript:;" onClick={toggleShowComposer}></a></li>
            <li className="nav-item d-none d-sm-inline-block m-0">
              <div className="btn-group">
                <button className="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Default Project
                </button>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item">Default Project</a></li>
                  <li><hr className="dropdown-divider"/></li>
                  <li><a className="dropdown-item disabled" href="#">+ New Project</a></li>
                </ul>
              </div>
            </li>
          </>
          }
          <li className="nav-item d-none d-lg-inline-block"><Link to="/">Dashboard</Link></li>
          <li className="nav-item d-inline-block"><Link to="/tools/">Playbook</Link></li>
          <li className="nav-item d-none d-lg-inline-block"><Link to="/pricing/">Pricing</Link></li>
        </ul>
      </div>}

      <div className="navbar-nav-wrap-content-end">
        <ul className="navbar-nav">

          <li className="nav-item d-inline-block me-2">
              <Link to="/tools/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
              <PlusCircle/>
              </button></Link>
          </li>
        { (!loading && user)?
          <>
            { showUpgradeNotification && 
            <li className="nav-item d-none d-md-inline-block">
              { (userEntity?.planInCart && user.emailVerfied) &&
                <>
                  Please <Link className="nav-item d-none d-sm-inline-block" to={"/subscribe?plan="+userEntity?.planInCart}>complete your payment</Link> to upgrade</>
              }
              { !userEntity?.planInCart && user.emailVerfied && !userEntity?.hasActiveSubscription &&
                <>
                  See <a className="nav-item d-none d-sm-inline-block" href={"/pricing/"}>upgrade options</a> for faster reporting and more features
                </>
              }
              { user?.email && !user.emailVerfied && user.emailVerfied !=null &&
                <>Please verify your email or <a className="nav-item d-none d-sm-inline-block" href="" onClick={console.log("resend")}>
                  Click here to resend</a> the verification email.</>
              }
            </li>
            }

        
        
        { (user && !user?.isAnonymous  && userEntity && userEntity?.access?.credits) &&
          <>
            <li className="nav-item d-none d-lg-inline-block"><Link to="/billing/" style={{color:'gray'}}>
              <b>{Intl.NumberFormat('en-US', {
                  notation: "compact",
                  maximumFractionDigits: 0
                }).format(userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription) }</b> JOBS &bull;&nbsp; 
              <b>{userEntity.access.credits.gas < 10000 ? Intl.NumberFormat('en-US', {
                  maximumFractionDigits: 0
                }).format(userEntity.access.credits.gas) : Intl.NumberFormat('en-US', {
                  notation: "compact",
                  maximumFractionDigits: 0
                }).format(userEntity.access.credits.gas)}</b> KW CREDITS</Link>
            </li>
          </>
        }


        { (user && user.isAnonymous)?
          
          <>

          {false &&
          <li className="nav-item me-2 d-none d-sm-inline-block">
              <Link to="/support/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
              <QuestionCircle/>
              </button></Link>
          </li> }
          <li className="nav-item d-none d-sm-inline-block m-0">
              <Link className="nav-item" to="/login/?restart=true"><button className="btn btn-outline-white">
                Login
              </button></Link>
          </li>
          <li className="nav-item">
            <Link className="btn btn-sm btn-primary" to="/register">
              Sign-up
            </Link>
          </li>
          
          </>
          :
          <>
          { false &&
            <li className="nav-item me-2">
                <Link to="/support/"><button className="btn btn-ghost-secondary btn-icon rounded-circle" type="button">
                <QuestionCircle/>
                </button></Link>
            </li> }
            <li className="nav-item">
              <div className="dropdown">
                <a className="navbar-dropdown-account-wrapper" id="accountNavbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                  { (user?.photoURL)? 
                    <div className="avatar avatar-sm avatar-circle">
                      <img className="avatar-img" src={user?.photoURL}/>
                      <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                    </div> :
                    <div className="avatar avatar-sm avatar-dark avatar-circle">
                      <span className="avatar-initials"></span>
                      <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                    </div>
                  }
                </a>

                <div style={{"minWidth":"270px"}} className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                  <div className="dropdown-item-text">
                    <div className="d-flex align-items-center">
                      { (user?.photoURL)? 
                      <div className="avatar avatar-sm avatar-circle">
                        <img className="avatar-img" src={user?.photoURL}/>
                      </div> :
                      <div className="avatar avatar-sm avatar-dark avatar-circle">
                        <span className="avatar-initials"></span>
                      </div>
                      }
                      <div className="flex-grow-1 ms-3">
                        <h5 className="mb-0">{name}</h5>
                        <p className="card-text text-body"><b>{user?.email}</b><br/>
                        {Intl.NumberFormat('en-US', {
                              notation: "compact",
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription) } JOBS
                            &bull;&nbsp;
                            {userEntity?.access?.credits.gas < 10000 ? Intl.NumberFormat('en-US', {
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits.gas) : Intl.NumberFormat('en-US', {
                              notation: "compact",
                              maximumFractionDigits: 0
                            }).format(userEntity?.access?.credits.gas)} KW CREDITS
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-divider"></div>
                  

                  <Link className="dropdown-item" to="/reports/">Recent Activity</Link>
                  { false &&
                  <Link className="dropdown-item" to="/catalog/">Content Catalog</Link>}
                  <Link className="dropdown-item" to="/tools/">All Tools</Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/billing/">Billing</Link>
                  <Link className="dropdown-item" to={"/pricing/"}>Pricing</Link>
                  <Link className="dropdown-item" to="/support/">Support</Link>


                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/login/" onClick={logout}>Sign out</a>
                </div>
              </div>
            </li>
            </>
          }
          
          </>:
            <>
            </>
          }
          { !loading && user == null && 
            <>
              <Link className="nav-item" to="/login"><button className="btn btn-outline-white">
                Login
              </button></Link>
              <Link className="nav-item" to="/register"><button className="btn btn-primary">
                Sign-up
              </button></Link>
            </>
            }
        </ul>
      </div>
    </div>
  </header>

    </>
  );
};
  
export default Navbar;
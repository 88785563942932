import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  EmailAuthProvider,
  getAuth,
  updateProfile,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInAnonymously,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink, 
  signInWithEmailLink,
  linkWithCredential,
  linkWithPopup, 
} from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBG_5ZYFfmWF39BUiqNI-NSXLIS_Es9HRg",
  authDomain: "cbot-engine.firebaseapp.com",
  databaseURL: "https://cbot-engine.firebaseio.com",
  projectId: "cbot-engine",
  storageBucket: "cbot-engine.appspot.com",
  messagingSenderId: "961042046682",
  appId: "1:961042046682:web:cde997a8001810443e0fe3",
  measurementId: "G-5CBM47LN8K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const createAnonymousUser = async () => {
  try {
    const res = await signInAnonymously(auth);
  } catch (err) {
    console.error(err);
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
  } catch (err) {
    console.error(err);
  }
};

const linkUserWithGoogle = async (user) => {
  const provider = new GoogleAuthProvider();
  const res = await linkWithPopup(auth.currentUser, provider).then((result) => {
    // Accounts successfully linked.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const user = result.user;
    return user
  }).catch((error) => {
    return error
  });
  return res
};

const logInWithEmailAndPassword = async (email, password) => {
  const res = signInWithEmailAndPassword(auth, email, password).catch((error) => {
      // An error occurred
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log( "errorCode", errorCode )
      console.log( "errorMessage", errorMessage )
      return {"errorCode": errorCode, "errorMessage": errorMessage}
      // ...
    });
  return res
};


const resendEmailtoUser = async () => {
  try {
    // send verification mail.
    sendEmailVerification( auth.currentUser );
  } catch (err) {
    return err
  }
};


const registerWithEmailAndPassword = async (name, email, password) => {
  try {

    console.log("... registering new user???")
    const res = createUserWithEmailAndPassword(auth, email, password).then((userCredential)=>{
      const user = userCredential.user;

      // send verification mail.
      console.log("send email")
      sendEmailVerification( user );

      console.log("update name")
      // save name to user
      const res2 = updateProfile(user, {
        displayName: name
      }).then(() => {
        // Profile updated!
        console.log("name added")
        // ...
      }).catch((error) => {
        // An error occurred
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log( "errorCode", errorCode )
        console.log( "errorMessage", errorMessage )
        return {"errorCode": errorCode, "errorMessage": errorMessage}
        // ...
      });

    })
    .catch((error) => {
      console.log("ERROR WITH register new user")
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log( "errorCode", errorCode )
      console.log( "errorMessage", errorMessage )
      return {"errorCode": errorCode, "errorMessage": errorMessage}
      // ..
    });


    return res
  } catch (err) {
    return err
  }

  
};

const linkUserWithEmailAndPassword = async (name, email, password) => {
    const credential = EmailAuthProvider.credential(email, password);
    const res = await linkWithCredential(auth.currentUser, credential)
      .then((userCredential) => {

        const user = userCredential.user;

        // send verification mail.
        console.log("send email")
        sendEmailVerification( user );

        console.log("update name")
        // save name to user
        const res2 = updateProfile(user, {
          displayName: name
        }).then(() => {
          // Profile updated!
          console.log("name added")
          // ...
        }).catch((error) => {
          // An error occurred
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log( "errorCode", errorCode )
          console.log( "errorMessage", errorMessage )
          // ...
        });

        return user
      }).catch((error) => {
        return error
      });
  return res
};

const sendPasswordReset = async (email) => {
  sendPasswordResetEmail(auth, email);
  return "If you have an account then an email was sent with the password reset link!";
};

const saveAnonymousViaEmail = async (email, uid) => {
  console.log(email)
  var include_uid = ""
  if(uid){
    include_uid = "uid="+uid+"&"
  }

  const app_url = process.env.REACT_APP_URL || "http://localhost:3000";
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: app_url + '/finishSignUp?'+include_uid,
    // This must be true.
    handleCodeInApp: true
  };

  // send user email
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
  .then(() => {
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    // ...
    // save email to user
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

};

const finishSignUp = async () => {
  var res = ""
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
      window.localStorage.setItem('emailForSignIn', email);
    }
    // The client SDK will parse the code from the link for you.
    
    var res = signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        //window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        //console.log( "is new user?" )
        //console.log( result.additionalUserInfo.isNewUser )
        return result
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        return error
      });
  }
  return res
};

const logout = () => {
  signOut(auth);
};




const saveUserToDatastore = ( user, name) => {
  // saves the user's details to the datastore
  console.log("save to datstore")
  // create a stripe user if none exists yet


}


export {
  auth,
  signInWithGoogle,
  linkUserWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  linkUserWithEmailAndPassword,
  createAnonymousUser,
  sendPasswordReset,
  resendEmailtoUser,
  logout,
  saveAnonymousViaEmail,
  finishSignUp,
};
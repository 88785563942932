import React, { useEffect, useState } from "react";
import CountrySelect from './CountrySelect';
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


const UpdateBillingAddress =({user, userEntity, setShowPaymentMethod, subscriptionOption, planInShoppingCart, setBillingInfo, selectedCredits, 
  selectedSubscription, editLink, autoLaunch}) =>{
  
  const [updatedUserEntity, setUpdatedUserEntity] = useState(userEntity);
  const [loaded, setLoaded] = useState(false);

  // form controls
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    
    // check form for validity
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // save to userEntity
      updateUserInformation();
    }
    setValidated(true);
  };

  const updateUserEntity = (key, value) => {
    const updated = updatedUserEntity
    updated[key] = value
    setUpdatedUserEntity(updated)
  }

  useEffect(() => {
    if (loaded) return;
    
    var price_id_field = "price_ids"
    if (process.env.REACT_APP_ACCESS_FIELD == "access_dev" ){
      var price_id_field = "price_ids_dev"
    }

    // pass subscriptionOption defaults to the userEntity
    if(subscriptionOption){
    updateUserEntity(price_id_field, subscriptionOption[price_id_field])
    }
    if(planInShoppingCart){
    updateUserEntity("planInCart",planInShoppingCart)
    }
    
    if( updatedUserEntity.email == null && user && user.email ){
      updateUserEntity("email", user.email)
    }
    if( updatedUserEntity.firstName == null && user && user.displayName){
      if(user.displayName.includes(" ") ){
        updateUserEntity( "firstName", user.displayName.split(" ")[0] )
      }
      if(user.displayName.includes(" ") ){
        updateUserEntity( "lastName", user.displayName.substring(user.displayName.split(" ")[0].length+1) )
      }
    }

    setLoaded(true)
  }, [loaded]);

  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  
  const saveUserEntity = (userEntity) => {
    axios.post(
      api_url + '/v1/user/update', userEntity,
      {
        headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}
    });
  };
  
  const updateUserInformation = () => {

    // get or create customer in stripe and save to existing user_entity in datastore
    axios.post(
      api_url + '/v1/user/stripe', 
      {
        'userEntity':userEntity,
        'subscriptionOption':subscriptionOption,
        'selectedCredits': selectedCredits,
        'selectedSubscription': selectedSubscription,
        'metadataCredits': {'autoLaunch':autoLaunch}
      }, { headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken} }
    ).then((response)=> {
      setBillingInfo(response.data)
    })
    

    // update user_entity in db
    saveUserEntity(updatedUserEntity)

    // if there is a stripe user id then continue to payment
    setShowPaymentMethod(true)
  }



  return (
    <>
    
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div id="checkoutStepDelivery" className="active mb-10">
                <div className="card mb-10 mb-lg-10">
                  <div className="card-header">
                    <h4 className="card-header-title">Account Details</h4>
                  </div>
                  <div className="card-body">
                    
        
          
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="firstNameDeliveryAddressLabel" className="form-label">First name</label>
                          <input required type="text" className="form-control" name="firstNameDeliveryAddress" id="firstNameDeliveryAddressLabel" onChange={(e) => updateUserEntity("firstName", e.target.value)} defaultValue={updatedUserEntity['firstName']} placeholder="Clarice" aria-label="Clarice"/>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="lastNameDeliveryAddressLabel" className="form-label">Last name</label>
                          <input required type="text" className="form-control" name="lastNameDeliveryAddress" id="lastNameDeliveryAddressLabel" onChange={(e) => updateUserEntity("lastName", e.target.value)} defaultValue={updatedUserEntity['lastName']} placeholder="Boone" aria-label="Boone"/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="emailDeliveryAddressLabel" className="form-label">Email</label>
                          <input required type="text" className="form-control" name="emailDeliveryAddress" id="emailDeliveryAddressLabel" value={updatedUserEntity['email']} disabled/>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="phoneDeliveryLabel" className="form-label">Phone</label>
                          <input required type="text" className="js-input-mask form-control" name="phoneDeliveryName" id="phoneDeliveryLabel" onChange={(e) => updateUserEntity("phone", e.target.value)} defaultValue={updatedUserEntity['phone']} placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" data-hs-mask-options="{
                                   &quot;mask&quot;: &quot;+0(000)000-00-00&quot;
                                 }"/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-8">
                        <div className="mb-4">
                          <label htmlFor="deliveryStreetAddressLabel" className="form-label">Street address</label>
                          <input required type="text" className="form-control" name="deliveryStreetAddress" id="deliveryStreetAddressLabel" onChange={(e) => updateUserEntity("address_street", e.target.value)} defaultValue={updatedUserEntity['address_street']} placeholder="Street Address" aria-label="Street Address"/>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="mb-4">
                          <label htmlFor="deliveryUnitLabel" className="form-label">Unit</label>
                          <input type="text" className="form-control" name="deliveryUnit" id="deliveryUnitLabel"  onChange={(e) => updateUserEntity("address_unit", e.target.value)} defaultValue={updatedUserEntity['address_unit']} placeholder="Unit" aria-label="Unit"/>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="deliveryCityLabel" className="form-label">City</label>
                      <input required type="text" className="form-control" name="deliveryCity" id="deliveryCityLabel" onChange={(e) => updateUserEntity("address_city", e.target.value)} defaultValue={updatedUserEntity['address_city']} placeholder="City" aria-label="City"/>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-4">
                          <CountrySelect updateUserEntity = {updateUserEntity} updatedUserEntity={updatedUserEntity}/>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="DeliveryZipCodeLabel" className="form-label">Zip code <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="You can find your code in a postal address." aria-label="You can find your code in a postal address."></i></label>

                          <input required type="text" className="js-input-mask form-control" name="DeliveryZipCode" id="DeliveryZipCodeLabel" onChange={(e) => updateUserEntity("address_postal_code", e.target.value)} defaultValue={updatedUserEntity['address_postal_code']} placeholder="Zip" aria-label="Zip" data-hs-mask-options="{
                                   &quot;mask&quot;: &quot;AA0 0AA&quot;
                                 }"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-10">
                  <div className="ms-auto w-100" style={{"textAlign":"center"}}>
                    <Button className="btn btn-lg btn-primary w-100 mb-3" type="submit">Continue</Button>
                    {editLink &&
                    <Link className="btn btn-ghost-secondary" to={editLink}>
                      Edit Order Details
                    </Link>}
                  </div>
                </div>
              </div>

    </Form>
        </>
    )
}

export default UpdateBillingAddress;
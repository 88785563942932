import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../pages/authentication/firebase";
import { Helmet } from "react-helmet"

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  useEffect(() => {
    if (loading) return;
    //if (user) navigate("/");
  }, [user, loading]);

  const resetPassword = () => {
    sendPasswordReset(email).then( (response) => {
      setSuccessMessage( response )
    })
  };

  var pageTitle = "Reset Password"
  
  return (
    <>
    <Helmet>
      <title>{pageTitle? pageTitle+" - Content Curator App" : "Content Curator App"}</title>
    </Helmet>

    <div className="content container-fluid" style={{"overflowY":"auto"}}>
      <main id="content" role="main" className="main">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start login-bg">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>

        <div className="container py-5 py-sm-7">
          <a className="d-flex justify-content-center mb-5" href={public_url}>
            <img className="zi-2 navbar-brand-logo" src="/images/contentcurator.svg"></img>
          </a>
          <div className="mx-auto login-module">
          { errorMessage && <>
            <div className="card card-lg mb-5 bg-soft-danger">
              <div className="card-body">
                <div className="text-danger">{errorMessage}</div> 
              </div>
            </div>
          </>}
          { successMessage && <>
            <div className="card card-lg mb-5 bg-soft-success">
              <div className="card-body">
                <div className="text-success">{successMessage}</div> 
              </div>
            </div>
          </>}
            <div className="card card-lg mb-5">
              <div className="card-body">
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Reset Your Password</h1>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="form-label" htmlFor="signinSrEmail">Your email</label>
                    <input type="email" className="form-control form-control-lg" name="email" 
                    id="signinSrEmail" tabindex="1" placeholder="email@address.com" aria-label="email@address.com" required
                    value={email}
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}/>
                    <span className="invalid-feedback">Please enter a valid email address.</span>
                  </div>

                  
                  <div className="d-grid mb-6">
                    <button type="submit" className="btn btn-primary btn-lg" onClick={resetPassword}>Send password reset email</button>
                  </div>
                    <div className="text-center">
                      Don't have an account? <Link to="/register">Register</Link> now.
                    </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </>
  );
}

export default Reset;

import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'



export default function WebSocketCall_results({ socket }) {
  // https://medium.com/@adrianhuber17/how-to-build-a-simple-real-time-application-using-flask-react-and-socket-io-7ec2ce2da977
  const [markdownMessages, setMarkdownMessages] = useState([]);

  useEffect(() => {
    // process log messages
    socket.on("md_stream", (data) => {
        setMarkdownMessages([...markdownMessages, data]);
    });
  }, [socket, markdownMessages]);


  return (
    <>
        {markdownMessages.map((data) => {
          return <span key={data.created}><ReactMarkdown>{data?.value}</ReactMarkdown></span>;
        })}
    </>
  );
}
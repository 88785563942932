import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams, Link } from "react-router-dom";
import { auth, logout } from "../authentication/firebase";
import axios from 'axios'
import { io } from "socket.io-client";
import { Download, PlusCircleDotted } from 'react-bootstrap-icons';
import { TourProvider } from '@reactour/tour'

import Layout from "../../components/Layout";
import WebSocketCall from "../../components/WebSocketCall";
import WebSocketCall_results from "../../components/WebSocketCall_results"
import Spinner from 'react-bootstrap/Spinner';

import "./JobDetails.css"

import SharePopup from "../../components/SharePopup";

import DisplayNicheDiscoveryV1 from "./JobDetails_template_niche-discovery_1";
import DisplayKeywordGenteratorV1 from "./JobDetails_template_keyword-generator_1";
import DisplayBriefDraftV1 from "./JobDetails_template_brief-draft_1";

import DisplayKeyphraseExtractorV1 from "./JobDetails_template_keyphrase-extractor_1";
import DisplayDomainAnalysisV1 from "./JobDetails_template_domain-analysis_1";
import DisplayPageInspectionOriginalityV1 from "./JobDetails_template_page-inspection-originality_1";
import DisplayPageInspectionInclusivityTextV1 from "./JobDetails_template_inclusivity-text_1";
import DisplayPageKeywordClusteringV1 from "./JobDetails_template_keyword-clustering_1"
import DisplayPageKeywordExpansionV1 from "./JobDetails_template_keyword-expansion_1"
import DisplayURLTopicsV1 from "./JobDetails_template_url-topics_1"

import { Helmet } from "react-helmet";


const AnalysisDetails =({analysis_key, job_id}) =>{

  //const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [
    {
      selector: "#topsummary",
      content: "Congratulations! You have new article opportunities! ... Click next to see how:",
    },
    {
      selector: "#cluster_hub_0",
      content: "A hub is a topical cluster of one or more pages.",
    },
    {
      selector: "#cluster_page_0",
      content: "Each spoke is a group of highly related keywords that are distinct from all other hubs or spokes. You just need one article to target all these keywords at once.",
    },
    {
      selector: "#create_article_0",
      content: "You don't have a URL ranking for this cluster yet!.. Click here to create a new article.",
    },
  ];

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [userEntity, setUserEntity] = useState({})

  const [jobDetails, setJobDetails] = useState({})
  const [templateVersion, setTemplateVersion] = useState(null)
  const [reportParts, setReportParts] = useState([])
  const [socketInstance, setSocketInstance] = useState("");
  const [loadingSocket, setLoadingSocket] = useState(true);

  // for users that aren't allowed to see the report
  const [isRedacted, setIsRedacted] = useState(false);

  // for public reports
  const [hideControls, setHideControls] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const [warn, setWarn] = useState(null);


  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  const REACT_APP_API_SOCKET_DOMAIN = process.env.REACT_APP_API_SOCKET_DOMAIN || "127.0.0.1:5000";

  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setJobDetails(response.data)
      }).catch(error => {
        console.log(error)
      });

    } else {
      // hide controls because it is anonymous
      setHideControls(true)
      // get the report with anonymous
      axios.get(api_url + '/v1/job/'+job_id, {headers: {'anonymous':true}}).then(response => {
        setJobDetails(response.data)
      }).catch(error => {
        console.log(error)
      });

    }

    // connect to socket with room
    const socket = io(REACT_APP_API_SOCKET_DOMAIN +"/", {
      transports: ["websocket"],
      cors: {
        origin: api_url+"/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (data) => {
      socket.emit("join",{"room":"room_job_"+job_id})
    });

    setLoadingSocket(false);



    // determine if this report should be redacted or not
    if( user?.isAnonymous ){
      setIsRedacted(true)
    }
  }, [user, loading])


  const updateJobStatus = (status) => {
    // update the status
    var newJobDetails = jobDetails
    newJobDetails['status'] = status
    setJobDetails(newJobDetails)
  }
  useEffect(() => {
    if (loadingSocket) return;
    if (socketInstance) {
    
    // process status messages
    socketInstance.on("status", (data) => {
      if( data?.value =='Complete' || data?.value =='Failed' ){
        // console.log("Complete refresh")
        // re-pull everything
        axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setJobDetails(response.data)
        }).catch(error => {
          console.log(error)
        });
      } else {
        updateJobStatus(data.value)
      }
    });


    socketInstance.on("results", (data) => {
      // innitializie results if doesn't exist
      var newJobDetailsResults = jobDetails?.results? jobDetails.results : {};
      for (var item in data?.data) {
        newJobDetailsResults[item] = data.data[item]
      }
      var newJobDetails = jobDetails;
      newJobDetails['results'] = newJobDetailsResults
      setJobDetails( newJobDetails )

    });


    // put this into reactive element so it updates as things are coming in!
    if(jobDetails?.template?.parts){
      setReportParts( jobDetails?.template?.parts )
    } else if(jobDetails.results) {
      setReportParts( [{
        "name":"Raw Results",
        "id":"raw-results",
        "displayTemplate":"raw-results"
      }] )
    }

    setTemplateVersion(jobDetails?.template?.version)
  }
  }, [socketInstance, loadingSocket, jobDetails]);




  var showComposer = false
  const setData = (showComposerVal) => {
    showComposer = showComposerVal
  }

  useEffect(() => {
    // redirect if no credits and anonymous
    if( user?.isAnonymous && userEntity?.access && (userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription)==0){
      navigate("/register/?restart=true&url="+encodeURIComponent(window.location.pathname+window.location.search))
    }

    // warn if email not verified
    if( user && userEntity && user?.email && user?.emailVerified == false){
      setWarn("Please verify your account by clicking the link in your email.")
    }
  }, [user, userEntity]);


  useEffect(()=>{
    if(jobDetails?.isPublic){
      setIsPublic(true)
    }
    // turn off socket if completed
    if( jobDetails['status']=="Completed" || jobDetails['status']=="Failed" ){
      setLoadingSocket(true)
    }


    




  }, [jobDetails])
    

  // if there is a template then use that!
  // otherwise use the default report by part
  // if no parts, then show all as json-raw
  const dipslayJobResults = ( jobDetails, templateVersion, user, currentStep ) => {
    
    if (jobDetails.results?.error){
      return(<div className="container-fluid content" id="raw-results"> <pre>{JSON.stringify(jobDetails?.results, null, 2)}</pre> </div>)
    }
    //
    // put the latest versions at the top
    //
    // niche-discovery view template v1
    if ( jobDetails?.inputs?.report_type == "niche-discovery" && jobDetails?.template?.version == 1 ){
      return( <DisplayNicheDiscoveryV1 jobDetails={jobDetails} reportParts={reportParts} isRedacted={isRedacted} user={user}/> )
    } 
    else if ( jobDetails?.inputs?.report_type == "keyword-generator" ){
      return( <DisplayKeywordGenteratorV1 jobDetails={jobDetails} reportParts={reportParts} isRedacted={isRedacted} user={user} downloadFile={downloadFile}/> )
    } 
    else if ( jobDetails?.inputs?.report_type == "brief-draft" ){
      return( <DisplayBriefDraftV1 jobDetails={jobDetails} reportParts={reportParts} isRedacted={isRedacted} user={user}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "niche-discovery" && jobDetails?.template?.version == null ){
      return( <>.</> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "keyphrase-extractor" || jobDetails?.inputs?.report_type == "page-inspection" ){
      return( <DisplayKeyphraseExtractorV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "bulk-url-topics" ){
      return( <DisplayURLTopicsV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "domain-analysis" ){
      return( <DisplayDomainAnalysisV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "page-inspection-originality" ){
      return( <DisplayPageInspectionOriginalityV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "inclusivity-text" ){
      return( <DisplayPageInspectionInclusivityTextV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    else if ( jobDetails?.inputs?.report_type == "keyword-clustering" ){
      return( <DisplayPageKeywordClusteringV1 jobDetails={jobDetails} reportParts={reportParts} user={user} downloadFile={downloadFile} currentStep={currentStep}/>)
    } 
    else if ( jobDetails?.inputs?.report_type == "bulk-keyword-expansion" ){
      return( <DisplayPageKeywordExpansionV1 jobDetails={jobDetails} reportParts={reportParts} user={user} downloadFile={downloadFile} currentStep={currentStep}/>)
    } 
    else if ( jobDetails?.inputs?.report_type == "serp-similarity" ){
      return( <DisplayPageKeywordClusteringV1 jobDetails={jobDetails} reportParts={reportParts} user={user} downloadFile={downloadFile} currentStep={currentStep}/> )
    } 

    
    // default view for any report type
    else if (jobDetails?.inputs?.report_type){
      
      return( 
        <div className="container-fluid content" id="summary">
          Display results for {jobDetails?.inputs?.report_type}
          <pre>{JSON.stringify(jobDetails?.results, null, 2)}</pre>
        </div>
      )
    
    } 
    
    // Otherwise it is still loading...
  }



  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  // Download file
  const downloadFile = () => {
    // text content 
    axios({
        url: api_url + '/v1/job/'+job_id+'?format=csv',
        method: "GET",
        headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken},
        responseType: "blob" 
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            'contentcurator-results-job-'+job_id+'.csv'
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
  }
  
  // tour
  const [tourCurrentStep, setTourCurrentStep] = useState(0);

  return (
    <>
      <Layout pageTitle={"Report Details"} allowAnonymous={true} hideControls={hideControls} isPublic={isPublic} customReportingLayout={true}>
        
      
      { jobDetails && jobDetails.status== 'In Queue' && jobDetails?.id && 
        // <!-- Twitter conversion tracking for report created -->
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            twq('event', 'tw-ofrd2-ofrd7', {
              conversion_id: '`+jobDetails?.id+`'
            });`
        }]}
        />
      }


      <div className="page-header job-header container-fluid" style={{"marginBottom":"0","paddingTop":"1rem","paddingBottom":"1rem"}}>
          <div className="row align-top" style={{"flexWrap":"unset"}}>

            <div className="col-sm mb-2 mb-sm-0">
             
             {jobDetails?.inputs?.report_type && <>
              { jobDetails.status== 'Complete' && <span className="legend-indicator bg-success mb-1"></span> }
              { (jobDetails.status== 'Processing' || jobDetails.status== 'In Queue') && <span className="legend-indicator spinner-grow mb-1"></span> }
              { jobDetails.status== 'Failed' && <span className="legend-indicator bg-danger mb-1"></span> }
              { (jobDetails.status!= 'Failed' && jobDetails.status!= 'Processing' && jobDetails.status != 'In Queue' && jobDetails.status!= 'Complete') && <span className="legend-indicator bg-warning mb-1"></span> }
              
              {jobDetails.status}
               &nbsp;&bull;&nbsp; 
               <Link to={"/tools/"+jobDetails?.inputs?.report_type+"/"} style={{color:"var(--bs-body-color)"}}>
                {jobDetails?.inputs?.report_type.replaceAll("-"," ").toUpperCase()}</Link></>
                
                }
            
              <h1 style={{"whiteSpace":"nowrap"}}>{jobDetails['name']}</h1>
              
            </div>
              <div className="col-auto align-baseline">
                <Link title={"New job using "+ jobDetails?.inputs?.report_type.replace("-"," ")} className="btn btn-outline-primary" to={"/tools/"+jobDetails?.inputs?.report_type}>
                  + New
                </Link>
              </div>
            { !hideControls &&
            <>
              { user && !user.isAnonymous &&
              <div className="col-auto align-baseline">
                <SharePopup job_id={job_id} user={user} analysis_key={analysis_key}></SharePopup>
              </div>
              }
              { user && !user.isAnonymous && jobDetails?.results?.data && 
              <div className="col-auto align-baseline">
                <button className="btn btn-outline-primary" title="Download CSV" onClick={downloadFile}><Download/></button>
              </div>
              }
              { user && user.isAnonymous && jobDetails?.results?.data && job_id &&
              <div className="col-auto align-baseline">
                <Link to={"/register/?url=/analysis/"+analysis_key+"/results/"+job_id} className="btn btn-outline-primary" disabled><Download/></Link>
              </div>
              }
            </>
            }
          </div>

          <div>
            {
            (!jobDetails?.logs || jobDetails.status=="Processing") ?
              // if loading display the progress bar
                <div className="pt-2">
                  {

                  }
                  <div className="progress" style={{"height":"8px"}}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{"width": "75%"}}></div>
                  </div>
                </div>
            :
              // display the top menu
              <ul className="nav nav-tabs page-header-tabs jobDetailsMenu" style={{"flexWrap":"nowrap", "overflowX":"scroll", "overflowY":"hidden"}}>
                

                {
                  reportParts.length>0 && reportParts?.map( (reportPart,i)=> {

                    return(
                      <li className="nav-item" key={"menu_"+i}>
                        <span style={{"whiteSpace": "nowrap"}}><a className="nav-link jobDetailsMenuNavLink" style={{"padding":"0.40rem","marginBottom":"1rem"}} href={"#"+reportPart['id']}>
                          {reportPart['name']}
                        </a></span>
                      </li>
                    )
                  })
                }


                <li className="nav-item">
                  <span style={{"whiteSpace": "nowrap"}}><a className="nav-link jobDetailsMenuNavLink" style={{"padding":"0.40rem","marginBottom":"1rem"}} href="#log">
                    Log
                  </a></span>
                </li>
              </ul>
            }
          </div>
        </div>
        <div className="job-results">
        <div className="container-fluid">

          <div className="row">

            <TourProvider
              steps={steps}
              onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
                
                // set for other actions
                setTourCurrentStep(currentStep)

                if (steps) {
                  if (currentStep === steps.length - 1) {
                    setIsOpen(false)
                  }
                  setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                }
              }}
              scrollSmooth
            >

            {jobDetails && jobDetails?.inputs?.report_type == "brief-draft" && jobDetails?.status != "Complete" &&
              <div className="container-fluid content">
                <h1>Brief / Draft Preview... <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /></h1>
                <div className="p-4 p-sm-10" style={{
                    "minHeight": "20vh",
                    "overflow": "auto",
                    "backgroundColor": "antiquewhite"}}>
                    {
                          !loadingSocket && <WebSocketCall_results socket={socketInstance} room={"room_job_"+job_id} />} <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                </div>
              </div>
            }

            { 
              // if there is a template then use that!
              // otherwise use the default report by part
              // if no parts, then show all as json-raw
              dipslayJobResults( jobDetails, templateVersion, user, tourCurrentStep )
            }



            <div className="content" style={{"minHeight":"80vh"}}>
              <h1 id="log" className="pt-4">Log</h1>

              <div className="line">
                {jobDetails?.logs && jobDetails?.logs.length && <>
                  {jobDetails.logs?.map((message, index) => {
                    return <div key={"log_"+message?.created}>{message.value? message.value : <>{JSON.stringify(message, null, 0)}</>}</div>;
                  })}
                </>}
                  {
                  !loadingSocket && <WebSocketCall socket={socketInstance} room={"room_job_"+job_id} />}
              </div>
              {(!jobDetails?.logs || jobDetails.status=="Processing") &&
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  
                </>
              }
              {!jobDetails?.results &&
              <p className="mt-5">
                Click REFRESH if page doesn't auto refresh:)
              </p>
              }
            </div>
            </TourProvider>
          </div>
        </div>
        </div>
      </Layout>
    </>
  );
}

export default AnalysisDetails;

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  linkUserWithEmailAndPassword,
  signInWithGoogle,
  linkUserWithGoogle,
  logout
} from "../../pages/authentication/firebase";
import { Helmet } from "react-helmet"

function FreeTrial() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
  
  const register = (user) => {
    if (!name){
      alert("Please enter name");
    } else {
      if( !user ){
        // regular registration
        registerWithEmailAndPassword(name, email, password);

        // todo send an email for verificaiton
      } else {
        // link new registration
        linkUserWithEmailAndPassword(name, email, password).then( (response) => {


          // todo send an email for verificaiton
          

          console.log(response);
          if (JSON.stringify(response).includes("email-already-in-use")){
            setErrorMessage("Email address already used.")
            
            // todo check credential
            // if it is good then add to user_entity so that if they login then link the old account
          } else {
            if( searchParams.get('url') ){
              navigate(searchParams.get('url'))
            } else {
              console.log( "go home" )
              navigate("/")
            }
          }
        });
      }
    }
    
  };


  const registerGoogle = (user) => {
    
    if( user == undefined || user == null ){
      // regular registration ( or login if already exists )
      signInWithGoogle(name, email, password);
    } else {
      // link new registration
      linkUserWithGoogle(user).then( (response) => {
        console.log(response);
        if (JSON.stringify(response).includes("credential-already-in-use")){
          setErrorMessage("Google account already used.")

          // todo check credential
          // if it is good then add to user_entity so that if they login then link the old account
        } else {
          navigate("/")
        }
      });
    }
  };
  useEffect(() => {
    if (loading) return;
    
    if (user && !user.isAnonymous) {
      // get redirect url
      if( searchParams.get('url') ){
        navigate(searchParams.get('url'))
      } else {
        console.log( "go home" )
        navigate("/")
      }
    };
  }, [user, loading]);
  
  var redirectUrl = ""
  if(searchParams.get('url') ){
    redirectUrl = "&url="+encodeURIComponent(searchParams.get('url'))
  }


  var style_icon = {
    "marginBottom": "45px",
    "height": "30px"
  }

  var pageTitle = "Free Trial"

  return (

    <>
    <Helmet>
      <title>{pageTitle? pageTitle+" - Content Curator App" : "Content Curator App"}</title>
    </Helmet>
    <div className="content container-fluid" style={{"overflowY":"auto", "padding":"0px"}}>




<main id="content" role="main" className="main pt-0">
    <div className="container-fluid px-3">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
          <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
            <div className="d-none d-lg-flex justify-content-between">
              <a href={public_url}>
                <img src="/images/contentcurator.svg" style={style_icon}></img>
              </a>
            </div>
          </div>

          <div style={{"maxWidth":"24rem"}}>

            <div className="mb-5">
              <h2 className="display-5">Curate better digital experiences:</h2>
            </div>

            <ul className="list-checked list-checked-lg list-checked-primary list-py-2">
              <li className="list-checked-item">
                <span className="d-block fw-semi-bold mb-1">Access Advanced Tools</span>
                Get 5 Jobs per month, FREE
              </li>

              <li className="list-checked-item">
                <span className="d-block fw-semi-bold mb-1">Save Your Progress</span>
                Review and compare reports you have already run.
              </li>
              <li className="list-checked-item">
                <span className="d-block fw-semi-bold mb-1">Pay-As-You-Go Pricing</span>
                Upgrade for more benefits and reduced pricing
              </li>
            </ul>

          </div>
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
          <div className="w-100 content-space-t-0 content-space-b-0" style={{"maxWidth":"24rem"}}>


          <div>
          <div className="card-body">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-5">Start a Free Trial</h1>
                  <p>Already have an account? <Link className="link" to={"/login/?restart=true"+redirectUrl}>Login</Link></p>
                </div>
          { errorMessage && <>
            <div className="card card-lg mb-5 bg-soft-danger">
              <div className="card-body">
                <div className="text-danger">{errorMessage} Please <Link to={"/login/?restart=true"+redirectUrl}>Login</Link> or <Link to="/reset/">Reset Password</Link></div> 
              </div>
            </div>
          </>}

                <div className="d-grid mb-4">
                  <a className="btn btn-outline-primary btn-lg" onClick={() => registerGoogle(user)}>
                    <span className="d-flex justify-content-center align-items-center">
                      <img className="avatar avatar-xss me-2" src="/assets/svg/brands/google-icon.svg" alt="Image Description"/>
                      Sign up with Google
                    </span>
                  </a>
                </div>

                <span className="divider-center text-muted mb-4">OR</span>
              </div>

              <label className="form-label" htmlFor="fullNameSrEmail">Full name</label>

              <div className="row">
                <div className="mb-4">
                  <input type="text" required className="form-control form-control-lg" name="fullName" id="fullNameSrEmail" placeholder="Mark" aria-label="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}/>
                  <span className="invalid-feedback">Please enter your first name.</span>
                </div>

              </div>

              <div className="mb-4">
                <label className="form-label" htmlFor="signupSrEmail">Your email</label>
                <input type="email" className="form-control form-control-lg" name="email" id="signupSrEmail" placeholder="Markwilliams@site.com" aria-label="Markwilliams@site.com" required=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}/>
                <span className="invalid-feedback">Please enter a valid email address.</span>
              </div>

              <div className="mb-4">
                <label className="form-label" htmlFor="signupSrPassword">Password</label>

                <div className="input-group input-group-merge" data-hs-validation-validate-className="">
                  <input type="password" className="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required="" minLength="8" data-hs-toggle-password-options="{
                           &quot;target&quot;: [&quot;.js-toggle-password-target-1&quot;, &quot;.js-toggle-password-target-2&quot;],
                           &quot;defaultClass&quot;: &quot;bi-eye-slash&quot;,
                           &quot;showClass&quot;: &quot;bi-eye&quot;,
                           &quot;classChangeTarget&quot;: &quot;.js-toggle-password-show-icon-1&quot;
                         }"
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}/>
                  <a className="js-toggle-password-target-1 input-group-append input-group-text" href="javascript:;">
                    <i className="js-toggle-password-show-icon-1 bi-eye-slash"></i>
                  </a>
                </div>

                <span className="invalid-feedback">Your password is invalid. Please try again.</span>
              </div>

              <div className="form-check mb-4">
                <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" required=""/>
                <label className="form-check-label" htmlFor="termsCheckbox">
                  I accept the <Link to="/terms">Terms of Service</Link>
                </label>
                <span className="invalid-feedback">Please accept our Terms of Service.</span>
              </div>

              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary btn-lg" onClick={register}>Create an account</button>

              </div>
                  { errorMessage? <div className="mt-3 text-danger text-center">{errorMessage} Please <Link className="text-danger"to={"/login?restart=true"+redirectUrl} style={{"fontWeight":"bold", "textDecoration":"underline"}}>login here</Link>.</div> : ""}
              </div>


              </div>
                </div>
                </div>
            </div>
        </div>
        </main>
        </div>
    </>
  );
}

export default FreeTrial;

import React from 'react';
import Modal from "react-bootstrap/Modal";
import "./TermsPopup.css";
import axios from 'axios'
import { BiShareAlt } from "react-icons/bi";

const SharePopup =({user, job_id, inline, analysis_key}) =>{

    const [isOpen, setIsOpen] = React.useState(false);

    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
    const app_url = process.env.REACT_APP_URL || "http://127.0.0.1:3000";
    
    const hideShareModal = () => {
      setIsOpen(false);
    };
    const showShareModal = () => {
      // notify backend that it is now sharable
      if( user ){
        axios.put( api_url+'/v1/job/'+job_id, {'isPublic':true}, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}});
      }
      setIsOpen(true);
    };

    var btnClass = "btn btn-outline-primary"
    if(inline){
      btnClass = "btn btn-sm btn-ghost-primary"
    }

  
    return(
        <>
          <button className={btnClass} onClick={showShareModal} title="Share these results"><BiShareAlt></BiShareAlt></button>
          <Modal size='lg' show={isOpen} onHide={hideShareModal} centered={true}>
            <Modal.Body>
                <div className="text-center mb-5">
                  <h4 className="h1">Share this Report</h4>
                </div>

                <div className="row">
                  <div className="form-group">
                    <label htmlFor="shareableLink">Shareable link:</label>
                    <input readOnly type="input" className="form-control mb-4" id="shareableLink" value={app_url+"/analysis/"+analysis_key+"/results/"+job_id}/>
                    <small id="info" className="form-text text-muted">Share this link with anyone so they can view the report without an account.</small>
                  </div>
                </div>

            </Modal.Body>
          </Modal>
        </>
    )
}

export default SharePopup;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams, Link } from "react-router-dom";


import AnalysisPreview from "./AnalysisPreview";
import AnalysisDetails from "../../pages/jobs/AnalysisDetails";

function Analysis() {

    const navigate = useNavigate();
    let { analysis_key, action, job_id } = useParams();

    if( action=='preview' && job_id ){
        return(<AnalysisPreview analysis_key={analysis_key} job_id={parseInt(job_id)}/>)
    } else if ( job_id ){
        return(<AnalysisDetails analysis_key={analysis_key} job_id={parseInt(job_id)}/>)
    } else {
        return (
            <>
                <p>Lookup:
                {analysis_key}
                </p>

                <p>
                    List all actions taken
                    {action} {job_id}
                </p>
            </>
        )
    }
}

export default Analysis;
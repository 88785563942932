import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout} from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet"

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  // login
  const doLogin = () => {
    logInWithEmailAndPassword(email, password).then((res) => {
      // if error 
      if("errorCode" in res){

        setErrorMessage("ERROR: "+res["errorCode"])
      } else {
      
        // good login
        if( searchParams.get('url') ){
          // go to url pre-defined
          navigate(searchParams.get('url'))
        } else {
          navigate("/")
        }

      }
    })
  }
  // to remove the specific key
  const restartInUrl = () => {
    const url = window.location.href
    var r = new URL(url)
    return r.searchParams.get("restart")  == 'true'
  }
  const removeUrlParameter = (paramKey) => {
    const url = window.location.href
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    window.history.pushState({ path: newUrl }, '', newUrl)
  }
  useEffect(() => {

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      // console.log(user)
      if (restartInUrl()){
        removeUrlParameter('restart')
        logout()
      } else {
        if( searchParams.get('url') ){
          // go to url pre-defined
          navigate(searchParams.get('url'))
        } else {
          navigate("/")
        }
      };
    }
  }, [user, loading]);

  var redirectUrl = ""
  if(searchParams.get('url') ){
    redirectUrl = "&url="+encodeURIComponent(searchParams.get('url'))
  }

  var pageTitle = "Login"

  return (
    <>
    <Helmet>
      <title>{pageTitle? pageTitle+" - Content Curator App" : "Content Curator App"}</title>
    </Helmet>
    <div className="content container-fluid" style={{"overflowY":"auto"}}>
      <main id="content" role="main" className="main">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start login-bg">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>

        <div className="container py-5 py-sm-7 pb-0 pb-lg-0">
          <a className="d-flex justify-content-center mb-5" href={public_url}>
            <img className="zi-2 navbar-brand-logo" src="/images/contentcurator.svg"></img>
          </a>
          <div className="mx-auto login-module">
            <div className="card card-lg mb-0">
              <div className="card-body">
                  <div className="text-center">
                    { errorMessage && <>
                      <div className="card card-lg mb-5 bg-soft-danger">
                        <div className="card-body">
                          <div className="text-danger">{errorMessage}</div> 
                        </div>
                      </div>
                    </>}
                    { error && <>
                      <div className="card card-lg mb-5 bg-soft-danger">
                        <div className="card-body">
                          <div className="text-danger">{error}</div> 
                        </div>
                      </div>
                    </>}
                    { successMessage && <>
                      <div className="card card-lg mb-5 bg-soft-success">
                        <div className="card-body">
                          <div className="text-success">{successMessage}</div> 
                        </div>
                      </div>
                    </>}
                    <div className="mb-5">
                      <h1 className="display-5">Sign in</h1>
                    </div>

                    <div className="d-grid mb-4">
                      <button className="btn btn-outline-primary btn-lg" onClick={signInWithGoogle}>
                        <span className="d-flex justify-content-center align-items-center">
                          <img className="avatar avatar-xss me-2" src="/assets/svg/brands/google-icon.svg" alt=""/>
                          Sign in with Google
                        </span>
                      </button>
                    </div>

                    <span className="divider-center text-muted mb-4">OR</span>
                  </div>

                  <div className="mb-4">
                    <label className="form-label" htmlFor="signinSrEmail">Your email</label>
                    <input type="email" className="form-control form-control-lg" name="email" 
                    id="signinSrEmail" tabindex="1" placeholder="email@address.com" aria-label="email@address.com" required
                    value={email}
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}/>
                    <span className="invalid-feedback">Please enter a valid email address.</span>
                  </div>

                  <div className="mb-4">
                    <label className="form-label w-100" htmlFor="signupSrPassword" tabindex="0">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>Password</span>
                        <Link className="form-label-link mb-0" to="/reset">Forgot Password?</Link>
                      </span>
                    </label>

                    <div className="input-group input-group-merge" data-hs-validation-validate-class>
                      <input type="password" className="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required minLength="8" data-hs-toggle-password-options='{
                              "target": "#changePassTarget",
                              "defaultClass": "bi-eye-slash",
                              "showClass": "bi-eye",
                              "classChangeTarget": "#changePassIcon"
                            }'
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                      <a id="changePassTarget" className="input-group-append input-group-text" href="javascript:;">
                        <i id="changePassIcon" className="bi-eye"></i>
                      </a>
                    </div>

                    <span className="invalid-feedback">Please enter a valid password.</span>
                  </div>
                  
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg" onClick={() => doLogin()}>Sign in</button>
                      
                  </div>
                  <div className="d-grid mt-3 text-center">
                  <p>Don't have an account yet? <Link className="link" to="/register">Sign up here</Link></p>
                  </div>
                  { errorMessage && <>
                        <div className="text-danger text-center">{errorMessage} <br/>Please <Link to={"/register/?restart=true"+redirectUrl}>Register</Link> or <Link to="/reset/">Reset Password</Link></div> 
                  </>}
              </div>
            </div>
          </div>
        </div>



      </main>
    </div>
    </>
  );
}

export default Login;

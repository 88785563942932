import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    plugins: {
        legend: {
            display: false
        },
    },
    scales:{
        x: {
            display: false
        },
        y: {
            display: false
        }
    }
  };
  
  





function SummaryChart({counts, labels}) {

    //const labels = ['January', 'February','x','y','z'];
    // const counts = [1,2,3,4,5];
    const data = {
        labels,
        datasets: [
          {
            label: 'Domains',
            data: counts,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

  return (
    <>
        <Bar options={options} data={data} />
    </>
  );
}

export default SummaryChart;
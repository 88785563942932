import { useEffect, useState } from "react";

export default function WebSocketCall({ socket }) {
  // https://medium.com/@adrianhuber17/how-to-build-a-simple-real-time-application-using-flask-react-and-socket-io-7ec2ce2da977
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // process log messages
    socket.on("log", (data) => {
      setMessages([...messages, data]);
    });
  }, [socket, messages]);


  return (
    <>
      <div>
        {messages.map((data) => {
          return <div key={data.created}>{data?.value}</div>;
        })}
      </div>
    </>
  );
}
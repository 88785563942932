
import React, { useEffect, useState } from "react";

import styled from 'styled-components';
import ResultsPageOverview from "./ResultsPageOverview";
import { GraphUpArrow, BoxArrowUpRight, CheckSquare, ExclamationSquareFill, Square } from 'react-bootstrap-icons';

import ResultsPageSegments from "./ResultsPageSegments";
import ResultsPageKeyphrases from "./ResultsPageKeyphrases";
import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

const ReportRowItem = styled.div`
    margin-top:4px;
    float:left;
    width: 125px;
    margin-right: -125px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1em;
`;
const ReportRowDetails = styled.div`
    margin-left:125px;
    overflow:hidden;
`;
const ReportRow = styled.div`
    margin-bottom:10px;
    min-height: 20px;
`;


class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a> <a className="btn btn-outline-secondary" href={"/pricing/"}>See Pricing!</a> 
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayPageInspectionOriginalityV1({jobDetails, reportParts}) {


    const Subsection = ({subsection, index}) => {
        return (
            <>
                    <div className="col-lg-12 p-2 pt-2 pb-2 hover-highlight" id={"segment-"+(index+1)+"-"+index}>
                        <div className="">
                                <div>{subsection?.keyphrases && subsection?.keyphrases.map((kw,index)=>{return kw?.key_phrase+" "})}</div>
                        </div>
                    </div>
            </>
        )
    }


    const renderTooltip = ({props, text}) => (
        <Tooltip {...props}>{text}</Tooltip>
    );

    {console.log(jobDetails)}
  return (
    <>
      <div className="reportPart content" id="overview">
        {jobDetails?.results && 
            <>
            
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="row">

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                        <div className="card card-hover-shadow h-100">
                        <div className="card-body">
                            <h6 className="mb-3 card-subtitle">Total URL Originality Score</h6>


                                {jobDetails?.results?.score?.originalityai?.original && jobDetails?.results?.score?.originalityai?.original<=.5 &&
                                    <>
                                            
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span><Square/> {Math.round(jobDetails?.results?.score?.originalityai?.original*100)}% Original</span></h2>
                                            </div>
                                        </div>
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span className="text-danger"><ExclamationSquareFill/> {100-Math.round(jobDetails?.results?.score?.originalityai?.original*100)}% AI</span></h2>
                                            </div>

                                        </div>
                                        
                                    </>
                                }
                                {jobDetails?.results?.score?.originalityai?.original && jobDetails?.results?.score?.originalityai?.original>.5 &&
                                    <>
                                            
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span className="text-success"><CheckSquare/> {Math.round(jobDetails?.results?.score?.originalityai?.original*100)}% Original</span></h2>
                                            </div>
                                        </div>
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span><Square/> {100-Math.round(jobDetails?.results?.score?.originalityai?.original*100)}% AI</span></h2>
                                            </div>

                                        </div>
                                        
                                    </>
                                }

                            <span className="text-body fs-6 ms-1">Scores provided by <a href="https://originality.ai?lmref=XSGc6g&utm_source=contentcurator" target="_blank">originality.ai</a></span>
                        </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-9 mb-3 mb-lg-5">
                        
                        <div className="card h-100">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">
                                Originality Summary <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({"text":"An overview of the content found on this URL"})}
                                >
                                <span>< BiHelpCircle></BiHelpCircle></span>
                                </OverlayTrigger>
                                
                                <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                            </div>

                            <div className="card-body">
                                
                                <ReportRow>
                                    <ReportRowItem>Segments Tested:</ReportRowItem>
                                    <ReportRowDetails>{jobDetails?.results?.score?.originalityai?.segments_tested}</ReportRowDetails>
                                </ReportRow>
                                <ReportRow>
                                    <ReportRowItem>Max Originality:</ReportRowItem>
                                    <ReportRowDetails>{Math.round(jobDetails?.results?.score?.originalityai?.original_max*100)}%</ReportRowDetails>
                                </ReportRow>
                                <ReportRow>
                                    <ReportRowItem>Min Originality:</ReportRowItem>
                                    <ReportRowDetails>{Math.round(jobDetails?.results?.score?.originalityai?.original_min*100)}%</ReportRowDetails>
                                </ReportRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        }


        {jobDetails['results'] && jobDetails['results']['segments'] &&
            <>
            <h1 id="segments" className="pt-4">Orginality by Segment</h1>
            <div>
                {jobDetails?.results?.segments && jobDetails?.results?.segments.map((message, index) => {
                    return <div className="row" key={"schema_"+index.toString()}>
                            <div className="col-lg-12 mb-3 mb-lg-5" id={"segment-"+(index+1)}>
                                <div className="card h-100">
                                    {message?.score?.originalityai?.original && message?.score?.originalityai?.original<=.5 &&
                                    <div className="card-header card-header-content-between" style={{"backgroundColor":"#f8d7da"}}>
                                        <h4 className="card-header-title"><span className="text-danger"><ExclamationSquareFill/> {Math.round(message?.score?.originalityai?.original*100)}% Original</span></h4>
                                    </div>
                                    }
                                    {message?.score?.originalityai?.original && message?.score?.originalityai?.original>.5 &&
                                    <div className="card-header card-header-content-between" style={{"backgroundColor":"#d4edda"}}>
                                        <h4 className="card-header-title">{Math.round(message?.score?.originalityai?.original*100)}% Original</h4>
                                    </div>
                                    }
                                    {!message?.score?.originalityai?.original && 
                                    <div className="card-header card-header-content-between" style={{"backgroundColor":"#e2e3e5"}}>
                                        <h4 className="card-header-title">Originality Not Tested</h4>
                                    </div>
                                    }
                                    <div className="card-body">
                                            <>
                                                <h4>Segment Summary</h4> 
                                                {message?.title?.text_summarized &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Keywords:</ReportRowItem>
                                                        <ReportRowDetails>{message?.title?.text_summarized}</ReportRowDetails>
                                                    </ReportRow>
                                                    <ReportRow>
                                                        <ReportRowItem>Header:</ReportRowItem>
                                                        <ReportRowDetails>&lt;{message.title?.markup.toUpperCase()}&gt;</ReportRowDetails>
                                                    </ReportRow>
                                                    <ReportRow>
                                                        <ReportRowItem>Title:</ReportRowItem>
                                                        <ReportRowDetails>{message.title?.text.toUpperCase()}</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                {message?.summary &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Images:</ReportRowItem>
                                                        <ReportRowDetails>{message?.summary?.imgs}</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                {message?.summary &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Links:</ReportRowItem>
                                                        <ReportRowDetails>{message?.summary?.links}</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                {message?.summary &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Words:</ReportRowItem>
                                                        <ReportRowDetails>{message?.summary?.words}</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                {message?.score?.originalityai?.original &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Originality Score:</ReportRowItem>
                                                        <ReportRowDetails>{Math.round(message?.score?.originalityai?.original*100)}%</ReportRowDetails>
                                                    </ReportRow>
                                                    <ReportRow>
                                                        <ReportRowItem>AI Score:</ReportRowItem>
                                                        <ReportRowDetails>{100-Math.round(message?.score?.originalityai?.original*100)}%</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                {message?.score?.originalityai?.error &&
                                                <>
                                                    <ReportRow>
                                                        <ReportRowItem>Originality Error:</ReportRowItem>
                                                        <ReportRowDetails>{message?.score?.originalityai?.error}</ReportRowDetails>
                                                    </ReportRow>
                                                </>
                                                }
                                                &nbsp;<br/> 
                                            </>

                                        { message?.score?.originalityai?.text && 
                                        <>
                                            <h4>Originality Text Tested</h4>
                                            <div>
                                                {message?.score?.originalityai?.text}
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>;
                })}
            </div>
            
            </>
        }
      </div>
    </>
  );
}

export default DisplayPageInspectionOriginalityV1;
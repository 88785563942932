
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'

import { Link } from "react-router-dom";

import Safe from 'react-safe';
import { Helmet } from "react-helmet"
import styled from 'styled-components';
import { GraphUpArrow, BoxArrowUpRight } from 'react-bootstrap-icons';

import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SummaryChart from "./summary-chart";


var Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-link" href={"/pricing/"}>pricing</a>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayBriefDraftV1({jobDetails, reportParts, isRedacted, user, downloadFile}) {

  
  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  const renderTooltip = ({props, text}) => (
    <Tooltip {...props}>{text}</Tooltip>
  );


  return (
    <>
        




        {
            reportParts.length>0 && reportParts.map( (reportPart,i)=> {

              const resultsByDisplayType = ({reportPart,results}) => {
                // determine how to display it
                
                if( reportPart.displayTemplate=='raw-results' ){
                  // display everything in json
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}} key={"row_"+i}><pre style={{"overflow":"unset"}}>{JSON.stringify(results, null, 2) }</pre></div> )
                
                } else if( reportPart.id=='overview' ){

                    // all keywords starting with the topic
                    var page = jobDetails?.results?.page || {}
                    return(
                        <>
                            <div className="row">
                                <p>Inputs Target Query: <b>{page?.primary_keyword}</b></p>
                            </div>
                        </>
                    )
                } else if( reportPart.id=='brief' ){

                    // all keywords starting with the topic
                    console.log(jobDetails)
                    var page = jobDetails?.results?.page || {}
                    var brief = jobDetails?.results?.brief || {}
                    return(
                        <>
                            <div className="row">
                                <p>Title: <b>{page?.title}</b></p>
                                {page?.subtitle && 
                                    <p>Subtitle: <b>{page?.subtitle}</b></p>
                                }
                                <p>Wordcount: <b>{page?.words}</b></p>
                                <p>Primary Topic: 
                                    <ul>
                                        <li>{brief?.primary_topic}</li>
                                    </ul>
                                </p>
                                <p>Target Keywords: 
                                </p>
                                <p>
                                    <ul>
                                { brief?.keyphrases && brief?.keyphrases?.map((kw, i) => {
                                    return( <li>{kw}</li> )
                                })}
                                    </ul>
                                </p>
                                <p>Intent: 
                                    <ul>
                                        <li><b>{page?.intent}</b></li>
                                        <li>{brief?.search_intent}</li>
                                    
                                    </ul>
                                </p>

                                { brief?.target_audience && typeof(brief.target_audience) == 'array' &&
                                <><p>Target Audience:</p>
                                <p>
                                    <ul>
                                        { brief?.target_audience && brief?.target_audience?.map((target_audience, i) => {
                                            return( <li>{target_audience}</li> )
                                        })}
                                    </ul>
                                </p></>
                                }
                                { brief?.target_audience && typeof(brief.target_audience) == 'string' &&
                                <><p>Target Audience:</p>
                                <p>
                                    <ul>
                                        <li>{brief?.target_audience}</li> 
                                    </ul>
                                </p></>
                                }
                            </div>
                            <div className="row">
                                <p>Article Outline:</p>
                                  { brief?.article_outline && ( typeof(brief.article_outline) == 'array' ) ?
                                    <p><ul>
                                        {  brief?.article_outline?.map((item, i) => {
                                            return( 
                                            <li className="mb-3">
                                              <b>{item?.title}</b>
                                              <p className="mb-3">{item?.summary}</p>
                                              <ul>
                                                  { item?.subsections && item?.subsections?.map((item2, i) => {
                                                      return( <li className="mb-3"><b>{item2?.title}</b><br/>{item2?.summary}</li> )
                                                  })}
                                              </ul>
                                            </li> )
                                        })}
                                    </ul></p>
                                    :
                                    // not an array
                                    <pre>
                                      { console.log("outline", typeof(brief.article_outline)) }
                                      { <pre>{JSON.stringify(brief?.article_outline, null, 2)}</pre> }
                                    </pre>
                                  }
                            </div>
                        </>
                    )
                
                } else if( reportPart.id=='draft' ){

                    var md_parts = jobDetails?.results?.draft?.md_parts || []
                    return(
                        <div className="p-3 p-md-8" style={{
                            "overflow": "auto",
                            "padding": "20px",
                            "backgroundColor": "aliceblue"}}>
                            { md_parts?.map((part,i) =>{
                                return( <ReactMarkdown>{part}</ReactMarkdown>) })
                            }
                        </div>
                    )
                
                } else if( reportPart.style=='data' || (!('displayTemplate' in reportPart) && reportPart.id=='data' ) ){
                  // simple data table
                  return(
                    <>
                      { results?.data &&
                      <>
                      <div style={{"position":"relative", "minHeight":"600px"}} key={"row_"+i}>
                        <div style={{"zIndex":-100}}>
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"All keywords generated"})}
                                  >
                                    <span>Keyword < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {results.data.map((link, index) => {
                                return <tr key={"link_"+index+1}>
                                  <td style={{"minWidth":"140px","maxWidth":"180px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                      <Link to={"/tools/keyword-generator/?topic="+encodeURIComponent(link?.keyword)}><GraphUpArrow /> {link?.keyword}</Link>
                                  </td>
                                  <td style={{"minWidth":"140px","maxWidth":"180px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                      <Link to={"/tools/keyword-generator/?topic="+encodeURIComponent(link?.keyword)+"&hl="+jobDetails?.inputs?.hl}>
                                        <span className="badge bg-soft-warning text-dark text-uppercase m-1">+ More</span>
                                      </Link>
                                      <Link to={"/tools/brief-draft/?topic="+encodeURIComponent(link?.keyword)+"&hl="+jobDetails?.inputs?.hl}>
                                        <span className="badge bg-soft-warning text-dark text-uppercase m-1">+ Brief Draft</span>
                                      </Link>
                                  </td>
                                  

                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      {isRedacted && 
                        <PaywallOverlay></PaywallOverlay>
                      }
                      </div>
                      
                      <span style={{"fontSize":"larger"}}>
                      TOTAL: <b>{results?.summary?.total} Keywords</b> 
                      </span>
                      { true && 
                        <>
                          &nbsp; ( <a href={"/register/"}>register for the full list</a> )
                        </>
                      }
                      </>
                    }
                    </>
                  )

                }
                
                // todo uses the id that must match!!!
                if( reportPart.id in results ){
                  // return json display of just this section
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}}><pre style={{"overflow":"unset"}}>{JSON.stringify(results[reportPart.id], null, 2) }</pre></div> )
                }
                
              }
              return(
                <>
                  <div className="reportPart content col-sm"  id={reportPart['id']}>
                    <h1>{reportPart['name']}</h1>
                    { resultsByDisplayType({"reportPart":reportPart,"results":jobDetails?.results}) }
                  </div>
                </>
                )
              })
          }


    </>
  );
}

export default DisplayBriefDraftV1;
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Layout from '../../components/Layout';
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../authentication/firebase";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { InfoCircle } from 'react-bootstrap-icons';

import Moment from 'moment';


const AccountBilling = () => {

  // get the user
  const [user, loading, error] = useAuthState(auth);
  const [userEntity, setUserEntity] = useState({})
  const [currentSubscriptionLevel, setCurrentSubscriptionLevel] = useState("")
  const [billingInfo, setBillingInfo] = useState(null);
  const navigate = useNavigate();
  
  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";



  // subscription levels
  const subscriptionOptions = [
    {
      'name': 'guest',
      'title': 'Guest Access',
      'discountOnCredits': 0,
      'monthlyPrice': 0,
      'benefits': []
    },
    {
      'name': 'free',
      'title': "Pay as you Go Plan",
      'discountOnCredits': 0,
      'monthlyPrice': 0,
      'prepaid_reports_included_per_month': 5,
      'benefits': [
        'Simple pay as you go pricing',
        'The first 5 reports per month are included'
      ]
    },
    {
      'name': 'basic_starter',
      'title': "Basic Plan",
      'discountOnCredits': 0,
      'monthlyPrice': 29,
      'prepaid_reports_included_per_month': 50,
      'benefits': [
        'Save 30% on credits',
        'The first 30 reports per month are included'
      ]
    },
    {
      'name': 'pro_starter',
      'title': "Profesional Plan",
      'discountOnCredits': 0.5,
      'monthlyPrice': 49,
      'prepaid_reports_included_per_month': 100,
      'benefits': [
        'Save 62.4% on credits',
        'The first 100 reports per month are included',
        'Collaboration tools'
      ]
    },
    {
      'name': 'lite_starter',
      'title': "Lite Plan",
      'discountOnCredits': 0,
      'monthlyPrice': 9,
      'prepaid_reports_included_per_month': 10,
      'benefits': [
        'The first 10 reports per month are included'
      ]
    },
    {
      'name': 'team_starter',
      'title': "Business Plan",
      'discountOnCredits': 0.624,
      'monthlyPrice': 299,
      'prepaid_reports_included_per_month': 600,
    },
  ]
  var subscriptionOptionsDict = {};
  for( const x of subscriptionOptions){
    subscriptionOptionsDict[x['name']] = x
  }


  useEffect(() => {
    if (loading) return;


    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

    if (!user) {
      // go home if not a user
      navigate("/");
    } else {

      // get billing information
      axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)


        // get the billing info for this customer
        if ( billingInfo==null ){  
          // get or create customer in stripe and save to existing user_entity in datastore
          axios.post(
            api_url+'/v1/user/stripe/status', 
            {
              'userEntity':response.data.user_entity
            }, { headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken} }
          ).then((response)=> {
            setBillingInfo(response.data)
          })
        }

      }).catch(error => {
        console.log(error)
      });


      

    }
  }, [user, loading]);

  useEffect(() => {
    if(loading) return;
    

    // set current membership plan using subscription membership_id
    var membership_id = userEntity?.access?.subscription?.membership_id
    if( membership_id ){
      setCurrentSubscriptionLevel(membership_id)
    } else {
      setCurrentSubscriptionLevel('free')
    }

  }, [userEntity, loading]);

  var titleStyle = {
    "marginBottom": "0px",
    "marginTop": "0px"
  }

  var planDetailsStyle = {
    "marginBottom": "20px",
  }

  // console.log("userEntity",userEntity)

  // console.log("billing", billingInfo)
  return (
    <>



      <Layout pageTitle={"Billing"} fullScreen={true} hideTermsAccepted={true}>
        <div className="content container-fluid" style={{"overflowY":"auto"}}>

        <div className="row justify-content-lg-center">
          <div className="col-lg-9 ">

          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">

                <h1 className="page-header-title">Billing</h1>
              </div>

            </div>
          </div>

          { user && user?.isAnonymous &&
                    <div className="alert alert-soft-warning" role="alert">
                      <p className="" style={{"color":"black"}}><b>HI GUEST!</b> Earn 5 MORE free jobs per month when you <Link to="/register/">Register</Link>. See <Link to="/pricing/">pricing</Link> for more options.
                      </p>
                    </div>
                  }

          { userEntity.access && ( userEntity.access?.credits?.available_anytime + userEntity.access?.credits?.available_subscription ) == 0 &&
                  <div className="alert alert-soft-secondary mt-3 mb-5 mb-lg-7" role="alert">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Image Description" data-hs-theme-appearance="default"/>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h3 className="alert-heading mb-3">Attention! You are out of jobs</h3>
                        <ul className="list-checked list-checked-primary">
                          <li className="list-checked-item">Simple pay as you go reporting starting at 300 credits per job</li>
                          <li className="list-checked-item">Consider <Link to={"/pricing/"}>upgrading</Link> your membership plan </li>
                          <li className="list-checked-item">You will receive more jobs when your subscription renews</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                }
          </div>
          </div>

          <div className="row justify-content-lg-center">
            <div className="col-lg-9 mb-5 mb-lg-7">
              

              <div className="d-grid gap-3 gap-lg-5">



              <div className="card">
                  <div className="card-header card-header-content-between border-bottom">
                    <h2 className="card-header-title">Active Subscription</h2>
                    <div className="col-md-auto">
                      <div className="d-grid d-sm-flex gap-3">
                        <Link to={"/pricing/"}><button type="button" className="btn btn-link w-100 w-sm-auto">Change Subscription</button></Link>
                      </div>
                    </div>
                  </div>
                  

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md mb-4 mb-md-0">



                        <div className="mb-4">
                          { !billingInfo && loading == false && user.isAnonymous && 
                            <>
                              <h2>{subscriptionOptionsDict[currentSubscriptionLevel]?.title}</h2>
                            </>
                          }
                          { (loading || (user != null && !user.isAnonymous)) && billingInfo ==null &&
                            <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Loading...</>
                          }
                          { billingInfo !=null && billingInfo.subscriptions.data.length ==0 &&
                            <>
                              <h2>{subscriptionOptionsDict[currentSubscriptionLevel]?.title}</h2>
                              { currentSubscriptionLevel=="Guest Access" && <>You currently have limted acces and everything will be lost. <a href={"/pricing/"}>Upgrade</a> or <Link to="/register/">Register</Link> to save your progress.</>}

                              <ul className="list-checked list-checked-secondary">
                              {subscriptionOptionsDict[currentSubscriptionLevel]['benefits']?.map((benefit,i) =>{
                                return (
                                  <li className="list-checked-item">{benefit}</li>
                                )})
                              }
                              { currentSubscriptionLevel == 'free' && 
                              <li className="list-checked-item">Choose to upgrade when you run your next tool ( <Link to="/tools/">see all tools</Link> )</li> }
                              </ul>
                            </>
                          }
                          
                          { billingInfo && billingInfo.subscriptions.data.map((sub,i)=>{
                              var numDate= new Date(sub.current_period_end*1000);
                              
                              var trialing = ""
                              var planStatus = ""
                              if(sub?.cancel_at_period_end){
                                planStatus = " - Canceled"
                              }
                              if(sub.status=="trialing"){
                                trialing = "(TRIAL) "
                              }
                              if(sub.status !='incomplete'){
                                return (
                                  <>
                                    <span key={"sub_"+i}>
                                      <h3>{trialing}{billingInfo.products[sub.plan.product].name} Plan{planStatus}
                                      </h3>

                                        <ul className="list-checked list-checked-secondary">

                                          {sub?.pending_setup_intent && 
                                            <li className="list-checked-item"><Link to={"/checkout/?step=payment&credits=0&plan="+sub?.metadata['membership_id']}>ADD PAYMENT METHOD</Link> to start the Free Trial</li>}
                                          {(sub.status=='trialing') && sub.collection_method=='charge_automatically' && 
                                            <li className="list-checked-item">{"Your paid subscription starts on "+numDate}</li>}
                                          {(sub.status=='active') && sub.collection_method=='charge_automatically' && !sub.cancel_at_period_end && 
                                            <li className="list-checked-item">Renews on <span title={numDate}>{numDate.toDateString()+""} <InfoCircle className="mb-1"/></span></li>}
                                          {sub?.cancel_at_period_end && 
                                            <li className="list-checked-item">{"Access available until "+numDate}</li>}
                                            <li className="list-checked-item">{"$"+sub.plan.amount/100+" per "+sub.plan.interval+""}</li>
                                            <li className="list-checked-item">{subscriptionOptionsDict[sub.metadata.membership_id]['prepaid_reports_included_per_month']+" Jobs per "+sub.plan.interval+""}</li>
                                            
                                          
                                        </ul>
                                    </span>
                                    { sub?.status=='trialing' && <>
                                      {sub?.pending_setup_intent ? <> 
                                      <div className="">
                                        <div className="w-100 text-center">
                                          <Link to={"/checkout/?step=payment&credits=0&plan="+sub?.metadata['membership_id']} className="btn btn-primary w-100 w-sm-auto">Finish Checkout</Link>
                                          
                                        
                                        </div>
                                      </div> 
                                      </> : <> 
                                      <div className="">
                                        <div className="w-100 text-center">
                                          <Link to={"/checkout/?step=payment&credits=0&plan="+sub?.metadata['membership_id']} className="btn btn-primary w-100 w-sm-auto">Start Paid Plan Now</Link>

                                        </div>
                                      </div> </>}
                                    </>}
                                  </>
                                )
                              }
                              })
                            }
                        </div>

                      </div>

                    </div>
                  </div>

                

                </div>



              <div className="card">
                  <div className="card-header card-header-content-between border-bottom">
                        <h2 className="card-header-title">
                          {
                            userEntity.access?.credits?.available_anytime + 
                            userEntity.access?.credits?.available_subscription
                            } Jobs Available
                        </h2>
                    <div className="col-md-auto">
                      <div className="d-grid d-sm-flex gap-3">
                        {currentSubscriptionLevel && currentSubscriptionLevel!='free' && <Link to={"/checkout/?credits=0&plan="+currentSubscriptionLevel}><button type="button" className="btn btn-link w-100 w-sm-auto">Renew Early</button></Link>}
                      </div>
                    </div>
                  </div>

                  { userEntity && userEntity.access && userEntity.access?.credits &&
                  <div className="card-body">
                    <div className="row align-items-center flex-grow-1 mb-2">
                      <div className="col">
                      </div>

                      
                    </div>
                    <div className="progress rounded-pill mb-3">
                      <div className="progress-bar" role="progressbar" style={{"width":(100*(userEntity.access?.credits?.available_subscription)/(userEntity.access?.credits?.available_anytime + userEntity.access?.credits?.available_subscription + userEntity.access?.credits?.used))+"%"}} aria-valuenow={userEntity.access?.credits?.available_subscription} aria-valuemin="0" aria-valuemax={userEntity.access?.credits?.available_anytime + userEntity.access?.credits?.available_subscription + userEntity.access?.credits?.used}></div>
                      <div className="progress-bar" role="progressbar" style={{"width":(100*(userEntity.access?.credits?.available_anytime)/(userEntity.access?.credits?.available_anytime + userEntity.access?.credits?.available_subscription + userEntity.access?.credits?.used))+"%"}} aria-valuenow={userEntity.access?.credits?.available_anytime} aria-valuemin="0" aria-valuemax={userEntity.access?.credits?.available_anytime + userEntity.access?.credits?.available_subscription + userEntity.access?.credits?.used}></div>
                      <div className="progress-bar" role="progressbar"></div>
                    </div>
                    <ul className="list-inline list-px-2">
                      <li className="list-inline-item">
                        <span className="legend-indicator bg-primary"></span> {userEntity.access?.credits?.available_subscription} Jobs available
                        ( Expiring
                        { userEntity.access?.credits?.expires_on && 
                      <> on {Moment(userEntity.access?.credits?.expires_on).format('MMM D')}</>
                      } )
                      </li>
                      { userEntity.access?.credits?.available_anytime > 0 &&
                      <li className="list-inline-item">
                        <span className="legend-indicator bg-primary"></span> {userEntity.access?.credits?.available_anytime} Jobs ( Non-expiring )
                      </li>
                      }
                      <li className="list-inline-item">
                        <span className="legend-indicator"></span> {userEntity.access?.credits?.used} Jobs used in this billing cycle
                      </li>
                    </ul>
                        <ul className="list-checked list-checked-secondary">
                          <li className="list-checked-item">Jobs expire at the end of the billing cycle</li>
                          <li className="list-checked-item">If you run out of jobs you can pay as you go for 300 KW CREDITS per job or renew your plan early</li>
                          <li className="list-checked-item">If you upgrade to a new subscription then you keep any remaining credits</li>
                        </ul>
                  </div>
                  }


                </div>

                <div className="card">
                  <div className="card-header card-header-content-between border-bottom">
                        <h2 className="card-header-title">{userEntity.access?.credits.gas} Keyword Credits Available</h2>
                    <div className="col-md-auto">
                      <div className="d-grid d-sm-flex gap-3">
                        <Link to={"/pricing/"}><button type="button" className="btn btn-link w-100 w-sm-auto">Buy Credits</button></Link>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md mb-0">
                        <ul className="list-checked list-checked-secondary">
                          <li className="list-checked-item">Credits never expire</li>
                          <li className="list-checked-item">Discounts available with select active monthly plans</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>




                

                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Payment history</h4>
                  </div>

                  <div className="table-responsive position-relative">
                    <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                      <thead className="thead-light">
                        <tr>
                          <th>Reference</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Created</th>
                          <th>Invoice</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        { (loading || ( (user != null && !user?.isAnonymous)) && billingInfo == null) &&
                          <tr>
                            <td><Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Loading...</td>
                          </tr>
                        }
                        { (user?.isAnonymous || (billingInfo!= null && billingInfo.invoices.data.length ==0)) && 
                          <tr>
                            <td>No Invoices Yet</td>
                          </tr>
                        }
                      { billingInfo!=null && billingInfo.invoices.data.map((invoice,i)=>{

                        var statusClass = "badge bg-soft-warning text-warning text-uppercase"
                        if(invoice.status=='paid'){
                          statusClass = "badge bg-soft-warning text-success text-uppercase"
                        }
                                return (
                                    <tr key={"inv_"+i}>
                                      <td><a href={invoice.hosted_invoice_url} target="_blank">#{invoice.number}</a></td>
                                      <td><span className={statusClass}>{invoice.status}</span></td>
                                      <td>${invoice.total/100}</td>
                                      <td>{new Date(invoice.created*1000).toLocaleString()}</td>
                                      <td><a className="btn btn-white btn-sm" href={invoice.invoice_pdf}><i className="bi-file-earmark-arrow-down-fill me-1"></i> PDF</a></td>
                                      <td>
                                        <a className="btn btn-white btn-sm" href={invoice.hosted_invoice_url} target="_blank"><i className="bi-eye-fill me-1"></i> Quick view</a>
                                      </td>
                                    </tr>
                                )
                                })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
    </>
  );
};
  
export default AccountBilling;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset, finishSignUp } from "./firebase";


function FinishSignUp() {
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  

  // do the login
  finishSignUp().then( response => {
    // if successful then merge
    console.log(response)
    if (response?.user != undefined ) console.log("with uid ", response?.user['uid']);
    navigate("/")
  });

  return (
    <div className="reset">
      <Link to="/"><div className="reset__container">
        Continue to home
      </div></Link>
    </div>
  );
}

export default FinishSignUp;


import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Safe from 'react-safe';
import { Helmet } from "react-helmet"
import styled from 'styled-components';
import { GraphUpArrow, BoxArrowUpRight } from 'react-bootstrap-icons';

import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SummaryChart from "./summary-chart";


var Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "25px",
      borderRadius: "15px",
      position: "absolute",
      margin: "auto",
      minWidth: "250px",
      width: "50vw",
      marginTop: "485px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper text-center" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h2>Unlock the Full Report</h2>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
              Access all the keyword ideas 
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
              Plus get 5 More Jobs FREE every month 
            </div>
            <div className="mt-4" style={{"width":"100%","text-align":"center"}}>
              <form className="form-inline">
                  <a className="btn btn-link mb-2" href={"/pricing/"}>See Pricing</a>
                  <a className="btn btn-outline-primary mb-2" href={"/register/"}>Unlock for Free</a>
              </form>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayKeywordGenteratorV1({jobDetails, reportParts, isRedacted, user, downloadFile}) {

  
  {console.log(jobDetails)}
  {console.log("reportParts",reportParts)}
  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  const renderTooltip = ({props, text}) => (
    <Tooltip {...props}>{text}</Tooltip>
  );

  return (
    <>
        
        {
            reportParts.length>0 && reportParts.map( (reportPart,i)=> {



              const resultsByDisplayType = ({reportPart,results}) => {
                // determine how to display it
                  
                // all keywords startig with the topic
                var all_keywords = jobDetails?.inputs?.topic+"%0D%0A"
                results?.data?.map((link, index) => {
                    all_keywords = all_keywords+link?.keyword+"%0D%0A"
                })


                if( reportPart?.displayTemplate=='raw-results' ){
                  // display everything in json
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}} key={"row_"+i}><pre style={{"overflow":"unset"}}>{JSON.stringify(results, null, 2) }</pre></div> )
                
                } else if( reportPart?.id=='overview' ){

                    
                    return(
                        <>
                        <div className="row">

                            <div className="col-12 col-lg-4 mb-3 mb-lg-5">
                                <div className="card card-hover-shadow h-100">
                                    <div className="card-body">
                                    <h6 className="card-subtitle">Total</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-12">
                                        <h1 className="card-title text-inherit">{results?.summary?.total} Keyword Ideas</h1>
                                        </div>
                                        <span>Language: <b className="text-uppercase">{jobDetails?.inputs?.hl || "EN"}</b></span>
                                    </div>

                                    </div>
                                </div>
                            </div>
                            
                            {Object.keys(results?.summary?.count_by_type || [])?.map(i => {
                                return( <>
                                    <div className="col-sm-3 col-lg-2 mb-3 mb-lg-5 d-none d-md-inline">
                                        <div className="card card-hover-shadow h-100">
                                            <div className="card-body">
                                            <h6 className="card-subtitle">{i}</h6>
        
                                            <div className="row align-items-center gx-2 mb-1">
                                                <div className="col-5">
                                                <h2 className="card-title text-inherit">{results?.summary?.count_by_type[i]}</h2>
                                                </div>
                                            </div>
        
                                            </div>
                                        </div>
                                    </div>
                                    </>)
                            })}
                        </div>

                            <div className="row">
                                <Link to={"/tools/serp-similarity/?hl="+(jobDetails?.inputs?.hl || "EN")+"&text="+all_keywords}>
                                    <button className="btn btn-primary btn-lg mb-2 w-100">De-duplicate and Prioritieze into Topic Clusters &gt;</button>
                                </Link>
                            </div>
                        </>
                    )
                
                } else if( reportPart.style=='data' || (!('displayTemplate' in reportPart) && reportPart.id=='data' ) ){
                  // simple data table
                  return(
                    <>
                      { results?.data &&
                      <>
                      <div style={{"position":"relative", "minHeight":"600px"}} key={"row_"+i}>
                        <div style={{"zIndex":-100}}>
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th className="d-none d-lg-block" scope="col">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={renderTooltip({"text":"Source of the Keyword"})}
                                  >
                                    <span>Source < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={renderTooltip({"text":"All keywords generated"})}
                                  >
                                    <span>Keyword < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col" style={{"maxWidth":"180px"}}>
                                  Next Action
                                </th>
                              </tr>
                              
                            </thead>
                            <tbody>
                              {results?.data && results?.data.length > 0 && results?.data?.map((link, index) => {
                                return <tr key={"link_"+index+1}>
                                  <td className="d-none d-lg-block" style={{"width":"80px","maxWidth":"80px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                      {link?.source}
                                  </td>
                                  <td style={{"width":"100%", "minWidth":"140px","maxWidth":"180px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                      <Link title="Generate more keywords like this" to={"/tools/keyword-generator/?topic="+encodeURIComponent(link?.keyword)}><GraphUpArrow /> {link?.keyword}</Link>
                                  </td>
                                  <td style={{"width":"180px","minWidth":"140px","maxWidth":"180px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden"}}>
                                      <Link className="d-none d-md-inline" title="Generate more keywords like this" to={"/tools/keyword-generator/?topic="+encodeURIComponent(link?.keyword)+"&hl="+jobDetails?.inputs?.hl || "EN"}>
                                        <span className="badge bg-soft-warning text-dark text-uppercase m-1">+ More</span>
                                      </Link>
                                      <Link title={"Create a new AI Brief and Draft article about '"+link?.keyword+"'"} to={"/tools/brief-draft/?topic="+encodeURIComponent(link?.keyword)+"&hl="+jobDetails?.inputs?.hl||"EN"}>
                                        <span className="badge bg-soft-primary text-dark text-uppercase m-1">+ Brief Draft</span>
                                      </Link>
                                  </td>
                                  

                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      {isRedacted && 
                        <PaywallOverlay></PaywallOverlay>
                      }
                      </div>
                      
                      <span style={{"fontSize":"larger"}}>
                      TOTAL: <b>{results?.summary?.total} Keywords</b> 

                      </span>
                      { isRedacted && 
                        <>
                          <p> ( <a href={"/register/"}>register for the full list</a> )</p>
                        </>
                      }

                        <div className="row">
                            <Link to={"/tools/serp-similarity/?hl="+(jobDetails?.inputs?.hl || "EN")+"&text="+all_keywords}>
                                <button className="btn btn-primary mb-2 w-100">De-duplicate and Prioritieze into Topic Clusters &gt;</button>
                            </Link>
                        </div>
                      </>
                    }
                    </>
                  )

                }
                
                // todo uses the id that must match!!!
                if( reportPart.id in results ){
                  // return json display of just this section
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}}><pre style={{"overflow":"unset"}}>{JSON.stringify(results[reportPart.id], null, 2) }</pre></div> )
                }
                
              }
              return(
                <>
                  <div className="reportPart content" id={reportPart['id']}>
                    <h1>{reportPart['name']}</h1>
                    { resultsByDisplayType({"reportPart":reportPart,"results":jobDetails?.results}) }
                  </div>
                </>
                )
              })
          }


    </>
  );
}

export default DisplayKeywordGenteratorV1;

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Safe from 'react-safe';
import { Helmet } from "react-helmet"
import styled from 'styled-components';
import { GraphUpArrow, BoxArrowUpRight } from 'react-bootstrap-icons';

import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SummaryChart from "./summary-chart";


var Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
var Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayNicheDiscoveryV1({jobDetails, reportParts, isRedacted, user}) {

  console.log("jobDetails", jobDetails)
  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  const renderTooltip = ({props, text}) => (
    <Tooltip {...props}>{text}</Tooltip>
  );

  return (
    <>
        
        {
            reportParts.length>0 && reportParts.map( (reportPart,i)=> {

              const resultsByDisplayType = ({reportPart,results}) => {
                // determine how to display it
                if( reportPart.displayTemplate=='raw-results' ){
                  // display everything in json
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}} key={"row_"+i}><pre style={{"overflow":"unset"}}>{JSON.stringify(results, null, 2) }</pre></div> )

                } else if( reportPart.style=='summary' || (!('displayTemplate' in reportPart) && reportPart.id=='summary' ) ){
                  
                  // simple summary one row for each in a dict
                  return(
                    <>
                     {results?.summary &&
                      <div className="row" key={"row_"+i}>

                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                          <div className="card card-hover-shadow h-100" >
                            <div className="card-body">
                              <h6 className="card-subtitle">Overview</h6>

                              <div className="row align-items-center gx-2 mb-1">
                                <div className="col-12 mb-3">
                          <a href="#data">
                                  <h2 className="card-title text-inherit">{results?.summary?.total} Niche Sites</h2>
                          </a>
                                </div>
                                <div className="col-12 mb-1">
                                  <span className="text-inherit" style={{"color":"black"}}>{jobDetails?.inputs?.category}</span>
                                </div>
                                <div className="col-12 mb-1">
                                  <span className="text-inherit" style={{"color":"black"}}>{jobDetails?.inputs?.tier2} {jobDetails?.inputs?.niche_other_option}</span>
                                </div>
                                <div className="col-12 mb-1">
                                  <span className="text-inherit" style={{"color":"black"}}>{jobDetails?.inputs?.query}</span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                          <div className="card card-hover-shadow h-100">
                            <div className="card-body">
                              <h6 className="card-subtitle">Avg. Domain Rank</h6>

                              <div className="row align-items-center gx-2 mb-1">
                                <div className="col-5">
                                  <h2 className="card-title text-inherit">{results?.summary?.rank?.median && results['summary']['rank']['median']}</h2>
                                </div>

                                {results?.summary?.rank?.hist && 
                                <div className="col-7">
                                  <SummaryChart counts={results?.summary?.rank?.hist?.count} labels={results?.summary?.rank?.hist?.labels}></SummaryChart>
                                </div>
                                }
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                          <div className="card card-hover-shadow h-100">
                            <div className="card-body">
                              <h6 className="card-subtitle">Avg. Monthly Traffic</h6>

                              <div className="row align-items-center gx-2 mb-1">
                                <div className="col-5">
                                  <h2 className="card-title text-inherit" style={{"whiteSpace": "nowrap"}}>{results?.summary?.traffic?.median && results['summary']['traffic']['median'].toLocaleString()}</h2>
                                </div>

                                {results?.summary?.traffic?.hist && 
                                <div className="col-7">
                                  <SummaryChart counts={results?.summary?.traffic?.hist?.count} labels={results?.summary?.traffic?.hist?.labels}></SummaryChart>
                                </div>
                                }
                              </div>

                            </div>
                          </div>
                        </div>


                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                          <div className="card card-hover-shadow h-100">
                            <div className="card-body">
                              <h6 className="card-subtitle">Avg. Page Count</h6>

                              <div className="row align-items-center gx-2 mb-1">
                                <div className="col-5">
                                  <h2 className="card-title text-inherit" style={{"whiteSpace": "nowrap"}}>{results?.summary?.pages?.median && results['summary']['pages']['median'].toLocaleString()}</h2>
                                </div>

                                {results?.summary?.pages?.hist && 
                                <div className="col-7">
                                  <SummaryChart counts={results?.summary?.pages?.hist?.count} labels={results?.summary?.pages?.hist?.labels}></SummaryChart>
                                </div>
                                }
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    </>
                  )

                } else if( reportPart.style=='data' || (!('displayTemplate' in reportPart) && reportPart.id=='data' ) ){
                  // simple data table
                  return(
                    <>
                      { results?.data &&
                      <>
                      <div style={{"position":"relative", "minHeight":"600px"}} key={"row_"+i}>
                        <div style={{"zIndex":-100}}>
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"Launch a new report using the 'Domain Analysis' tool."})}
                                  >
                                    <span>Domain Analysis Report < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col" style={{"textAlign":"center"}}>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"Score used to estimate google's pagerank. 100 is the best."})}
                                  >
                                    <span>DR < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col" style={{"textAlign":"center"}}>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"Estimated average monthly traffic."})}
                                  >
                                    <span>Traffic < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col" style={{"textAlign":"center"}}>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"Approximate total number of unique urls on the domain."})}
                                  >
                                    <span>Pages < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col" style={{"textAlign":"center"}}>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({"text":"Approximate total number unique domains backlinking to this domain."})}
                                  >
                                    <span>RD < BiHelpCircle></BiHelpCircle></span>
                                  </OverlayTrigger>
                                </th>
                                <th scope="col">URL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {results.data.map((link, index) => {
                                return <tr key={"link_"+index+1}>
                                  <th scope="row">{link?.order}</th>
                                  <td style={{"minWidth":"140px","maxWidth":"180px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                      <Link to={"/tools/domain-analysis/?url="+encodeURIComponent(link?.domain?.url)}><GraphUpArrow /> {link?.domain?.name}</Link>
                                  </td>
                                  
                                  {!isRedacted && 
                                    <>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.rank || "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.traffic? link?.domain?.traffic.toLocaleString() : "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.pages? link?.domain?.pages.toLocaleString() : "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.referring_domains? link?.domain?.referring_domains.toLocaleString() : "-"}</td>
                                    </>
                                  }
                                  {isRedacted && <>
                                  { index<=2 &&
                                    <>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.rank || "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.traffic? link?.domain?.traffic.toLocaleString() : "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.pages? link?.domain?.pages.toLocaleString() : "-"}</td>
                                      <td style={{"textAlign":"right"}}>{link?.domain?.referring_domains? link?.domain?.referring_domains.toLocaleString() : "-"}</td>
                                    </>
                                  }
                                  { index>2 && index<=3 &&

                                    <>
                                      <Tdblur1 style={{"textAlign":"right"}}>{link?.domain?.rank || "-"}</Tdblur1>
                                      <Tdblur1 style={{"textAlign":"right"}}>{link?.domain?.traffic? link?.domain?.traffic.toLocaleString() : "-"}</Tdblur1>
                                      <Tdblur1 style={{"textAlign":"right"}}>{link?.domain?.pages? link?.domain?.pages.toLocaleString() : "-"}</Tdblur1>
                                      <Tdblur1 style={{"textAlign":"right"}}>{link?.domain?.referring_domains? link?.domain?.referring_domains.toLocaleString() : "-"}</Tdblur1>
                                    </>
                                  }
                                  { index>3 && index<=4 &&
                                    <>
                                      <Tdblur2 style={{"textAlign":"right"}}>{link?.domain?.rank || "-"}</Tdblur2>
                                      <Tdblur2 style={{"textAlign":"right"}}>{link?.domain?.traffic? link?.domain?.traffic.toLocaleString() : "-"}</Tdblur2>
                                      <Tdblur2 style={{"textAlign":"right"}}>{link?.domain?.pages? link?.domain?.pages.toLocaleString() : "-"}</Tdblur2>
                                      <Tdblur2 style={{"textAlign":"right"}}>{link?.domain?.referring_domains? link?.domain?.referring_domains.toLocaleString() : "-"}</Tdblur2>
                                    </>
                                  }
                                  { index > 4 &&
                                    <>
                                      <Tdblur style={{"textAlign":"right"}}>{getRandomInt(100)}</Tdblur>
                                      <Tdblur style={{"textAlign":"right"}}>{getRandomInt(1000000)}</Tdblur>
                                      <Tdblur style={{"textAlign":"right"}}>{getRandomInt(100000)}</Tdblur>
                                      <Tdblur style={{"textAlign":"right"}}>{getRandomInt(1000)}</Tdblur>
                                    </>
                                  }
                                  </>
                                  }
                                  <td style={{"minWidth":"40px","maxWidth":"120px",
                                            "whiteSpace": "nowrap",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"}}>
                                    <span>
                                      <a href={link?.domain?.url} target="_blank"><BoxArrowUpRight /> {link?.domain?.url}</a>
                                    </span>
                                  </td>

                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      {isRedacted && 
                        <PaywallOverlay></PaywallOverlay>
                      }
                      </div>
                      
                      <span style={{"fontSize":"larger"}}>
                      TOTAL: <b>{results?.summary?.total} Websites</b> 
                      </span>
                      { true && 
                        <>
                          &nbsp; ( <a href={"/register/"}>register for the full list</a> )
                        </>
                      }
                      </>
                    }
                    </>
                  )

                }
                
                // todo uses the id that must match!!!
                if( reportPart.id in results ){
                  // return json display of just this section
                  return( <div style={{
                    "height": "400px",
                    "maxHeight": "50vh",
                    "overflow": "auto",
                    "padding": "20px",
                    "backgroundColor": "aliceblue"}}><pre style={{"overflow":"unset"}}>{JSON.stringify(results[reportPart.id], null, 2) }</pre></div> )
                }
                
              }
              return(
                <>
                  <div className="reportPart content" id={reportPart['id']}>
                    <h1>{reportPart['name']}</h1>

                    { resultsByDisplayType({"reportPart":reportPart,"results":jobDetails?.results}) }
                  </div>
                </>
                )
              })
          }


    </>
  );
}

export default DisplayNicheDiscoveryV1;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import Layout from "./components/Layout";
import RecentAnalysis from "./components/RecentAnalysis";
import { auth } from "./pages/authentication/firebase";
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';


const Dashboard = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  var limit = 20
  var page = 0


  limit = searchParams.get('limit')
  page = searchParams.get('page')
  if( !limit){
    limit = 20
  }
  limit = Math.min(limit, 100)
  if( !page){
    page = 0
  }

  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

  const [user, loading, error] = useAuthState(auth);
  const [userAnalysis, setUserAnalysis] = useState(null)
  const [showActivity, setShowActivity] = useState(true);
  const [userEntity, setUserEntity] = useState({})
  const [err, setErr] = useState(null);
  const [warn, setWarn] = useState(null);

  const navigate = useNavigate();

  useEffect(()=>{
    if (loading) return;
    if (false && user && userEntity) {
      console.log("userEntity", userEntity)
      console.log("user", user)
    }
  }, [userEntity,user])

  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'refreshToken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)
      }).catch(error => {
        console.log(error)
        setWarn("PLEASE REFRESH: Your session has expired or our backend services are currently busy.")
      });

      axios.get(api_url + '/v1/analysis?page='+searchParams.get('page',0)+"&limit="+searchParams.get('limit',20), {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        // list all analysis
        setUserAnalysis(response.data)

        if( response.data.length == 0){
          //navigate("/tools/")
        }
      }).catch(error => {
        console.log(error)
      })

    };
    
  }, [user, loading])



  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  return (
    <>
      <Layout allowAnonymous={false} pageTitle={"Dashboard"} logoLinkToPublicHome={false} hideTermsAccepted={true}>


      <div className="container mb-10">
        
      { user && user.isAnonymous && 
                <div className="alert alert-soft-warning mb-5 mb-lg-5" role="alert">
                  <div className="d-lg-flex align-items-end">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Megaphone announcment" data-hs-theme-appearance="default"/>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h3 className="alert-heading mb-1">REGISTER NOW TO SAVE YOUR PROGRESS</h3>
                        
                          <ul className="list-checked list-checked-sm list-checked-primary mt-3 mb-0">
                            <li className="list-checked-item">Earn 5 more FREE jobs every month!</li>
                            <li className="list-checked-item">Access pay-as-you-go pricing</li>
                          </ul>
                      </div>
                    </div>

                          <div className="flex-grow-1 text-end mt-3">
                            <Link to={"/register/?url="+encodeURIComponent(window.location.pathname+window.location.search)}>
                              <button type="button" className="btn btn-primary">Sign-up Now</button>
                            </Link>
                          </div>
                  </div>
                </div>
              }
        <div className="page-header job-header">
          <div className="row align-items-end">
            <div className="col mb-2 mb-sm-0">

             
              <h1>Recent Activity</h1>
            </div>
            <div className="col-auto">
              <Link className="btn btn-outline-primary" to="/tools/">
                + New
              </Link>
            </div>

          </div>
            <ul className="nav nav-tabs page-header-tabs">
                <li className="nav-item">
                    <Link className="nav-link active" to="/analysis/">
                    All
                    </Link>
                </li>
                { false && 
                <>
                  <li className="nav-item">
                      <a className="nav-link" href="/archives/">
                      Archived
                      </a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link" href="/shared/">
                      Shared
                      </a>
                  </li>
                </>
                }
            </ul>
        </div>
                  { warn &&
                    <div className="alert alert-soft-warning mb-7" role="alert">
                      <span style={{"color":"gray"}}>{warn}</span>
                    </div>
                  }

        { user && !user?.isAnonymous && userEntity[process.env.REACT_APP_ACCESS_FIELD] && ( userEntity[process.env.REACT_APP_ACCESS_FIELD].credits?.available_anytime + userEntity[process.env.REACT_APP_ACCESS_FIELD].credits?.available_subscription ) == 0 &&
                  <div className="alert alert-soft-dark mt-3 mb-5 mb-lg-7" role="alert">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Image Description" data-hs-theme-appearance="default"/>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h3 className="alert-heading mb-1">Attention!</h3>
                        <p className="mb-0">You are all out of credits. Please <a href={"/pricing/"}>upgrade your plan</a> or see billing for more details.</p>
                      </div>
                    </div>
                  </div>
                }


        { userAnalysis && userAnalysis.length == limit && <>
          <div class="row"><div className="col-12"><a href={"?page="+(Number(page)+1)} className="float-end btn btn-link">Next Page &gt;</a></div></div></>}


      {!userAnalysis? <div className="text-center">Loading ... <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> </div>:

        <>
        {userAnalysis && <RecentAnalysis userAnalysis={userAnalysis} showActivity={showActivity} loading={loading} user={user}/>}
        </>
      }
      { userAnalysis && userAnalysis.length == limit && <>
        <div class="row"><div className="col-12"><a href={"?page="+(Number(page)+1)} className="float-end btn btn-link">Next Page &gt;</a></div></div></>}


        </div>
      </Layout>
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate,useSearchParams } from "react-router-dom";
import { auth, logout, saveAnonymousViaEmail, linkUserWithEmailAndPassword} from "./authentication/firebase";
import axios from 'axios'
import Layout from '../components/Layout';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


const Continue = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isAnonymous, setIsAnonymous] = useState(false);

  // get user
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [Err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState(null);

  useEffect(()=>{
    if (loading) return;
    if (!user) {
      // if none go back to root (login)
      return navigate("/");
    } else {
      
      // save in local storage
      localStorage.setItem('authUser', JSON.stringify(user))

      if (JSON.parse(localStorage.getItem('authUser'))['isAnonymous']){
        // if anonymous then give option to upgrade
        setIsAnonymous(true)
        setUid(JSON.parse(localStorage.getItem('authUser'))['uid'])
      } else {
        // redirect to the job page
        if ( searchParams.get("job_id") ) {
          navigate("/reports/"+searchParams.get("job_id"))
        }
      }
    };
    
  }, [user, loading]);


  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      navigate("/");
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";


  const saveEmailtoUserTableInDatastore = async (uid, email) => {
    axios.post(
      api_url + '/v1/user/update', 
      {
        "uid": uid,
        "email": email
      }, 
      {
        headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}
    });
  };

  const handleButtonClick2 = async () => {
    setIsLoading(true);
    try {
      saveEmailtoUserTableInDatastore(uid, email);
      linkUserWithEmailAndPassword(null,email,"supersecretlongpassword123123123123123123123")
      saveAnonymousViaEmail(email, uid);
      navigate("/");
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Layout>
        <div className="row justify-content-lg-center">
          <div className="col-lg-9">
            <div className="d-grid gap-3 gap-lg-5">

              <div>
                <h1>Step 3: (OPTIONAL) Save Your Progress</h1>
                Running {user?.isAnonymous? <>using Limited Guest Access</>: <>as {user?.email}</> }
              </div>


              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md mb-4 mb-md-0">
                      <div className="mb-4">
                        <span className="card-subtitle">Your job is currenty running</span>
                        <h3>Spinner Job: {searchParams.get("primary_url")} .....</h3>
                      </div>

                    </div>

                    <div className="col-md-auto">
                      <div className="d-grid d-sm-flex gap-3">
                        <a className="btn btn-link" onClick={(e) => handleButtonClick(e)}>Check Status</a>
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Initializing
                          </Button>
                      </div>
                    </div>
                  </div>
                </div>



                
              </div>

              <div className="card">

                <div className="card-body">
                  <div className="row">
                    <div className="col-md mb-4 mb-md-0">
                      <div className="mb-4">
                        { isAnonymous?
                          <>
                            <h3>Email a copy of the realtime report?</h3>
                            
                              <input
                                type="text"
                                className="login__textBox"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="E-mail Address"
                              />
                              <button onClick={(e) => handleButtonClick(e)}>Skip</button>
                              <button
                                className="login__btn"
                                onClick={() => handleButtonClick2()}
                              >
                                Save Progress
                              </button>
                            <div>
                              Want to create an account? <Link to="/register">Register</Link> now.
                            </div>
                            
                          </> : <>
                          <button onClick={(e) => handleButtonClick(e)}>Continue</button>
                          </>
                        }

                      </div>
                    </div>

                  </div>
                </div>


                <div className="card-body">
                  <div className="row align-items-center flex-grow-1 mb-2">
                    <div className="col">
                      <div className="d-grid d-sm-flex gap-3">
                        <button type="button" className="btn btn-primary float-right w-100 w-sm-auto w-lg-25" data-bs-toggle="modal" data-bs-target="#accountUpdatePlanModal" onClick={(e) => handleButtonClick(e)}>Submit</button>
                        <a className="btn btn-link float-left" href={"/tools/"+searchParams.get("report")}><i className="bi bi-arrow-left"></i> Reset</a>
                      </div>
                    </div>

                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
  
export default Continue;

import React, { useEffect, useState } from "react";


import styled from 'styled-components';


import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ResultsPageOverview({jobDetails, detailsJson, summaryJson}) {
  const renderTooltip = ({props, text}) => (
    <Tooltip {...props}>{text}</Tooltip>
  );


  const ReportRowItem = styled.div`
  margin-top:4px;
  float:left;
  width: 125px;
  margin-right: -125px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1em;
  `;
  const ReportRowDetails = styled.div`
  margin-left:125px;
  overflow:hidden;
  `;
  const ReportRow = styled.div`
  margin-bottom:10px;
  min-height: 20px;
  `;

  return (
    <>
      
      <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">
                        Content Brief <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({"text":"An overview of the content found on this URL"})}
                        >
                          <span>< BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                        
                        <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                    </div>

                    <div className="card-body">
                        <p>

                            {jobDetails?.results?.keyphrases?.semantics &&<ReportRow>
                                <ReportRowItem>Primary Topic:</ReportRowItem>
                                <ReportRowDetails>{jobDetails?.results?.keyphrases?.semantics && jobDetails?.results?.keyphrases?.semantics[0]?.key_phrase}</ReportRowDetails>
                            </ReportRow>
                            }
                            <ReportRow>
                                <ReportRowItem>Title:</ReportRowItem>
                                <ReportRowDetails>{jobDetails?.results?.meta?.title}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>Description:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.description)? <span className="text-danger">Missing description</span> : jobDetails?.results?.meta?.description}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>Type:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.type)? <span className="text-danger">Not Detected</span> : <>
                              <a href={jobDetails?.results?.meta?.type? "#schema-"+jobDetails?.results?.meta?.type.toLowerCase() : ""}>{jobDetails?.results?.meta?.type}</a>
                            </>}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>Published:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.datePublished)? <span className="text-danger"></span> : <>Created: {jobDetails?.results?.meta?.datePublished}</>}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>Modified:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.dateModified)? <span className="text-danger"></span> : <>Created: {jobDetails?.results?.meta?.dateModified}</>}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>inLanguage:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.inLanguage)? <span className="text-danger"></span> : jobDetails?.results?.meta?.inLanguage}</ReportRowDetails>
                            </ReportRow>
                            <ReportRow>
                                <ReportRowItem>primaryImage:</ReportRowItem>
                                <ReportRowDetails>{(!jobDetails?.results?.meta?.primaryImage)? <span className="text-danger">Not Detected</span> : jobDetails?.results?.meta?.primaryImage}</ReportRowDetails>
                            </ReportRow>
                        </p>

                        <div className="row">

                        <div className="col-6 col-lg-3 mb-3 mb-lg-5">
                        <div className="card h-100">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2">Text</h6>

                            <div className="row align-items-center gx-2">
                            <div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.words?.in_context}</span> Words
                            </div>
                            </div>

                            <div className="row align-items-center gx-2">
                            <a href="#segments"><div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{jobDetails?.results?.segments && jobDetails?.results?.segments.length}</span> Segments
                            </div></a>
                            </div>

                            <div className="row align-items-center gx-2">
                            <a href="#keyphrases"><div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{jobDetails?.results?.keyphrases?.semantics && jobDetails?.results?.keyphrases?.semantics.length}</span> Topics
                            </div></a>
                            </div>

                        </div>
                        </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-3 mb-lg-5">
                        <div className="card h-100">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2">Links</h6>

                            <div className="row align-items-center gx-2">
                            <div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.links?.in_context_internal && summaryJson?.links?.in_context_internal}</span> Internal
                            </div>
                            </div>
                            
                            <div className="row align-items-center gx-2">
                            <div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.links?.in_context_external && summaryJson?.links?.in_context_external}</span> External
                            </div>
                            </div>
                            
                            <div className="row align-items-center gx-2">
                            <div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.links?.total - summaryJson?.links?.in_context}</span> Nav
                            </div>
                            </div>
                            
                        </div>
                        </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-3 mb-lg-5">
                        <div className="card h-100">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2">mutli-Media</h6>

                            <div className="row align-items-center gx-2">
                            <a href="#multimedia-images"><div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.images?.in_context}</span> Images
                            </div></a>
                            </div>
                            {summaryJson?.videos?.in_context && 
                            <div className="row align-items-center gx-2">
                            <div className="col">
                                <span className="js-counter display-4 text-dark" data-value="28">{summaryJson?.videos?.in_context}</span> Video
                            </div>
                            </div>
                            }
                        </div>
                        </div>
                        </div>
                    </div>
                    <p>
                      <b>Segments:</b>
                    </p>
                      <ol>
                      {jobDetails.results.segments.map((message, index) => {
                          return <li key={"brief_schema_"+index}>
                              <a href={"#segment-"+(index+1)}>
                                {message?.title && <>&lt;{message.title?.markup.toUpperCase()}&gt; </>}{message?.title? <>{message.title?.text_summarized}</> : "Introduction"}
                              </a>
                            </li>
                      })}
                      </ol>
                    <p>
                      <b>Schemas:</b>
                    </p>
                      <ul>
                      {jobDetails?.results?.schemas.map((message, index) => {
                          return <li key={"brief_schema_"+index}>
                              <a href={"#schema-"+message['@type'].toLowerCase()}>
                              {message['@type']} ({message['data'].length})
                              </a>
                              <ul>
                                {message['data'].map((d, dindex) => {
                                  return <li key={"brief_schema_"+index+"_item_"+dindex}>{d?.['@id']? d?.['@id'] : "Item without Id"}</li>
                                })}
                              </ul>
                            </li>
                      })}
                      {jobDetails?.results?.schemas && jobDetails?.results?.schemas.length ==0 && <span className="text-danger">No Schema Detected</span>}
                      </ul>
                    </div>
                </div>
            </div>
        </div>

      <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">Channel Details</h4>
                    </div>

                    <div className="card-body">
                        <p>
                            Domain: {detailsJson?.domain}<br/>
                            URL: <a href={detailsJson?.url} rel="noreferrer" target="_blank">{detailsJson?.url}</a><br/>
                        </p>
                    </div>
                </div>
            </div>
      </div>
    </>
  );
}

export default ResultsPageOverview;
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, createAnonymousUser } from "./authentication/firebase";
import axios from 'axios'
import Layout from '../components/Layout';
import Safe from 'react-safe';


const Start = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  useEffect(() => {
    // get input query
    var q = null;
    if(  searchParams.get("input") ){
      // get input and trim ;)
      q = searchParams.get("input").trim()
    }

    // if q is a url
    if( (q.slice(0,4)=="http") || (q.includes(".")) ){
      // domain analysis
      if((q.slice(0,4)!="http") && !(q.includes("//")) ){
        q = "https://"+q
      }
      navigate("/tools/domain-analysis/?url="+encodeURIComponent(q))
    } else {
      // redirect to niche discovery
      navigate("/tools/niche-discovery/?q="+encodeURIComponent(q))
    }

  }, [searchParams]);






  return (
    <>
    </>
  );
};
  
export default Start;
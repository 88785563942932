import React, { useEffect, useState } from "react";
import Layout from '../components/Layout';
import { Helmet } from "react-helmet"



import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "./authentication/firebase";
import axios from 'axios'


const SupportThankYou = () => {


  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";



  return (
    <>
      <Layout>
          <h1>Thank you!</h1>
          We will contact you very soon.
      </Layout>
    </>
  );
};
  
export default SupportThankYou;
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Layout from '../../components/Layout';
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../authentication/firebase";
import axios from 'axios';
import './orderComplete.css';

import { Helmet } from "react-helmet";



const ConfirmEmail = () => {

  // get the user
  const [user, loading, error] = useAuthState(auth);
  const [userEntity, setUserEntity] = useState({})
  const navigate = useNavigate();
  
  useEffect(() => {
    if (loading) return;

    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";

    if (!user) {
      // go home if not a user
      navigate("/");
    } else {

      // get billing information
      axios.get(api_url + '/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)
        console.log(response.data.user_entity)
      }).catch(error => {
        console.log(error)
      });

      // console.log(user)
    }
  }, [user, loading]);
  
  
  
  return (
    <>
      <Layout hideUpgradeNotification={true} fullScreen={true} hideTermsAccepted={true}>

      { true && 
        // <!-- Twitter conversion tracking for checkout -->
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            twq('event', 'tw-ofrd2-ofrd8', {
            });`
        }]}
        />
      }
      { true && 
        // <!-- Twitter conversion tracking for customer -->
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            twq('event', 'tw-ofrd2-ofrdd', {
            });`
        }]}
        />
      }

        <main id="content" role="main" className="main">
          <div className="content container-fluid" style={{"overflowY":"auto"}}>

            <div id="checkoutStepSuccessMessage">
              <div className="text-center">
                <img className="orderSuccess img-fluid mb-3" src="/assets/svg/illustrations/oc-browse.svg" alt="Image Description" data-hs-theme-appearance="default"/>

                <div className="mb-4">
                    <h2>Please Confirm Your Email</h2>
                </div>


                <a className="btn btn-primary" href="/tools/">
                    Continue
                </a>
                
              </div>
            </div>
          </div>
        </main>
    </Layout>
    </>
  );
};
  
export default ConfirmEmail;
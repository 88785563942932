
import React, { useEffect, useState } from "react";

import styled from 'styled-components';
import ResultsPageOverview from "./ResultsPageOverview";
import { GraphUpArrow, BoxArrowUpRight, CheckSquare, ExclamationSquareFill, Square } from 'react-bootstrap-icons';

import ResultsPageSegments from "./ResultsPageSegments";
import ResultsPageKeyphrases from "./ResultsPageKeyphrases";
import { BiHelpCircle } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const Tdblur1 = styled.td`
  filter: blur(1px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur2 = styled.td`
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
`;
const Tdblur = styled.td`
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
`;

const ReportRowItem = styled.div`
    margin-top:4px;
    float:left;
    width: 125px;
    margin-right: -125px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1em;
`;
const ReportRowDetails = styled.div`
    margin-left:125px;
    overflow:hidden;
`;
const ReportRow = styled.div`
    margin-bottom:10px;
    min-height: 20px;
`;


class PaywallOverlay extends React.Component {
  render() {
    const mystyle = {
      padding: "35px",
      borderRadius: "5px",
      position: "absolute",
      margin: "auto",
      marginTop: "280px",
      marginBottom: "0px",
      boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.20)",
      top: 0, left: "20%",
      zIndex: 100,
      backgroundColor: "#fff"
    };
    const bgWhiteFade = {
      position: "absolute",
      bottom: 0, left: 0,
      height: "300px",
      width: "100%",
      background: "linear-gradient(to bottom, rgba(255,255,255,0), #fff)"
    }
    const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
    return (
      <div style={{"width":"100%"}}>
        <div style={bgWhiteFade}></div>
          <div id="paywall-wrapper" style={mystyle}>
            <div style={{"width":"100%","text-align":"center"}}>
              <h4>Ready for more data?</h4>
            </div>
            <div style={{"width":"100%","text-align":"center"}}>
                <a className="btn btn-primary" href={"/register/"}>Sign-up for Free</a> <a className="btn btn-outline-secondary" href={"/pricing/"}>See Pricing!</a> 
            </div>
            <div className="mt-3">
              <ul>
                <li>Unlock all the data</li>
                <li>Share reports</li>
                <li>Data exports</li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}



function DisplayPageInspectionInclusivityTextV1({jobDetails, reportParts}) {


    const Subsection = ({subsection, index}) => {
        return (
            <>
                    <div className="col-lg-12 p-2 pt-2 pb-2 hover-highlight" id={"segment-"+(index+1)+"-"+index}>
                        <div className="">
                                <div>{subsection?.keyphrases && subsection?.keyphrases.map((kw,index)=>{return kw?.key_phrase+" "})}</div>
                        </div>
                    </div>
            </>
        )
    }


    const renderTooltip = ({props, text}) => (
        <Tooltip {...props}>{text}</Tooltip>
    );

    {console.log(jobDetails)}
  return (
    <>
      <div className="reportPart content" id="overview">
        {jobDetails?.results && 
            <>
            
            <div className="col-lg-12 mb-3 mb-lg-5">
                <div className="row">

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                        <div className="card card-hover-shadow h-100">
                        <div className="card-body">
                            <h6 className="mb-3 card-subtitle">Inclusivity Score</h6>


                                {jobDetails?.results?.overview?.score < 100 &&
                                    <>
                                            
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span><Square/> {jobDetails?.results?.overview?.score}</span> of 100</h2>
                                            </div>
                                        </div>
                                        
                                    </>
                                }
                                {jobDetails?.results?.overview?.score==100 &&
                                    <>
                                            
                                        <div className="row align-items-center gx-2 mb-3">
                                            <div className="col-5">
                                                <h2 className="card-title text-inherit text-nowrap" ><span className="text-success"><CheckSquare/> {jobDetails?.results?.overview?.score}</span> of 100</h2>
                                            </div>
                                        </div>
                                        
                                    </>
                                }

                            
                                <p>
                                    A very non-inclusive text would score less than 50 on a 100 point scale. 
                                    Text that appears to be inclusive would be at or near 100.
                                </p>
                                <p>Inclusivity is personal and it can be interpreted differently.</p>
                                <p>
                                    It is commendable to achieving an inclusivity score of 100.
                                </p>
                                
                        </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-9 mb-3 mb-lg-5">
                        
                        <div className="card h-100">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">
                                Inclusivity Summary <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({"text":"An overview of the content found in this text"})}
                                >
                                <span>< BiHelpCircle></BiHelpCircle></span>
                                </OverlayTrigger>
                                
                                <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone." aria-label="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></h4>
                            </div>

                            <div className="card-body">
                                <p>This score is produced useing an automated approach combined with the
                                results from OpenAI's GPT-3.5 model to identify phrases that might be considered non-inclusive.</p>

                                <ReportRow>
                                    <ReportRowItem>Auto Score:</ReportRowItem>
                                    <ReportRowDetails>{jobDetails?.results?.overview?.score}</ReportRowDetails>
                                </ReportRow>
                                <ReportRow>
                                    <ReportRowItem>Auto detected:</ReportRowItem>
                                    {console.log(jobDetails)}
                                    <ReportRowDetails>{jobDetails?.results?.overview?.nonInclusivePhrases} words or phrases</ReportRowDetails>
                                </ReportRow>
                                <ReportRow>
                                    <ReportRowItem>Wordcount:</ReportRowItem>
                                    <ReportRowDetails>{jobDetails?.results?.wordcount}</ReportRowDetails>
                                </ReportRow>
                                <ReportRow>
                                    <ReportRowItem>Total Used:</ReportRowItem>
                                    <ReportRowDetails>{jobDetails?.results?.total_tokens} Tokens</ReportRowDetails>
                                </ReportRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        }




        { jobDetails?.results?.data &&
            <>
            <h1 id="data" className="pt-4">Non-inclusive words and phrases</h1>
            * This is the result of automated detection. There may be additional words and phrases that imply non-inclusivity
            <div key={"row_data"}>
            <div>
                <table className="table table-sm">
                <thead>
                    <tr>
                    <th scope="col">Count</th>
                    <th scope="col" style={{"textAlign":"center"}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({"text":"Auto detected phrase"})}
                        >
                        <span>Phrase < BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                    </th>
                    <th scope="col" style={{"textAlign":"center"}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({"text":"A level automatically assigned by the Language Model."})}
                        >
                        <span>Inclusivity Level < BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                    </th>
                    <th scope="col" style={{"textAlign":"center"}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({"text":"Auto assigned alternative text by the language model."})}
                        >
                        <span>Alternative < BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                    </th>
                    <th scope="col" style={{"textAlign":"center"}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({"text":"Auto assigned reason text by the Language Model"})}
                        >
                        <span>Reason < BiHelpCircle></BiHelpCircle></span>
                        </OverlayTrigger>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {jobDetails.results.data.map((link, index) => {
                    return <tr key={"link_"+index+1}>
                        <th scope="row">{link?.count}</th>
                        <td style={{"textAlign":"left"}}>{link?.phrase || "-"}</td>
                        <td style={{"textAlign":"center"}}>{link?.inclusivity_level || "-"}</td>
                        <td style={{"minWidth":"140px","maxWidth":"180px",
                                "whiteSpace": "nowrap",
                                "overflow": "hidden",
                                "textOverflow": "ellipsis"}}>
                            {link?.inclusive_alternative || "-"}
                        </td>
                        <td style={{"minWidth":"140px","maxWidth":"180px"}}>
                            {link?.reason || "-"}
                        </td>
                        

                    </tr>
                    })}
                </tbody>
                </table>
            </div>
            </div>
            
            </>
        }


        { jobDetails?.results?.text_slices &&
            <>
            <h1 id="fulltext" className="pt-4">Annotated Full Text</h1>
            <span style={{"whiteSpace":"pre-wrap"}}>
                { jobDetails?.results?.text_slices.map((slice, index) => {

                    if( slice?.alt ){
                        return <>
                                <span style={{"textDecoration":"line-through", "color":"red"}}>{slice?.input}</span> <span style={{"fontWeight":600, "color":"black"}}>{slice?.alt[0]?.inclusive_alternative}</span>
                        </>
                    } else {
                        return <>
                                {slice?.input}
                        </>
                    }
                    })
                }
            </span>
            </>
        }
        {console.log(jobDetails)}

      </div>
    </>
  );
}

export default DisplayPageInspectionInclusivityTextV1;
import React from 'react';
import {Elements, useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const StripeCheckout =({hidePaymentMethod, billingInfo}) =>{

    const app_url = process.env.REACT_APP_URL || "http://localhost:3000";


    // build the form
    const CheckoutForm = (params) => {
      const stripe = useStripe();
      const elements = useElements();
    
      const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        console.log(event)

        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: app_url + "/order-complete",
          },
        }).catch(()=>{

          console.log("... setup free trial")
          stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: app_url + "/order-complete",
            },
          });

        }
        );
    
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          // console.log(result.error.message);

          // try setup ;)
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      };
    

      return (
        <form onSubmit={handleSubmit}>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header">
              <h4 className="card-header-title">Payment method</h4>
            </div>
            <div className="card-body">
              <PaymentElement />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="ms-auto mb-10 w-100" style={{"textAlign":"right"}}>
            <button disabled={!stripe} className="btn btn-lg btn-primary w-100">Submit</button>
            </div>
          </div>
        </form>
      )
    };
    
    const options = {
        // passing the client secret obtained from the server
        clientSecret: billingInfo['clientSecret']
    };

    return (
      <div id="checkoutStepPayment" className="">
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm hidePaymentMethod={hidePaymentMethod}/>
        </Elements>
      </div>
    );
};


export default StripeCheckout
import React, { useEffect, useState } from "react";
import Layout from '../components/Layout';
import { Helmet } from "react-helmet"



import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../pages/authentication/firebase";
import axios from 'axios'


const Support = () => {
  const [user, loading, error] = useAuthState(auth);
  
  const [showComposer, setShowComposer] = useState(false);

  const toggleShowComposer = (e) => {
      setShowComposer(!showComposer)
  }
  const navigate = useNavigate();
  const [userEntity, setUserEntity] = useState({})

  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";


  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)
      }).catch(error => {
        console.log(error)
      });
      
    };
    
  }, [user, loading])


  var bgStyle = {
  }
  var hiddenField = {
      "display": "none"
  }

  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";



  return (
    <>
      <Layout pageTitle="Customer Support" hideTermsAccepted={true}>
      <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            /** This section is only needed once per page if manually copying **/
            if (typeof MauticSDKLoaded == 'undefined') {
                var MauticSDKLoaded = true;
                var head            = document.getElementsByTagName('head')[0];
                var script          = document.createElement('script');
                script.type         = 'text/javascript';
                script.src          = 'https://m.contentcurator.com/media/js/mautic-form.js?v7e950ac9';
                script.onload       = function() {
                    MauticSDK.onLoad();
                };
                head.appendChild(script);
                var MauticDomain = 'https://m.contentcurator.com';
                var MauticLang   = {
                    'submittingMessage': "Please wait..."
                }
            }else if (typeof MauticSDK != 'undefined') {
                MauticSDK.onLoad();
            }`
        }]}
        />

      <main id="content" role="main">
      <div className="position-relative bg-img-start" style={bgStyle}>
      <div className="container content-space-t-1">
        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <h1 style={{"fontSize":"xx-large"}}>Customer Support</h1>
          <p>We'd love to talk about how we can help you.</p>
        </div>

        <div className="row">

        <div className="col-md-12">
          <div className="container content-space-0 mb-10">
            <div className="mx-auto">
                <form autoComplete="false" role="form" method="post" action="https://m.contentcurator.com/form/submit?formId=2" id="mauticform_contactsupport" data-mautic-form="contactsupport" encType="multipart/form-data">
                    <div className="mauticform-error" id="mauticform_contactsupport_error"></div>
                    <div className="mauticform-message" id="mauticform_contactsupport_message"></div>
                    <div className="row">
                        <div className="col-sm-6">
                        <div className="mb-4 mauticform-row mauticform-text mauticform-field-1" id="mauticform_contactsupport_first_name">
                            <label className="form-label" id="mauticform_label_contactsupport_first_name" htmlFor="mauticform_input_contactsupport_first_name">First name</label>
                            <input type="text" className="form-control form-control-lg" required name="mauticform[first_name]" id="mauticform_input_contactsupport_first_name" defaultValue={user?.displayName? user.displayName.split(" ")[0]: null} placeholder="First name" aria-label="First name"/>
                            <span className="mauticform-errormsg" style={hiddenField}></span>
                        </div>
                        </div>

                        <div className="col-sm-6">
                        <div className="mb-4 mauticform-row mauticform-text mauticform-field-2" id="mauticform_contactsupport_first_name">
                            <label className="form-label" id="mauticform_label_contactsupport_last_name" htmlFor="mauticform_input_contactsupport_last_name">Last name</label>
                            <input required type="text" className="form-control form-control-lg" name="mauticform[last_name]" id="mauticform_input_contactsupport_last_name" defaultValue={(user?.displayName && user.displayName.split(" ").length>1)? user.displayName.split(" ")[1]: null} placeholder="last name" aria-label="last name"/>
                            <span className="mauticform-errormsg" style={hiddenField}></span>
                        </div>
                        </div>

                    </div>

                    <div className="mb-4">
                        <label className="form-label" htmlFor="hireUsFormWorkEmail">Email address</label>
                        <input required type="email" className="form-control form-control-lg" name="mauticform[email]" id="hireUsFormWorkEmail" defaultValue={user?.email} placeholder="email@site.com" aria-label="email@site.com"/>
                    </div>
                    <div className="mb-4">
                        <label className="form-label" htmlFor="hireUsFormWorkPhone">Phone</label>
                        <input type="phone" className="form-control form-control-lg" name="mauticform[phone]" id="hireUsFormWorkPhone" defaultValue={user?.phoneNumber} placeholder="(000) 000-0000" aria-label="(000) 000-0000"/>
                    </div>

                    <div id="mauticform_label_contactsupport_details" className="mb-4 mauticform-row mauticform-text mauticform-field-6">
                        <label className="form-label" htmlFor="mauticform_input_contactsupport_details">Details</label>
                        <textarea required className="form-control form-control-lg mauticform-textarea" name="mauticform[details]" id="mauticform_input_contactsupport_details" placeholder="Tell us more details..." aria-label="Tell us more details..." rows="6"></textarea>
                        <span className="mauticform-errormsg" style={hiddenField}></span>
                    </div>


                    <div id="mauticform_contactsupport_submit" className="d-grid mb-2 mauticform-row mauticform-button-wrapper mauticform-field-8">
                        <button type="submit" name="mauticform[submit]" id="mauticform_input_contactsupport_submit" className="mauticform-button btn btn-primary btn-lg">Submit</button>
                    </div>

                    <div className="text-center">
                        <span className="form-text">We'll get back to you ASAP but not more than 1-2 business days.</span>
                    </div>

                    <input hidden type="hidden" className="form-control form-control-lg" name="mauticform[customer_id]" id="customer_id" value={user?.uid}/>
                    <input type="hidden" name="mauticform[formId]" id="mauticform_contactsupport_id" value="2"/>
                    <input type="hidden" name="mauticform[return]" id="mauticform_contactsupport_return"/>
                    <input type="hidden" name="mauticform[formName]" id="mauticform_contactsupport_name" value="contactsupport"/>
                </form>
          </div>
        </div>
      </div>
          <div className="col-md-4 d-none">
            <div className="mb-4">
              <a className="card text-center h-100" href={public_url+"/guides/"}>
                <div className="card-body">
                  <div className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#035A4B"></path>
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.591 13.209V14.868H11.851C14.392 14.868 16.387 12.915 16.387 10.395C16.387 7.875 14.392 5.922 11.851 5.922C9.31 5.922 7.336 7.875 7.336 10.395H9.121C9.121 8.799 10.234 7.581 11.851 7.581C13.447 7.581 14.602 8.799 14.602 10.395C14.602 11.991 13.447 13.209 11.851 13.209H10.591ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill="#035A4B"></path>
                    </svg>

                  </div>

                  <h4 className="card-title">Knowledgebase</h4>
                  <p className="card-text text-body">We're here to help with any questions or code.</p>
                </div>

                <div className="card-footer p-2">
                  <span className="card-link">Browse Guides <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>

            <div className="mb-4">
              <a className="card text-center h-100" href={public_url+"/sales/"}>
                <div className="card-body">
                  <div className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                    </svg>

                  </div>

                  <h4 className="card-title">Hire Us</h4>
                  <p className="card-text text-body">Need a custom solution? Lets start a conversation.</p>
                </div>

                <div className="card-footer p-2">
                  <span className="card-link">Contact Sales <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>

            <div className="">
              <a className="card text-center h-100" href={public_url+"/api/"}>
                <div className="card-body">
                  <div className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5688 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5688 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="#035A4B"></path>
                    <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="#035A4B"></path>
                    </svg>

                  </div>

                  <h4 className="card-title">Developer APIs</h4>
                  <p className="card-text text-body">Check out our programming interface quickstart guide.</p>
                </div>

                <div className="card-footer p-2">
                  <span className="card-link">API Quickstart <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
          </div>

  
          </div>
      </div>
    </div>
    </main>
      </Layout>
    </>
  );
};
  
export default Support;
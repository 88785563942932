
import React from 'react';
import Layout from "../../components/Layout";
import Navbar from '../../components/Navbar';
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../pages/authentication/firebase";

const AccountSettings = () => {
  const [user, loading, error] = useAuthState(auth);

  return (
    <>
    <Layout fullScreen={true}>
        <div className="content container-fluid" style={{"overflowY":"auto"}}>
        <div className="page-header">
            <div className="row align-items-end">
                <div className="col-sm mb-2 mb-sm-0">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-no-gutter">
                        <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/account/">Account</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                    </nav>

                    <h1 className="page-header-title">Settings</h1>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-3">
            <div className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5">
                <div className="d-grid">
                <button type="button" className="navbar-toggler btn btn-white mb-3" data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenu" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenu">
                    <span className="d-flex justify-content-between align-items-center">
                    <span className="text-dark">Menu</span>

                    <span className="navbar-toggler-default">
                        <i className="bi-list"></i>
                    </span>

                    <span className="navbar-toggler-toggled">
                        <i className="bi-x"></i>
                    </span>
                    </span>
                </button>
                </div>

                <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
                <ul id="navbarSettings" className="js-sticky-block js-scrollspy card card-navbar-nav nav nav-tabs nav-lg nav-vertical" data-hs-sticky-block-options='{
                        "parentSelector": "#navbarVerticalNavMenu",
                        "targetSelector": "#header",
                        "breakpoint": "lg",
                        "startPoint": "#navbarVerticalNavMenu",
                        "endPoint": "#stickyBlockEndPoint",
                        "stickyOffsetTop": 20
                    }'>
                    <li className="nav-item">
                    <a className="nav-link active" href="#content">
                        <i className="bi-person nav-icon"></i> Basic information
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#deleteAccountSection">
                        <i className="bi-trash nav-icon"></i> Delete account
                    </a>
                    </li>
                </ul>
                </div>
            </div>
            </div>

            <div className="col-lg-9">
            <div className="d-grid gap-3 gap-lg-5">
                
                <div className="card">
                <div className="card-header">
                    <h2 className="card-title h4">Basic information</h2>
                </div>

                <div className="card-body">
                    <form>
                    <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">Full name <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Displayed on public forums, such as Front."></i></label>

                        <div className="col-sm-9">
                        <div className="input-group input-group-sm-vertical">
                            <input type="text" className="form-control" name="firstName" id="firstNameLabel" placeholder="Your first name" aria-label="Your first name" value="Mark"/>
                            <input type="text" className="form-control" name="lastName" id="lastNameLabel" placeholder="Your last name" aria-label="Your last name" value="Williams"/>
                        </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label">Email</label>

                        <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="mark@site.com"/>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <label htmlFor="phoneLabel" className="col-sm-3 col-form-label form-label">Phone <span className="form-label-secondary">(Optional)</span></label>

                        <div className="col-sm-9">
                        <input type="text" className="js-input-mask form-control" name="phone" id="phoneLabel" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" value="+1 (609) 972-22-22" data-hs-mask-options='{
                                "mask": "+0(000)000-00-00"
                                }'/>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                    </form>
                </div>
                </div>

                

                <div id="deleteAccountSection" className="card">
                <div className="card-header">
                    <h4 className="card-title">Delete your account</h4>
                </div>

                <div className="card-body">
                    <p className="card-text">When you delete your account, you lose access to Front account services, and we permanently delete your personal data. You can cancel the deletion for 14 days.</p>

                    <div className="mb-4">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="deleteAccountCheckbox"/>
                        <label className="form-check-label" htmlFor="deleteAccountCheckbox">
                        Confirm that I want to delete my account.
                        </label>
                    </div>
                    </div>

                    <div className="d-flex justify-content-end gap-3">
                    <a className="btn btn-white" href="#">Learn more</a>
                    <button type="submit" className="btn btn-danger">Delete</button>
                    </div>
                </div>
                </div>
            </div>

            <div id="stickyBlockEndPoint"></div>
            </div>
        </div>
        </div>
    </Layout>
    </>
  );
};
  
export default AccountSettings;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, logout, resendEmailtoUser } from "../authentication/firebase";
import axios from 'axios'
import { io } from "socket.io-client";

import Layout from "../../components/Layout";
import WebSocketCall from "../../components/WebSocketCall";
import Spinner from 'react-bootstrap/Spinner';

import { BiHelpCircle, BiData } from "react-icons/bi";
import Helmet from 'react-helmet';

import SimpleNavbar from '../../components/Navbar/simple';




const AnalysisPreview =({analysis_key, job_id}) =>{

  // settings for this analysis
  const optionSettings = {
    'related':{
      'multiplePerKeyword': 0.1
    },
    'intent':{
      'multiplePerKeyword': 0.1
    },
    'cache':{
      'multiplePerKeyword': 1
    },
    'topicalmap':{
      'multiplePerKeyword': 10
    },
    'multilevel':{
      'multiplePerKeyword': 1
    },
    'aggregate_page':{
      'multiplePerKeyword': 0
    },
    'competitoranalysis':{
      'multiplePerKeyword': 0
    }
  }


  const navigate = useNavigate();

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [userEntity, setUserEntity] = useState({})
  const [accessToken, setAccessToken] = useState('');

  const [jobDetails, setJobDetails] = useState({})
  const [analysisResponse, setAnalysisResponse] = useState(false);

  const [templateVersion, setTemplateVersion] = useState(null)
  const [reportParts, setReportParts] = useState([])
  const [socketInstance, setSocketInstance] = useState("");
  const [loadingSocket, setLoadingSocket] = useState(true);

  // default billing
  const [pricePer1000Credits, setPricePer1000Credits] = useState(4.98);
  const [minCreditPurchase, setMinCreditPurchase] = useState(1);
  const creditsPerKeyword = 1.0
  const creditsPerKeyword_relatedandpaa = optionSettings['related']['multiplePerKeyword']
  const creditsPerKeyword_serpintent = optionSettings['intent']['multiplePerKeyword']
  const creditsPerKeyword_cached = optionSettings['cache']['multiplePerKeyword']
  const creditsPerKeyword_topicalmap = optionSettings['topicalmap']['multiplePerKeyword']
  const creditsPerKeyword_multilevel = optionSettings['multilevel']['multiplePerKeyword']
  const creditsPerKeyword_aggregate_page = optionSettings['aggregate_page']['multiplePerKeyword']

  const numberOfReports = 1.0
  const creditsPerReportIfNotPrepaid = 200
  const freeCreditsIncluded = 500
  const minPurchaseRequired = 4000

  // final purchase details
  const [onetimeCreditsToPurchase, setOnetimeCreditsToPurchase] = useState(minCreditPurchase);
  const [monthlyMembershipPrice, setMonthlyMembershipPrice] = useState(0);

  const [creditsForReport, setCreditsForReport] = useState(0); // often pre-paid but sometimes not

  const [n_keywords_cached, setN_keywords_cached] = useState(0);

  const [activeAddons, setActiveAddons] = useState({
    'related':0,
    'intent':0,
    'cache':0,
    'multilevel':1,
    'aggregate_pages':0,
    'topicalmap':0,
    'competitoranalysis':0
  }); // toggles for add-ons
  const [creditsForOptionalConfiguration, setCreditsForOptionalConfiguration] = useState(0);
  
  // membership options
  const [currentBalance, setCurrentBalance] = useState(0);
  const [onetimeCreditDiscount, setOntimeCreditDiscount] = useState(0);
  var totalCredits = 0

  // credit purchase options
  const [reportCreditsExpected, setReportCreditsExpected] = useState(numberOfReports)
  const [totalCreditsNeeded, setTotalCreditsNeeded] = useState(0); // how many credits does this report take?
  const [essentialQuantity, setEssentialQuantity] = useState(minCreditPurchase);
  const [bulkQuantity, setBulkQuantity] = useState(100000);
  const [bulk_8kQuantity, setBulk_8kQuantity] = useState(8000);

  const creditOptions = [
    {
      'name': 'essential',
    },
    {
      'name': 'bulk_8k',
    },
    {
      'name': 'bulk_100k',
    },
  ]
  // convert to a dict
  var creditOptionsDict = {};
  for( const x of creditOptions){
    creditOptionsDict[x['name']] = x
  }


  // get the n_keywords_cached when the 
  function getNKeywordsCached(value) {
    return "n_keywords_cached" in value ? value["n_keywords_cached"] : 0;
  }

  // for button submissions
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [warn, setWarn] = useState(null);

  const handleButtonClick = async event => {

    // don't allow multiple clicks or click while loading
    setIsLoading(true);
    event.currentTarget.disabled = true;

    // confirm and submit the next action on the analysis
    try {
      axios.post(api_url + '/v1/analysis/'+analysis_key+"/results/run", {
        'report_credits_expected': reportCreditsExpected, 
        'gas_credits_expected': totalCreditsNeeded,
        'addons': activeAddons
      }, {headers: {
        'Authorization': accessToken,
        'Refreshtoken': user?.refreshToken
      }})
        .then((response)=> {
          setAnalysisResponse(response)
          if(response?.data?.id){
            navigate("/analysis/"+analysis_key+"/reports/"+response.data.id)
          }
        }).catch ((error) => {
          setErr(error.message)
        });
    } catch (err) {
      setErr(err.message);
    } 
  }

  var currentUrl = "/analysis/"+analysis_key+"/preview/"+job_id+"/"
  var autoLaunch = analysis_key

  // create the job and process it
  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";
  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  const redirectUrl = "?restart=true&url="+encodeURIComponent(window.location.pathname+window.location.search)

  const REACT_APP_API_SOCKET_DOMAIN = process.env.REACT_APP_API_SOCKET_DOMAIN || "127.0.0.1:5000";

  const subscriptionOptions = [
    {
      'name': 'free',
      'title': "Pay as you Go Plan",
      'discountOnCredits': 0,
      'monthlyPrice': 0
    },
    {
      'name': 'basic_starter',
      'title': "Basic",
      'discountOnCredits': 0,
      'monthlyPrice': 29
    },
    {
      'name': 'pro_starter',
      'title': "Profesional",
      'discountOnCredits': 0.5,
      'monthlyPrice': 49
    },
    {
      'name': 'team_starter',
      'title': "Business",
      'discountOnCredits': 0.624,
      'monthlyPrice': 299
    },
    {
      'name': 'lite_starter',
      'title': "Lite Membership",
      'discountOnCredits': 0,
      'monthlyPrice': 9
    }
  ]

  var subscriptionOptionsDict = {};
  for( const x of subscriptionOptions){
    subscriptionOptionsDict[x['name']] = x
  }

  // todo get this from user
  const [currentSubscription, setCurrentSubscription] = useState('free');


  var defaultSubscriptionOption = {"name":currentSubscription}
  if( currentSubscription == 'free'){
    var defaultSubscriptionOption = subscriptionOptions[0]
  }
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState(currentSubscription)


  const defaultCreditOption = 'essential'
  const [selectedCreditsOption, setSelectedCreditsOption] = useState(defaultCreditOption)




  // on load
  // - default to the currently active subscription
  // - remove select button from that option

  // on click 
  const chooseSubscriptionOption = (plan) => {
    if(plan != selectedSubscriptionOption ){
      setSelectedSubscriptionOption(plan)
      setMonthlyMembershipPrice(subscriptionOptionsDict[plan]['monthlyPrice'])
      setOntimeCreditDiscount(subscriptionOptionsDict[plan]['discountOnCredits'])
    }
  }


  // on click options
  const chooseOption = (option_id) => {

    let copyOfObject = { ...activeAddons }

    // toggle the add-on
    if(option_id){
      if( copyOfObject[option_id] ) {
        copyOfObject[option_id] = 0
      } else {
        copyOfObject[option_id] = 1
      }
      setActiveAddons( shopCart => ({...copyOfObject}))
    }
    
    // update the count
    var options = Object.keys(copyOfObject)
    var total = 0
    for( const option in options){
      total = total + copyOfObject[options[option]] * jobDetails.results?.summary?.n_keywords * optionSettings[options[option]]['multiplePerKeyword']
    }
    setCreditsForOptionalConfiguration(
      Math.floor(total)
    )

    // total credits
    totalCredits = creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached) + Math.floor(total) + creditsForReport - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded)
    // add number of credits needed
    setTotalCreditsNeeded( totalCredits  )
    
    setEssentialQuantity( totalCredits - currentBalance )
  }

  // on click 
  const chooseCreditsOption = (creditoption_id) => {
    if(creditoption_id != selectedCreditsOption ){
      setSelectedCreditsOption(creditoption_id)
      
      // set the number of credits selected
      if(creditoption_id == 'essential'){
        // set the onetimeCreditPrice
        setOnetimeCreditsToPurchase(essentialQuantity - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded)+freeCreditsIncluded)
      }
      if(creditoption_id == 'bulk_8k'){
        // set the onetimeCreditPrice
        setOnetimeCreditsToPurchase(bulk_8kQuantity)
      }
      if(creditoption_id == 'bulk_100k'){
        // set the onetimeCreditPrice
        setOnetimeCreditsToPurchase(bulkQuantity)
      }
    }
  }
  // - change the card to 'checked'
  // - uncheck the others

  // on submit
  // - send selected option to next page /subscribe
  const submitForm = (selectedPlan) => {
    if(selectedPlan){
      setSelectedSubscriptionOption(selectedPlan)
    }
    navigate("/subscribe?plan="+selectedSubscriptionOption['name'])
  }

  // userEntity

  useEffect(() => {
    if(loading) return;

    // set current membership plan using subscription membership_id
    var membership_id = userEntity?.access?.subscription?.membership_id
    if( membership_id ){
      setCurrentSubscription(membership_id)
      setSelectedSubscriptionOption(null)
      setOntimeCreditDiscount(subscriptionOptionsDict[membership_id]['discountOnCredits'])
    }

    // set gas balance
    if( userEntity?.access?.credits?.gas){
      setCurrentBalance(userEntity?.access?.credits?.gas)
      setEssentialQuantity( totalCreditsNeeded - currentBalance )
    }
    console.log("userEntity", user)
    if( true ) {
      if( userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription < reportCreditsExpected ){
        // Not enough pre-paid reports for this report
        setCreditsForReport( creditsPerReportIfNotPrepaid )
        // no reports will be used now
        setReportCreditsExpected( 0 )
      }
    }
    
  }, [user, userEntity, loading, reportCreditsExpected]);

  useEffect(() => {
    // redirect if no credits and anonymous
    if( user?.isAnonymous && userEntity[process.env.ACCESS_FIELD] && (userEntity[process.env.ACCESS_FIELD].credits?.available_anytime + userEntity[process.env.ACCESS_FIELD].credits?.available_subscription)==0){
      navigate("/register/?restart=true&url="+encodeURIComponent(window.location.pathname+window.location.search))
    }

    // warn if email not verified
    if( user && userEntity && user?.email && user?.emailVerified == false){
      setWarn("Please verify your account by clicking the link in your email.")
    }
  }, [user, userEntity]);


  // set total credits needed
  
  useEffect(() => {
    if(loading) return;

    // total credits
    totalCredits = creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached) + creditsForOptionalConfiguration + creditsForReport - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded)
    // add number of credits needed
    setTotalCreditsNeeded( totalCredits )

    // set the minimum credit purchase
    if( totalCredits>minCreditPurchase ){
      setEssentialQuantity( Math.max( minCreditPurchase, totalCredits - currentBalance) )
    }
  }, [jobDetails, userEntity, creditsForReport, minCreditPurchase, loading]);


  // user
  useEffect(() => {
    if (loading) return;
    if (!user) {
      // get anonymous user if not already logged in
      //createAnonymousUser()
      redirectUrl('/login')
    } else {
      setAccessToken(user.accessToken)

      // get the userEntity
      axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)

      }).catch(error => {
        console.log(error)
        setWarn("PLEASE REFRESH: Your session has expired or our backend services are currently busy.")
      });
      
    }
  }, [user, loading]);

  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setJobDetails(response.data)
        // add number of credits needed
        if( response?.data?.results?.summary?.n_keywords ){

          // CHOOSE OPTION VALUES
          let copyOfObject = { ...activeAddons }
          // update the count
          var options = Object.keys(copyOfObject)
          var total = 0
          for( const option in options){
            total = total + copyOfObject[options[option]] * response.data.results?.summary?.n_keywords * optionSettings[options[option]]['multiplePerKeyword']
          }
          setCreditsForOptionalConfiguration(
            Math.floor(total)
          )


          // total credits
          totalCredits = creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached) + creditsForOptionalConfiguration

          // set credits needed
          setTotalCreditsNeeded( totalCredits + creditsForReport - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded) )

          // set the minimum credit purchase
          if( response?.data?.results?.summary?.n_keywords>minCreditPurchase ){
            setEssentialQuantity( Math.max( minCreditPurchase, response?.data?.results?.summary?.n_keywords-currentBalance-freeCreditsIncluded) )
            //setOnetimeCreditsToPurchase(response.data?.results?.summary?.n_keywords)
          }
          

        }
      }).catch(error => {
        console.log(error)
      });

    }

    // connect to socket with room
    const socket = io(REACT_APP_API_SOCKET_DOMAIN +"/", {
      transports: ["websocket"],
      cors: {
        origin: api_url+"/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (data) => {
      socket.emit("join",{"room":"room_job_"+job_id})
    });

    setLoadingSocket(false);


  }, [user, loading])


  const updateJobStatus = (status) => {
    // update the status
    var newJobDetails = jobDetails
    newJobDetails['status'] = status
    setJobDetails(newJobDetails)
  }


  useEffect(() => {
    if (loadingSocket) return;
    if (socketInstance) {
    
    // process status messages
    socketInstance.on("status", (data) => {
      if( data?.value =='Complete' || data?.value =='Failed' ){
        // console.log("Complete refresh")
        // re-pull everything
        axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setJobDetails(response.data)
          // add number of credits needed
          if( response.data?.results?.summary?.n_keywords ){

            // total credits
            totalCredits = creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached) + creditsForOptionalConfiguration

            // set credits needed
            setTotalCreditsNeeded( totalCredits + creditsForReport - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded) )
            
            if( response.data?.results?.summary?.n_keywords>minCreditPurchase){
              setEssentialQuantity( Math.max( minCreditPurchase, totalCredits + creditsForReport - Math.min(creditsPerKeyword * (jobDetails.results?.summary?.n_keywords-n_keywords_cached), freeCreditsIncluded)) )
              //setOnetimeCreditsToPurchase(response.data?.results?.summary?.n_keywords)
            }
          }
        }).catch(error => {
          console.log(error)
        });
      } else {
        updateJobStatus(data.value)
      }
    });


    socketInstance.on("results", (data) => {
      // innitializie results if doesn't exist
      var newJobDetailsResults = jobDetails?.results? jobDetails.results : {};
      for (var item in data?.data) {
        newJobDetailsResults[item] = data.data[item]
      }
      var newJobDetails = jobDetails;
      newJobDetails['results'] = newJobDetailsResults
      setJobDetails( newJobDetails )

    });


      // if error
      if ( jobDetails?.results?.error ){

        // redirect to registration if user is annonymous

        if ( userEntity?.isAnonymous ){
          
        }
        var errorMsg = "While running the job the following error occurred: '"+jobDetails?.results?.error+"'"
        setErr(errorMsg);
      }

    // put this into reactive element so it updates as things are coming in!
    if(jobDetails?.template?.parts){
      setReportParts( jobDetails?.template?.parts )
    } else if(jobDetails.results) {
      setReportParts( [{
        "name":"Raw Results",
        "id":"raw-results",
        "displayTemplate":"raw-results"
      }] )
    }

    if( jobDetails?.results?.summary ){
      setN_keywords_cached( getNKeywordsCached(jobDetails.results?.summary) )
    }
    setTemplateVersion(jobDetails?.template?.version)
  }
  }, [socketInstance, loadingSocket, jobDetails]);



  useEffect(()=>{
    // if user already has enough credits then override everything
    if( currentBalance >= totalCreditsNeeded ){
      setOnetimeCreditsToPurchase(0)
    } else {
      
      setOnetimeCreditsToPurchase(Math.max( minCreditPurchase, totalCreditsNeeded-currentBalance))
    }
  }, [currentBalance, totalCreditsNeeded])






  const [reportDetails, setReportDetails] = useState({
    'name': "Confirm Preview",
    'pages': [
      {
        "name": "Inputs"
      },
      {
        "name": "Confirm Preview"
      }
    ],
    "stepNumber": 2
  });
  console.log("jobdetails", jobDetails)

  // comes from this analysis & job
  var availableReports = {
    "serp-similarity-preview": {
      "name": "Bulk Serp Similarity",
      "pages": [
        {
          "name": "Input Keywords"
        },
        {
          "name": "Confirm Preview"
        }
      ],
      "stepNumber": 2,
      "addons": ["sdf"]
    },
    "keyword-clustering-preview": {
      "name": "Keyword Clustering",
      "pages": [
        {
          "name": "Input Keywords"
        },
        {
          "name": "Confirm Preview"
        }
      ],
      "stepNumber": 2,
      "addons": ["sdf"]
    },
    "bulk-keyword-expansion-preview": {
      "name": "Bulk Keyword Expansion",
      "pages": [
        {
          "name": "Input Keywords"
        },
        {
          "name": "Confirm Preview"
        }
      ],
      "stepNumber": 2,
      "addons": []
    }
  }



  useEffect(()=>{
    // turn off socket if completed
    if( jobDetails['status']=="Completed" || jobDetails['status']=="Failed" ){
      setLoadingSocket(true)
    }

    // determine the report name and steps
    var report_type = jobDetails?.inputs?.report_type
    setReportDetails( availableReports[ report_type ] )
  }, [jobDetails])
  

  var style_icon = {
    "marginBottom": "45px",
    "height": "30px"
  }

  // for top menu inititizlie
  var pageNumber = 0;
  var tool_id = jobDetails?.inputs?.report_type.replace("-preview","");

  const selectedSubscriptionOption_url = () => {
    if( selectedSubscriptionOption ){
      return("plan="+selectedSubscriptionOption+"&")
    } else {
      return("")
    }
  }


    // resend email
    const resendEmail = async event => {
      try {
        resendEmailtoUser()
      } catch (err) {
        console.log("error")
      }
    }

  return (
    <>

      <Helmet>
        <script src="/assets/vendor/hs-toggle-switch/dist/hs-toggle-switch.min.js"></script>
        <script src="/assets/vendor/tom-select/dist/js/tom-select.complete.min.js"></script>
      </Helmet>
      <Layout pageTitle={"Step "+reportDetails?.stepNumber+" - New "} fullScreen={false} hideNavbar={true} allowAnonymous={false} hideTermsAccepted={true}>
        <div className="row justify-content-lg-center">
          <main style={{"maxWidth": "900px"}}>
              <SimpleNavbar user={user} userEntity={userEntity}/>
                  { err &&
                    <div className="alert alert-soft-danger mb-5" role="alert">
                    <b>Error</b>: {err} Please contact customer service for additional details if it continues happening.
                  </div>
                  }
                  { warn &&
                    <div className="alert alert-soft-warning mb-7" role="alert">
                      <span style={{"color":"gray"}}><span style={{"color":"gray"}}>{warn}</span></span>
                    </div>
                  }
                  { warn && false && 
                    <div className="alert alert-soft-warning mb-7" role="alert">
                      <a href="#" onClick={resendEmail}>Resend Verification Email</a>
                    </div>
                  }


              <div className="mb-7">



                  
                <form action={"/tools/"+tool_id+"/"} method="GET">
                <div className="d-grid gap-3 gap-lg-5">

                  <div>
                    <h1>{reportDetails?.name}</h1>
                  </div>

                  <div className="w-md-75 mx-md-auto">
                    <ul id="checkoutStepFormProgress" className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-3 mb-sm-7">


                    {reportDetails?.pages.map( (inputData, i) => {
                        if( inputData['name'] ){
                          pageNumber = pageNumber+1
                          return( 
                            <li className={ reportDetails?.stepNumber >= pageNumber? "step-item active focus" : "step-item"}>
                            <span className="step-content-wrapper">
                                <span className="step-icon step-icon-soft-dark">{pageNumber}</span>
                                <div className="step-content">
                                  <span className="step-title">{inputData['name']}</span>
                                </div>
                              </span>
                            </li>
                          )}
                        } )
                      }

                      <li className="step-item">
                        <span className="step-content-wrapper">
                          <span className="step-icon step-icon-soft-dark">{pageNumber+1}</span>
                          <div className="step-content">
                            <span className="step-title">View Results</span>
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>


                  { false && user && user?.isAnonymous && !err && userEntity && userEntity?.access && (userEntity?.access.credits?.available_anytime + userEntity?.access.credits?.available_subscription)==0 &&
                    <div className="alert alert-soft-warning" role="alert">
                      <p className="" style={{"color":"black"}}><b>You are out of credits.</b> Earn 5 MORE free jobs per month when you <Link to="/register/">Register</Link>. See <Link to="/pricing/">pricing</Link> for more options.
                      </p>
                    </div>
                  }

                <div className="">
                  <div className="row align-items-end">
                    <div className="col-sm text-end">
                    { 
                    // TOP BUY NOW BUTTON
                    jobDetails && !err && jobDetails?.status == "Complete" && 
                    <>  

                      { user ?
                        <>{ // CASE 1: Purchasing credits 
                        
                          onetimeCreditsToPurchase>0? <>
                              Total: ${(Math.round((onetimeCreditsToPurchase*pricePer1000Credits/1000*(1-onetimeCreditDiscount))*100)/100).toFixed(2)}{ totalCreditsNeeded<minPurchaseRequired && "*" }
                              {monthlyMembershipPrice>0 && <> + ${monthlyMembershipPrice} monthly</>}
                              &nbsp; <Link to={"/checkout/?"+selectedSubscriptionOption_url()+"credits="+onetimeCreditsToPurchase+"&edit="+currentUrl+"&launch="+autoLaunch} className="btn btn-primary float-right w-150 w-sm-auto w-lg-125" >Confirm & Pay Now</Link>
                          </>:
                          // CASE 2: Using account credits only
                          <>
                            Total: {totalCreditsNeeded} KW Credits{reportCreditsExpected>0 ? <> + {reportCreditsExpected} Job</> : <></>}
                            

                          {currentSubscription=='free' ? <>
                                  <button type="button" className="btn btn-primary float-right w-150 w-sm-auto w-lg-125 ms-2" 
                                    onClick={handleButtonClick} disabled={isLoading}>Continue</button>
                          </> : <>
                                  <button type="button" className="btn btn-primary float-right w-150 w-sm-auto w-lg-125 ms-2" 
                                    onClick={handleButtonClick} disabled={isLoading}>Confirm & View Results</button>
                          </>
                          }
                          </>
                        }</>
                      : 
                      // signup for free trial with redirect to this page
                      <>
                        <div className="col-auto align-baseline">
                          <Link to={"/register/?url=/analysis/"+analysis_key+"/preview/"+job_id} className="btn btn-primary" disabled>Sign-up {totalCreditsNeeded>0? "to See Options" : "for Free Trial"}</Link>
                        </div>
                      </>
                      }
                      </>
                    // TOP BUY NOW BUTTON - END
                    }
                    </div>
                  </div>
                </div>
                
                <div className="card" style={{"background-color":"#F4F4F4"}}>
                  <div className="card-body">
                      <div className="row align-items-center flex-grow-1 mb-2">
                        <div className="col">
                          { jobDetails && !err && jobDetails?.status == "Complete"? 

                          <div className="row align-items-top flex-grow-1 mb-2">
                            <div className="col">

                              <div className="text-center mb-3 fw-bolder display-5">
                                {jobDetails?.results?.summary?.n_keywords} Total Distinct Keywords
                              </div>
                              <div className="text-center font-weight-bold mb-3" style={{"color":"purple"}}>
                                    First {freeCreditsIncluded} Keywords FREE
                              </div>
                              <div className="div table-responsive">
                                <table className="table table-nowrap table-striped table-bordered" style={{"minWidth":"250px"}}>
                                  <thead>
                                    <tr>
                                      <th scope="col">Keyword</th>
                                      <th scope="col" className="text-end">Volume</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {jobDetails?.results?.data.map( (rows, i) => {
                                          return( 
                                            <>
                                            <tr>
                                              <td>
                                                <div style={{
                                                  "overflow": "hidden",
                                                  "whiteSpace": "nowrap",
                                                  "textOverflow": "ellipsis",
                                                  "maxWidth": "250px"}}>
                                                  {rows.cached_at? <span title="Cached"><BiData/> </span>: <></>}{rows.keyword}
                                                </div>
                                              </td>
                                              <td className="text-end">{rows.volume}</td>
                                            </tr>
                                            { i == 4 && jobDetails?.results?.summary?.n_keywords>5 &&
                                            <tr>
                                              <td>...</td>
                                              <td className="text-end">...</td>
                                            </tr>
                                            }
                                            </>
                                          )
                                        } 
                                      )
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col">
                              <div className="row justify-content-end mb-3">
                                <div className="col-md-top mt-7">
                                  <dl className="row text-end">
                                    <dt className="col-9">Language:</dt>
                                    <dd className="col-3 text-uppercase">{jobDetails.inputs?.hl? jobDetails.inputs?.hl : "en"}</dd>
                                    <dt className="col-9">Location:</dt>
                                    <dd className="col-3 text-uppercase">{jobDetails.inputs?.gl? jobDetails.inputs?.gl : "us"}</dd>
                                    {jobDetails.inputs?.domain_to_track && <>
                                    <dt className="col-9">Domain to Track:</dt>
                                    <dd className=" text-lowercase">{jobDetails.inputs?.domain_to_track}</dd>
                                    
                                    </>}

                                      {jobDetails.results?.summary?.n_duplicates >0 && <>
                                      <dt className="col-9">Duplicate Keywords Removed:</dt>
                                      <dd className="col-3">{jobDetails.results?.summary?.n_duplicates}</dd></>
                                      }
                                  </dl>

                                  { false && reportDetails?.addons && reportDetails.addons.length > 0 &&
                                  <>
                                  <hr className="mt-3"></hr>
                                    <p><b>OPTIONAL ADD-ONS</b></p>
                                  <dl className="row text-end">

                                    <dt className="col-1"><input type="checkbox" id="multilevel" name="multilevel" selected
                                      onChange={() => chooseOption('multilevel')} checked={activeAddons && activeAddons.multilevel===1}/></dt>
                                    <dt className="col-8"><label htmlFor="multilevel">Include Category and Hub (1x):</label></dt>
                                    <dd className="col-3">{activeAddons.multilevel? 
                                      <>{Math.round(creditsPerKeyword_multilevel * jobDetails.results?.summary?.n_keywords).toFixed(0)}</> :
                                      <span style={{"color":"lightgray"}}>{Math.round(creditsPerKeyword_multilevel * jobDetails.results?.summary?.n_keywords).toFixed(0)}</span>}
                                    </dd>


                                    <dt className="col-1"><input type="checkbox" id="aggregate_page" name="aggregate_page"
                                      onChange={() => chooseOption('aggregate_page')} checked={activeAddons && activeAddons.aggregate_page===1}/></dt>
                                    <dt className="col-8"><label htmlFor="aggregate_page">Aggregate to Page (0.1x):</label></dt>
                                    <dd className="col-3">{activeAddons.aggregate_page? 
                                      <>{Math.round(creditsPerKeyword_aggregate_page * jobDetails.results?.summary?.n_keywords).toFixed(0)}</> :
                                      <span style={{"color":"lightgray"}}>{Math.round(creditsPerKeyword_aggregate_page * jobDetails.results?.summary?.n_keywords).toFixed(0)}</span>}
                                    </dd>


                                    <dt className="col-1"><input type="checkbox" id="related" name="related"
                                      onChange={() => chooseOption('related')} checked={activeAddons && activeAddons.related===1}/></dt>
                                    <dt className="col-8"><label htmlFor="related">Related Queries & PAA (0.1x):</label></dt>
                                    <dd className="col-3">{activeAddons.related? 
                                      <>{Math.round(creditsPerKeyword_relatedandpaa * jobDetails.results?.summary?.n_keywords).toFixed(0)}</> :
                                      <span style={{"color":"lightgray"}}>{Math.round(creditsPerKeyword_relatedandpaa * jobDetails.results?.summary?.n_keywords).toFixed(0)}</span>}
                                    </dd>

                                    <dt className="col-1"><input type="checkbox" id="intent" name="intent" value={activeAddons.intent}
                                      onChange={() => chooseOption('intent')} checked={activeAddons.intent===1}/></dt>
                                    <dt className="col-8"><label htmlFor="intent">SERP Features (0.1x):</label></dt>
                                    <dd className="col-3">{activeAddons.intent? 
                                      <>{Math.round(creditsPerKeyword_serpintent * jobDetails.results?.summary?.n_keywords).toFixed(0)}</> :
                                      <span style={{"color":"lightgray"}}>{Math.round(creditsPerKeyword_serpintent * jobDetails.results?.summary?.n_keywords).toFixed(0)}</span>}</dd>

                                    { false && <>
                                    <dt className="col-1"><input type="checkbox" id="cache" name="cache" value={activeAddons.cache}
                                      onChange={() => chooseOption('cache')} checked={activeAddons.cache===1}/></dt>
                                    <dt className="col-8"><label htmlFor="cache">Cache SERP Results (1x):</label></dt>
                                    <dd className="col-3">{activeAddons.cache * Math.round(creditsPerKeyword_cached * jobDetails.results?.summary?.n_keywords).toFixed(0)}</dd>

                                    <dt className="col-1"><input type="checkbox" id="topicalmap" name="topicalmap" value="topicalmap"
                                      onChange={() => chooseOption('topicalmap')} checked={activeAddons.topicalmap===1}/></dt>
                                    <dt className="col-8">Recursive TURBO MODE (10x):</dt>
                                    <dd className="col-3">{activeAddons.topicalmap * Math.round(creditsPerKeyword_topicalmap * jobDetails.results?.summary?.n_keywords).toFixed(0)}</dd>
                                    
                                    </>}
                                    
                                  </dl>

                                  </>}


                                    
                                    <><hr className="mt-3"></hr>
                                      { (onetimeCreditsToPurchase + totalCreditsNeeded) > 0 &&
                                      <>
                                        <dl className="row text-end">

                                        </dl>
                                      </>}
                                      <dl className="row text-end">
                                        <dt className="col-9"><b>Total KW Credits for this Job:</b></dt>
                                        <dd className="col-3">{totalCreditsNeeded}</dd>
                                        <hr className="mt-3"></hr>
                                        { (totalCreditsNeeded - onetimeCreditsToPurchase) > 0 && onetimeCreditsToPurchase != totalCreditsNeeded && 
                                        <>
                                        <dt className="col-9">Use available KW Credits:</dt>
                                        <dd className="col-3">{(totalCreditsNeeded - onetimeCreditsToPurchase)}</dd>
                                        </>
                                        }
                                          { onetimeCreditsToPurchase>0 &&
                                          <>
                                          <dt className="col-9"><a href="#credits"><u>Buy additional KW Credits</u></a>:</dt>
                                          <dd className="col-3">+ {onetimeCreditsToPurchase}</dd>
                                          <dt className="col-9">
                                          { currentSubscription != 'free'?
                                            <>Discount for Subscription Plan</>
                                            :
                                            <a href="#upgrade"><u>Discount for Subscription Plan</u></a>
                                          }:</dt>
                                          <dd className="col-3">{(Math.round(onetimeCreditDiscount*100)).toFixed(0)}%</dd>

                                          <dt className="col-9">Total:</dt>
                                          <dd className="col-3">${(Math.round((onetimeCreditsToPurchase*pricePer1000Credits/1000*(1-onetimeCreditDiscount))*100)/100).toFixed(2)}</dd>
                                          
                                          </>
                                          }

                                      </dl>
                                    </>
                                </div>
                              </div>
                            </div>
                          </div>
                          : <>
                            <div className="text-center m-7">
                              Generating preview .... <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                            </div>
                          </>}

                        </div>
                      </div>

                  </div>
                </div>



                  

                      


              { jobDetails && jobDetails?.status == "Complete" && 
              
              
              // start anonymous
              <>
              
              { user ?
                        <>


                
                  { false && !err && userEntity && userEntity?.access && (userEntity?.access.credits?.available_anytime + userEntity?.access.credits?.available_subscription)==0 &&
                    <div className="alert alert-light" role="alert">
                      <p>NOTICE: Jobs cost 300 KW CREDITS when you run out of job.</p>
                      
                      <p>Please check <Link to="/billing/">billing</Link> to upgrade your subscription. See <Link to="/pricing/">pricing</Link> for more details about credits, reports, and memberships.
                      </p>
                    </div>
                  }


              { onetimeCreditsToPurchase>0?
                <>
                  
                {
                // only show if it is free (pay as you go)
                currentSubscription == 'free' &&
                <>
                <div id="upgrade" className="pt-5">
                  <h3 className="mt-0 mb-0">Choose a Subscription Plan <Link to="/pricing/">< BiHelpCircle/></Link></h3>
                </div>

                <div className="">
                  <div className="row mb-0">



                    

                  
                    <div className="col-md mb-3">
                      <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                        <div className="card-header text-center">
                          <input type="radio" className="form-check-input" name="billingPricingRadio" 
                          id={subscriptionOptions[0]['name']} 
                          checked={subscriptionOptions[0]['name'] === selectedSubscriptionOption} 
                          value={subscriptionOptions[0]['name']} 
                          onChange={() => chooseSubscriptionOption(subscriptionOptions[0]['name'])}/>
                          <label className="form-check-label" htmlFor={subscriptionOptions[0]['name']}></label>

                      <h2 className="card-title display-4 text-dark mt-1 mb-1">
                        Pay as you&nbsp;Go
                      </h2>
                        </div>

                        <div className="card-body d-flex justify-content-center p-3">
                          <ul className="list-checked list-checked-primary mb-0">
                            <li className="list-checked-item">5 jobs per month</li>
                            <li className="list-checked-item">First 500 KWs Free</li>
                            <li className="list-checked-item">Pay as you go pricing</li>
                          </ul>
                        </div>
                        {(!user || user && user?.isAnonymous) &&
                          <div className="text-center zi-1 mb-3">
                            <Link to="/register/" className="btn btn-soft-primary">Sign-up</Link>
                          </div>
                        }

                      </div>
                    </div>



                    <div className="col-md mb-3">
                      <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                        <div className="card-header text-center">
                          <input type="radio" className="form-check-input" name="billingPricingRadio" 
                          id="billingPricingRadio3" 
                          value={subscriptionOptions[2]['name']} 
                          checked={subscriptionOptions[2]['name'] === selectedSubscriptionOption} 
                          onChange={() => chooseSubscriptionOption(subscriptionOptions[2]['name'])}/>
                          <label className="form-check-label" htmlFor="billingPricingRadio3"></label>

                          <span className="card-subtitle">Professional</span>
                          <h2 className="card-title display-4 text-dark">
                            $<span id="pricingCount2">49</span>
                            <span className="fs-6 text-muted">/ month</span>
                          </h2>
                          <p className="card-text">Upgrade and Save</p>
                        </div>

                        <div className="card-body d-flex justify-content-center p-3">
                          <ul className="list-checked list-checked-primary mb-0">
                            <li className="list-checked-item">100 jobs per month</li>
                            <li className="list-checked-item">First 500 KWs Free</li>
                            <li className="list-checked-item">Save 50% on credits</li>
                          </ul>
                        </div>
                        {currentSubscription == subscriptionOptions[2]['name'] ?
                          <div className="text-center zi-1 mb-3">
                          <Link to="/billing/" className="btn btn-link">Current Plan</Link>
                          </div> :
                          <div className="text-center zi-1 mb-3">
                            <Link to={"/checkout/?"+"plan=pro_starter&"+"credits="+onetimeCreditsToPurchase+"&edit="+currentUrl+"&launch="+autoLaunch} className="btn btn-soft-primary">{userEntity?.access?.subscription?.membership_id ? <>Buy Now</>: <>7-Day Free Trial</>}</Link>
                          </div>
                        }

                      </div>
                    </div>


                    <div className="col-md mb-3">
                      <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                        <div className="card-header text-center">
                          <input type="radio" className="form-check-input" name="billingPricingRadio" 
                          id={subscriptionOptions[3]['name']}
                          value={subscriptionOptions[3]['name']} 
                          checked={subscriptionOptions[3]['name'] === selectedSubscriptionOption} 
                          onChange={() => chooseSubscriptionOption(subscriptionOptions[3]['name'])}/>
                          <label className="form-check-label" htmlFor={subscriptionOptions[3]['name']}></label>

                          <span className="card-subtitle">Business</span>
                          <h2 className="card-title display-4 text-dark">
                            $<span id="pricingCount2">299</span>
                            <span className="fs-6 text-muted">/ month</span>
                          </h2>
                          <p className="card-text">Upgrade and Save</p>
                        </div>

                        <div className="card-body d-flex justify-content-center p-3">
                          <ul className="list-checked list-checked-primary mb-0">
                            <li className="list-checked-item">600 jobs per month</li>
                            <li className="list-checked-item">First 500 KWs Free</li>
                            <li className="list-checked-item">Save 62% on credits</li>
                          </ul>
                        </div>
                        {currentSubscription == subscriptionOptions[3]['name'] ?
                          <div className="text-center zi-1 mb-3">
                          <Link to="/billing/" className="btn btn-link">Current Plan</Link>
                          </div> :
                          <div className="text-center zi-1 mb-3">
                            <Link to={"/checkout/?"+"plan=team_starter&"+"credits="+onetimeCreditsToPurchase+"&edit="+currentUrl+"&launch="+autoLaunch} className="btn btn-soft-primary">Buy Now</Link>
                          </div>
                        }

                      </div>
                    </div>


                  </div>

                </div>
                </>
              }



                <div id="credits" className="mt-0">
                  <h3 className="mt-5 mb-0">Purchase Additional Credits <Link to="/pricing">< BiHelpCircle/></Link></h3>
                  {
                  // only show if it is free (pay as you go)
                  currentSubscription != 'free' &&
                  <>
                  Currently subscribed to the <b>{subscriptionOptionsDict[currentSubscription]['title']} Plan</b>. See <Link to="/pricing/">pricing</Link> for more options.
                  </>
                  }
                </div>
                


                <div className="">
                    <div className="row mb-3">



                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="essential" 
                            checked={'essential' === selectedCreditsOption} 
                            value='essential' 
                            onChange={() => chooseCreditsOption('essential')}/>
                            <label className="form-check-label" htmlFor="essential"></label>

                            <span className="card-subtitle">The Essentials</span>
                            <h2 className="card-title display-5 text-dark">
                              {essentialQuantity} Credits{ totalCreditsNeeded<minPurchaseRequired && "*" }
                            </h2>
                          </div>

                          <div className="card-body d-flex justify-content-center p-3">
                            <ul className="list-checked list-checked-primary mb-0">
                              { onetimeCreditDiscount>0? 
                              <><li className="list-checked-item fw-bolder display-7"><s>${(Math.round(essentialQuantity*pricePer1000Credits/1000*100)/100).toFixed(2)} pay as you go</s></li>
                              <li className="list-checked-item"> ${(Math.round(essentialQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} subscriber price</li></>
                              :
                              <li className="list-checked-item fw-bolder display-7">${(Math.round(essentialQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} pay as you go</li>
                              }
                              { onetimeCreditDiscount>0? <><li className="list-checked-item">{(Math.round(onetimeCreditDiscount*100)).toFixed(0)}% discount</li></>:
                              <li className="list-checked-item">Upgrade your subscription to get a discount</li>}
                              <li className="list-checked-item">Credits never expire</li>
                            </ul>
                          </div>

                        </div>
                      </div>

                      { totalCreditsNeeded < 8000 && 
                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_8k" 
                            checked={'bulk_8k' === selectedCreditsOption} 
                            value='bulk_8k' 
                            onChange={() => chooseCreditsOption('bulk_8k')}/>
                            <label className="form-check-label" htmlFor="bulk_8k"></label>

                            <span className="card-subtitle">Starter Pack</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 1
                              }).format(bulk_8kQuantity)} Credits
                            </h2>
                          </div>

                          <div className="card-body d-flex justify-content-center p-3">
                            <ul className="list-checked list-checked-primary mb-0">
                              { onetimeCreditDiscount>0? 
                              <><li className="list-checked-item fw-bolder display-7"><s>${(Math.round(bulk_8kQuantity*pricePer1000Credits/1000*100)/100).toFixed(2)} pay as you go</s></li>
                              <li className="list-checked-item"> ${(Math.round(bulk_8kQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} subscriber price</li></>
                              :
                              <li className="list-checked-item fw-bolder display-7">${(Math.round(bulk_8kQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} pay as you go</li>
                              }
                              { onetimeCreditDiscount>0? <><li className="list-checked-item">{(Math.round(onetimeCreditDiscount*100)).toFixed(0)}% discount</li></>:
                              <li className="list-checked-item">Plan discount available</li>}
                              <li className="list-checked-item">Only buy what you need</li>
                            </ul>
                          </div>

                        </div>
                      </div>
                      }


                      <div className="col-md mb-3">
                        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                          <div className="card-header text-center">
                            <input type="radio" className="form-check-input" 
                            name="chooseCredits" 
                            id="bulk_100k" 
                            checked={'bulk_100k' === selectedCreditsOption} 
                            value='bulk_100k' 
                            onChange={() => chooseCreditsOption('bulk_100k')}/>
                            <label className="form-check-label" htmlFor="bulk_100k"></label>

                            <span className="card-subtitle">Buy in Bulk</span>
                            <h2 className="card-title display-5 text-dark">
                              {Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 1
                              }).format(bulkQuantity)} Credits
                            </h2>
                          </div>

                          <div className="card-body d-flex justify-content-center p-3">
                            <ul className="list-checked list-checked-primary mb-0">
                              { onetimeCreditDiscount>0? 
                              <><li className="list-checked-item fw-bolder display-7"><s>${(Math.round(bulkQuantity*pricePer1000Credits/1000*100)/100).toFixed(2)} pay as you go</s></li>
                              <li className="list-checked-item"> ${(Math.round(bulkQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} subscriber price</li></>
                              :
                              <li className="list-checked-item fw-bolder display-7">${(Math.round(bulkQuantity*pricePer1000Credits/1000*(1-onetimeCreditDiscount)*100)/100).toFixed(2)} pay as you go</li>
                              }
                              { onetimeCreditDiscount>0? <><li className="list-checked-item">{(Math.round(onetimeCreditDiscount*100)).toFixed(0)}% discount</li></>:
                              <li className="list-checked-item">Upgrade your subscription to get a discount</li>}
                              <li className="list-checked-item">Credits never expire</li>
                            </ul>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                               

                  <div className="text-center mt-1 mb-5">

                    <h1 className="mt-1 mb-2">
                    The Total is ${(Math.round((onetimeCreditsToPurchase*pricePer1000Credits/1000*(1-onetimeCreditDiscount))*100)/100).toFixed(2)}{ totalCreditsNeeded<minPurchaseRequired && "*" } one time
                    {monthlyMembershipPrice>0 && <> + ${monthlyMembershipPrice} monthly</>}
                    </h1>
                    <p className="mt-1 mb-2">{monthlyMembershipPrice>0 && <> Including your subscription plan for ${monthlyMembershipPrice} per month starting today (cancel anytime)</>}</p>
                    <p className="mt-1 mb-2">Confirm to use {totalCreditsNeeded} credits{reportCreditsExpected>0 ? <> and {reportCreditsExpected} job</> : <></>}.</p>
                    
                    { totalCreditsNeeded<minPurchaseRequired &&
                      <p>*Minimum quantity Required at Checkout</p> }
                      
                    { false &&                    
                      <p className="mt-1 mb-5">After this job finishes, you will have {currentBalance + onetimeCreditsToPurchase - totalCreditsNeeded } credits 
                    
                         {false && creditsForReport==0 && <> and {userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription - reportCreditsExpected} jobs </>} remaining.</p>
                    }
                    
                    <p><Link to={"/checkout/?"+selectedSubscriptionOption_url()+"credits="+onetimeCreditsToPurchase+"&edit="+currentUrl+"&launch="+autoLaunch}
                     className="btn btn-lg btn-primary float-right w-100 w-sm-auto w-lg-100" >Confirm & Pay Now</Link></p>

                    {
                      // free user so suggest they sign-up
                      user && user?.isAnonymous && currentSubscription=='free' && selectedSubscriptionOption=='free' &&
                      <p><Link to={"/free-trial/?url="+currentUrl} className="btn btn-lg btn-soft-primary float-right w-100 w-sm-auto w-lg-100" >{isLoading? "Loading..." : "Register for 5 More Free Jobs"}</Link></p>
                    }
                  </div>
                  </>
                  :
                  <>
                  <div className="text-center mt-1 mb-5">
                    
                    {totalCreditsNeeded>0 &&
                      <p>
                        This job will use {totalCreditsNeeded} of your {currentBalance + onetimeCreditsToPurchase} available credits.
                      </p>
                    }
                    { false && reportCreditsExpected>0 && !user?.isAnonymous &&
                      <p>
                        This tool will use {reportCreditsExpected} of your {userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription} available jobs
                      </p>
                    }

                    {currentSubscription=='free' ? <>
                    <p><button type="button" className="btn btn-primary float-right w-100 w-sm-auto w-lg-100" 
                      onClick={handleButtonClick} disabled={isLoading}>Continue with Limited Preview</button></p>
                    </> : <>
                    <p><button type="button" className="btn btn-primary float-right w-100 w-sm-auto w-lg-100" 
                      onClick={handleButtonClick} disabled={isLoading}>Confirm & View Results</button></p>
                    </>
                    }

                    {
                      // free user so suggest they sign-up
                      user && user?.isAnonymous && currentSubscription=='free' && selectedSubscriptionOption=='free' &&
                      <p><Link to={"/free-trial/?url="+currentUrl} className="btn btn-lg btn-soft-primary float-right w-100 w-sm-auto w-lg-100" >{isLoading? "Loading..." : "Register for 5 More Free Jobs"}</Link></p>
                    }
                  </div>
                  </>

              }
              
              
              </>
                    : 
                    // signup for free trial with redirect to this page
                    <>
                      <div className="text-center mb-10">
                        <Link to={"/register/?url=/analysis/"+analysis_key+"/preview/"+job_id} className="btn btn-primary float-right w-150 w-sm-auto w-lg-125" disabled>Sign-up for {totalCreditsNeeded>0? "to See Options" : "for Free Trial"}</Link>
                      </div>
                    </>
                    }

              { onetimeCreditDiscount>0 && false &&
              <div className=" mt-1 mb-10">
              * <b>Limited Time Offer:</b> We reserve the right to adjust the discount. This may impact the estimated price comparisons for future credit usage.
              </div>
              }

              </>}


            </div>




                </form>
              </div>

              
              
              
              </main>
            </div>


      </Layout>
    </>
  );
}

export default AnalysisPreview;


import React, { useEffect, useState } from "react";





function ResultsPageSegments({jobDetails}) {



    const Subsection = ({subsection, index}) => {
        return (
            <>
                    <div className="col-lg-12 p-2 pt-2 pb-2 hover-highlight" id={"segment-"+(index+1)+"-"+index}>
                        <div className="">
                                <div>{subsection?.keyphrases.map((kw,index)=>{return kw?.key_phrase+" "})}</div>
                        </div>
                    </div>
            </>
        )
    }
  return (
    <>
        
        <div>
        {jobDetails.results.segments.map((message, index) => {
            return <div className="row" key={"schema_"+index.toString()}>
                    <div className="col-lg-12 mb-3 mb-lg-5" id={"segment-"+(index+1)}>
                        <div className="card h-100">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Segment {index+1}: {message?.title && <>&lt;{message.title?.markup.toUpperCase()}&gt; </>}{message?.title && <>{message.title?.text.toUpperCase()}</>}</h4>
                            </div>
                                {console.log(message)}
                            <div className="card-body">
                                <h4>Segment Summary</h4> {message?.title?.text_summarized}<br/>
                                &nbsp;<br/>
                                <h4>Sub-segments</h4>
                                { message?.subsegments.map((subsection,sindex) =>{
                                    return  <Subsection subsection={subsection} index={sindex}></Subsection>
                                })}
                                <pre style={{"display":"none"}}>{JSON.stringify(message, null, 1)}</pre>
                            </div>
                        </div>
                    </div>
                </div>;
        })}
        </div>
    </>
  );
}

export default ResultsPageSegments;
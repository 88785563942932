import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";


import Tap from "@tapfiliate/tapfiliate-js";

import Safe from 'react-safe';
import { Helmet } from "react-helmet"
import Navbar from '../components/Navbar';

import { auth } from "../pages/authentication/firebase";
import axios from 'axios'
import TermsPopup from "../components/TermsPopup";

import "./Layout.css"


// Please make sure to call this method only once!
import Gleap from 'gleap';
Gleap.initialize("1icGyEum364Q1RcW9b57dHbi0GDVUepd");

Tap.init('40145-f51553');



const Layout =({children, hideNavbar, simpleNavbar, fullScreen, allowAnonymous, hideControls, logoLinkToPublicHome, hideTermsAccepted, isPublic, customReportingLayout, pageTitle}) =>{
    const [showTerms, setShowTerms] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    
    const [showComposer, setShowComposer] = useState(false);
    
    const [showActivity, setShowActivity] = useState(false);
    const [userActivity, setUserActivity] = useState(false);
  
    const toggleShowComposer = (e) => {
        setShowComposer(!showComposer)
    }
    const navigate = useNavigate();
    const [userEntity, setUserEntity] = useState({})

    const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";


    useEffect(() => {
      if (loading) return;
      Gleap.identify(user?.uid, {
        name: userEntity?.firstName,
        email: userEntity?.email,
        phone: userEntity?.phone,
        customData: {
          plan: userEntity?.access?.subscription?.membership_id,
          planInCart: userEntity?.planInCart,
        },
      });
    }, [userEntity, loading]);
    useEffect(() => {
      if (loading) return;
        if (!user && !allowAnonymous) return navigate("/login/"+window.location.search);
    }, [user, loading]);

    useEffect(()=>{
      if (loading) return;
      if (user) {
  
        axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setUserEntity(response.data.user_entity)
          if( !hideTermsAccepted && !('terms_accepted_on' in response.data.user_entity) ){
            setShowTerms(true)
          }
        }).catch(error => {
          console.log(error)
        });
        
      };
      
    }, [user, loading])
    


    return(
        <>

        <Helmet>
          <title>{pageTitle? pageTitle+" - Content Curator App" : "Content Curator App"}</title>
        </Helmet>
        {!process.env.REACT_APP_GOOGLEANALYTICS_TRACKINGID && process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID && 
        <Helmet><script async src={"https://www.googletagmanager.com/gtag/js?id="+process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID}></script></Helmet>
        }
        { (!process.env.REACT_APP_GOOGLEANALYTICS_TRACKINGID && process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID && !process.env.REACT_APP_GOOGLEANALYTICS4_DEBUG ) && 
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '`+process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID+`');`
            }]}
        />
        }
        {process.env.NODE_ENV !== 'development' &&
        <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "f1ux7q0use");`
            }]}
        />
        }
        { (!process.env.REACT_APP_GOOGLEANALYTICS_TRACKINGID && process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID && process.env.REACT_APP_GOOGLEANALYTICS4_DEBUG) && 
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '`+process.env.REACT_APP_GOOGLEANALYTICS4_TRACKINGID+`',{ 'debug_mode':true });`
            }]}
        />
        }
        { (process.env.REACT_APP_MAUTIC_URL ) && 
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
                w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
                m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
            })(window,document,'script','`+process.env.MAUTIC_URL+`','mt');

            mt('send', 'pageview');`
        }]}
        />
        }
        { true && 
        // <!-- Twitter conversion tracking base code -->
            <Helmet 
            script={[{ 
            type: 'text/javascript', 
            innerHTML: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('config','ofrd2');`
        }]}
        />
        }

        {
        // onlyshow if done loading and there is a user or if allowAnonymous (ie start)
        ((!loading && user ) || allowAnonymous) && <>
        { !hideNavbar &&
            <Navbar user={user} simpleNavbar={simpleNavbar} userEntity={userEntity} loading={loading} showComposer={showComposer} toggleShowComposer={toggleShowComposer} hideControls={hideControls} logoLinkToPublicHome={logoLinkToPublicHome}/>
        }
        { (showTerms && !isPublic) ? <TermsPopup user={user}/> : ""}
        <main id="content" role="main" className="main" style={{"overflow":"hidden", "display": "flex",
                "flexDirection": "row",
                "flex": "1 1 auto",}}>

        
        { !fullScreen? 
        <>
            {customReportingLayout? 
            <div id="reportLayout" className="reportLayout">
                {children}
            </div> :
            <div id="catalog" className="catalog content container-fluid pt-0 pt-lg-4 px-2">
                {children}
            </div>
            } 
            { showComposer && 
            <div id="composer" className="composer content container-fluid">
                <h1>Composer</h1>
                <p>A new way to actively curate content using our built in content editor.</p>
                <p>This editor combines the power of content curation with AI recommended topics, auto import content brief, and automatic first drafts.</p>
                <b>Fast:</b>
                <ul>
                  <li>Easily pull in ideas and existing segments</li>
                  <li>Add a unique personal touch</li>
                  <li>Have our AI rewrite the segment</li>
                </ul>
                <b>Organized:</b>
                <ul>
                  <li>Start new content from a template</li>
                  <li>Create and save templates</li>
                </ul>
                <b>Intelligent:</b>
                <ul>
                  <li>Get AI content recommendations</li>
                  <li>Quickly verify the message is on topic</li>
                  <li>Save and continue later</li>
                </ul>
                <b>Integrated:</b>
                <ul>
                  <li>Collaborate with others</li>
                  <li>Export to html, markdown, or Json via API</li>
                </ul>
                <p>Early access is limited to a select group of Beta users.</p>
                <div className="text-center">
                  <Link to="/support/"><button className="btn btn-primary">Request Access to Composer</button></Link>
                </div>
                
            </div>
            }

        </> :
        <>
            {children}
        </>
        }




            <Safe.script src="/assets/vendor/jquery/dist/jquery.min.js"></Safe.script>
            <Safe.script src="/assets/vendor/jquery-migrate/dist/jquery-migrate.min.js"></Safe.script>
            <Safe.script src="/assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"></Safe.script>
            <Safe.script src="/assets/js/hs.core.js"></Safe.script>
        </main>
        </>}
        </>
    )
}

export default Layout;
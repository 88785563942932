import React from 'react';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import Moment from 'moment';

const RecentActivity =({userActivity, loading, showActivity}) =>{
    return(
        <>

            <div className="card">

                <div className="table-responsive datatable-custom">
                <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{
                        "columnDefs": [{
                            "targets": [0],
                            "orderable": false
                            }],
                        "order": [],
                        "info": {
                            "totalQty": "#datatableWithPaginationInfoTotalQty"
                        },
                        "search": "#datatableSearch",
                        "entries": "#datatableEntries",
                        "pageLength": 12,
                        "isResponsive": false,
                        "isShowPaging": false,
                        "pagination": "datatablePagination"
                        }'>
                    <thead className="thead-light">
                    <tr>
                        <th>Report Type</th>
                        <th className="table-column-ps-0">Name</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    
                    <tbody>
                          { loading && ( userActivity == null || !showActivity) &&
                            <>
                            <tr><td colSpan="3">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Loading...
                            </td></tr>
                            </>
                          }
                          { userActivity != null && userActivity.length==0 && !loading && <>
                            <>
                            <tr><td colSpan="3">
                                <div>Please click [ <Link to="/tools/">+ New</Link> ] above to run your first report!</div>
                            </td></tr>
                            </>
                            </>}
                        {
                        userActivity.map((oneKey,i)=>{
                            return (
                                <tr key={i}>
                                    <td><span className="legend-indicator bg-success"></span>{oneKey['status']} {oneKey.data && oneKey.data.inputs && oneKey.data.inputs.report_type}</td>
                                    <td className="table-column-ps-0" style={{
                                        "maxWidth":"400px",
                                        "whiteSpace": "nowrap",
                                        "overflow": "hidden",
                                        "textOverflow": "ellipsis"}}>
                                        <Link to={"/reports/"+oneKey['job_id']}>{oneKey['info']}</Link>
                                    </td>
                                    <td>{Moment(oneKey['created_at']).format('MMM D, yyyy')}</td>
                                </tr>
                            )
                        })
                        }
                        
                    </tbody>
                </table>
            </div></div>
        </>
    )
}

export default RecentActivity;
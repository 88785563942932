import React from 'react';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import SharePopup from "../components/SharePopup";
import { Archive, ArrowClockwise } from 'react-bootstrap-icons';

import Moment from 'moment';

const RecentAnalysis =({userAnalysis, loading, user}) =>{
    return(
        <>
            <div className="card">
                <div className="table-responsive datatable-custom">
                <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{
                        "columnDefs": [{
                            "targets": [0],
                            "orderable": false
                            }],
                        "order": [],
                        "info": {
                            "totalQty": "#datatableWithPaginationInfoTotalQty"
                        },
                        "search": "#datatableSearch",
                        "entries": "#datatableEntries",
                        "pageLength": 5,
                        "isResponsive": false,
                        "isShowPaging": false,
                        "pagination": "datatablePagination"
                        }'>
                    <thead className="thead-light">
                    <tr>
                        <th className="table-column-ps-1">Name</th>
                        <th>Updated At</th>
                        <th>Tool</th>
                        <th></th>
                    </tr>
                    </thead>
                    
                    <tbody>
                          { loading && ( userAnalysis == null) &&
                            <>
                            <tr><td colSpan="4">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Loading...
                            </td></tr>
                            </>
                          }
                          { userAnalysis != null && userAnalysis.length==0 && !loading && <>
                            <>
                            <tr><td colSpan="4">
                                <div>Please click [ <Link to="/tools/">+ New</Link> ] above to run your first report!</div>
                            </td></tr>
                            </>
                            </>}
                        {
                        userAnalysis.map((oneKey,i)=>{
                            return (
                                <tr key={i}>
                                    <td className="table-column-ps-1" style={{
                                        "whiteSpace": "nowrap",
                                        "overflow": "hidden",
                                        "textOverflow": "ellipsis"}}>
                                            
                                            
                                            { /// determine status
                                             oneKey?.status== 'Initializing' && <span className="legend-indicator"></span> }
                                            { oneKey?.status== 'Processing' && <span className="legend-indicator bg-warning"></span> }
                                            { oneKey?.status== 'Failed' && <span className="legend-indicator bg-danger"></span> }
                                            { (oneKey?.status!= 'Failed' && oneKey?.status!= 'Processing' && oneKey?.status!= 'Complete') && <span className="legend-indicator bg-warning"></span> }
                                            
                                            {oneKey?.last_action != 'results' ? <>{ oneKey?.status== 'Complete' && <span className="legend-indicator bg-success spinner-grow"></span> }</> : <>{ oneKey?.status== 'Complete' && <span className="legend-indicator bg-success"></span> }</>
                                            /// end status
                                            }
                                            
                                            <span className="d-none d-lg-inline">{oneKey?.last_action != 'results' ? <>Please Confirm </>: <>{oneKey?.status}</>}</span>
                                            
                                            
                                            <Link to={"/analysis/"+oneKey?.analysis_key+"/"+oneKey?.last_action+"/"+oneKey?.last_job_id}>
                                            {oneKey?.last_action != 'results' && <>[{oneKey?.last_action.toUpperCase()}]</>} {oneKey['name']}</Link>
                                    </td>
                                    <td style={{
                                        "maxWidth":"20px",
                                        "whiteSpace": "nowrap",
                                        "overflow": "hidden",
                                        "textOverflow": "ellipsis"}}>{Moment(oneKey['created_at']).format('MMM D hh:mm:ss')}</td>
                                    <td style={{
                                        "width":"120px",
                                        "maxWidth":"180px",
                                        "overflow": "hidden"}}><span className="badge bg-soft-warning text-dark text-uppercase">{oneKey?.type.replace("-"," ")}</span></td>
                                    <td>
                                        <Link to={"/tools/"+oneKey?.type+"/"}>
                                            <button className="btn btn-sm btn-ghost-primary" title={"New "+oneKey?.type.replace("-"," ")}><ArrowClockwise/></button>
                                        </Link>
                                        { false &&
                                            <button className="btn btn-sm btn-ghost-primary" title="Archive"><Archive/></button>}
                                        {oneKey?.last_action == "results" && 
                                            <SharePopup analysis_key={oneKey?.analysis_key} job_id={oneKey?.last_job_id} user={user} inline={true}></SharePopup>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        }
                        
                    </tbody>
                </table>
            </div></div>
        </>
    )
}

export default RecentAnalysis;
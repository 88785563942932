import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams, Link } from "react-router-dom";
import { auth, logout } from "../authentication/firebase";
import axios from 'axios'
import { io } from "socket.io-client";
import { Download, PlusCircleDotted } from 'react-bootstrap-icons';


import Layout from "../../components/Layout";
import WebSocketCall from "../../components/WebSocketCall";
import Spinner from 'react-bootstrap/Spinner';

import "./JobDetails.css"

import SharePopup from "../../components/SharePopup";

import DisplayNicheDiscoveryV1 from "./JobDetails_template_niche-discovery_1";
import DisplayKeyphraseExtractorV1 from "./JobDetails_template_keyphrase-extractor_1";
import DisplayDomainAnalysisV1 from "./JobDetails_template_domain-analysis_1";
import DisplayPageInspectionOriginalityV1 from "./JobDetails_template_page-inspection-originality_1";
import DisplayPageInspectionInclusivityTextV1 from "./JobDetails_template_inclusivity-text_1";

function JobDetails() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [userEntity, setUserEntity] = useState({})

  const [jobDetails, setJobDetails] = useState({})
  const [templateVersion, setTemplateVersion] = useState(null)
  const [reportParts, setReportParts] = useState([])
  const [socketInstance, setSocketInstance] = useState("");
  const [loadingSocket, setLoadingSocket] = useState(true);

  // for users that aren't allowed to see the report
  const [isRedacted, setIsRedacted] = useState(false);

  // for public reports
  const [hideControls, setHideControls] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  let { job_id } = useParams();
  const [warn, setWarn] = useState(null);


  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  const REACT_APP_API_SOCKET_DOMAIN = process.env.REACT_APP_API_SOCKET_DOMAIN || "127.0.0.1:5000";

  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setJobDetails(response.data)
      }).catch(error => {
        console.log(error)
      });

    } else {
      // hide controls because it is anonymous
      setHideControls(true)
      // get the report with anonymous
      axios.get(api_url + '/v1/job/'+job_id, {headers: {'anonymous':true}}).then(response => {
        setJobDetails(response.data)
      }).catch(error => {
        console.log(error)
      });

    }

    // connect to socket with room
    const socket = io(REACT_APP_API_SOCKET_DOMAIN +"/", {
      transports: ["websocket"],
      cors: {
        origin: api_url+"/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (data) => {
      socket.emit("join",{"room":"room_job_"+job_id})
    });

    setLoadingSocket(false);



    // determine if this report should be redacted or not
    if( user.isAnonymous ){
      setIsRedacted(true)
    }
  }, [user, loading])


  const updateJobStatus = (status) => {
    // update the status
    var newJobDetails = jobDetails
    newJobDetails['status'] = status
    setJobDetails(newJobDetails)
  }
  useEffect(() => {
    if (loadingSocket) return;
    if (socketInstance) {
    
    // process status messages
    socketInstance.on("status", (data) => {
      if( data?.value =='Complete' || data?.value =='Failed' ){
        // console.log("Complete refresh")
        // re-pull everything
        axios.get(api_url + '/v1/job/'+job_id, {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
          setJobDetails(response.data)
        }).catch(error => {
          console.log(error)
        });
      } else {
        updateJobStatus(data.value)
      }
    });


    socketInstance.on("results", (data) => {
      // innitializie results if doesn't exist
      var newJobDetailsResults = jobDetails?.results? jobDetails.results : {};
      for (var item in data?.data) {
        newJobDetailsResults[item] = data.data[item]
      }
      var newJobDetails = jobDetails;
      newJobDetails['results'] = newJobDetailsResults
      setJobDetails( newJobDetails )

    });


    // put this into reactive element so it updates as things are coming in!
    if(jobDetails?.template?.parts){
      setReportParts( jobDetails?.template?.parts )
    } else if(jobDetails.results) {
      setReportParts( [{
        "name":"Raw Results",
        "id":"raw-results",
        "displayTemplate":"raw-results"
      }] )
    }

    setTemplateVersion(jobDetails?.template?.version)
  }
  }, [socketInstance, loadingSocket, jobDetails]);




  var showComposer = false
  const setData = (showComposerVal) => {
    showComposer = showComposerVal
  }

  useEffect(() => {
    // redirect if no credits and anonymous
    if( user?.isAnonymous && userEntity?.access && (userEntity?.access?.credits?.available_anytime + userEntity?.access?.credits?.available_subscription)==0){
      navigate("/register/?restart=true&url="+encodeURIComponent(window.location.pathname+window.location.search))
    }

    // warn if email not verified
    if( user && userEntity && user?.email && user?.emailVerified == false){
      setWarn("Please verify your account by clicking the link in your email.")
    }
  }, [user, userEntity]);


  useEffect(()=>{
    if(jobDetails?.isPublic){
      setIsPublic(true)
    }
    // turn off socket if completed
    if( jobDetails['status']=="Completed" || jobDetails['status']=="Failed" ){
      setLoadingSocket(true)
    }


    




  }, [jobDetails])
    

  // if there is a template then use that!
  // otherwise use the default report by part
  // if no parts, then show all as json-raw
  const dipslayJobResults = ( jobDetails, templateVersion ) => {

    if (jobDetails.results?.error){
      return(<div className="reportPart content" id="raw-results"> <pre>{JSON.stringify(jobDetails?.results, null, 2)}</pre> </div>)
    }
    //
    // put the latest versions at the top
    //
    // niche-discovery view template v1
    if ( jobDetails?.inputs?.report_type == "niche-discovery" && jobDetails?.template?.version == 1 ){
      return( <DisplayNicheDiscoveryV1 jobDetails={jobDetails} reportParts={reportParts} isRedacted={isRedacted} user={user}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "niche-discovery" && jobDetails?.template?.version == null ){
      return( <>.</> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "keyphrase-extractor" || jobDetails?.inputs?.report_type == "page-inspection" ){
      return( <DisplayKeyphraseExtractorV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "domain-analysis" ){
      return( <DisplayDomainAnalysisV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "page-inspection-originality" ){
      return( <DisplayPageInspectionOriginalityV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 
    // niche-discovery view template null
    else if ( jobDetails?.inputs?.report_type == "inclusivity-text" ){
      return( <DisplayPageInspectionInclusivityTextV1 jobDetails={jobDetails} reportParts={reportParts}/> )
    } 

    
    // default view for any report type
    else if (jobDetails?.inputs?.report_type){
      
      return( 
        <div className="reportPart content" id="summary">
          Display results for {jobDetails?.inputs?.report_type}
          <pre>{JSON.stringify(jobDetails?.results, null, 2)}</pre>
        </div>
      )
    
    } 
    
    // Otherwise it is still loading...
  }



  const public_url = process.env.REACT_APP_PUBLIC_URL || "http://127.0.0.1:8000";

  // Download file
  const downloadFile = () => {
    // text content 
    axios({
        url: api_url + '/v1/job/'+job_id+'?format=csv',
        method: "GET",
        headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken},
        responseType: "blob" 
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            'contentcurator-results-job-'+job_id+'.csv'
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
  }

  return (
    <>
      <Layout pageTitle={"Report Details"} allowAnonymous={true} hideControls={hideControls} isPublic={isPublic} customReportingLayout={true}>
        <div className="page-header job-header container-fluid" style={{"marginBottom":"0","paddingTop":"1rem"}}>
          <div className="row align-items-end" style={{"flexWrap":"unset"}}>
            <div className="col-sm mb-2 mb-sm-0">

             
              { jobDetails.status== 'Complete' && <span className="legend-indicator bg-success"></span> }
              { jobDetails.status== 'Complete' && <span className="legend-indicator bg-success"></span> }
              { (jobDetails.status== 'Processing' || jobDetails.status== 'In Queue') && <span className="legend-indicator spinner-grow"></span> }
              { jobDetails.status== 'Failed' && <span className="legend-indicator bg-danger"></span> }
              { (jobDetails.status!= 'Failed' && jobDetails.status!= 'Processing' && jobDetails.status != 'In Queue' && jobDetails.status!= 'Complete') && <span className="legend-indicator bg-warning"></span> }
              
              {jobDetails.status} &nbsp;|&nbsp; {jobDetails?.inputs?.report_type && <><Link to="/tools/" style={{color:"var(--bs-body-color)"}}>TOOLS</Link> &nbsp;|&nbsp; <Link to={"/tools/"+jobDetails?.inputs?.report_type+"/"} style={{color:"var(--bs-body-color)"}}>{jobDetails?.inputs?.report_type.replace("-"," ").toUpperCase()} +</Link></>}
            
              <h1 style={{"whiteSpace":"nowrap"}}>{jobDetails['name']}</h1>
              
            </div>
            { !hideControls &&
            <>
              <div className="col-auto align-baseline">
                <Link className="btn btn-outline-primary" to={"/tools/"+jobDetails?.inputs?.report_type}>
                  + New
                </Link>
              </div>
              { user && !user.isAnonymous &&
              <div className="col-auto align-baseline">
                <SharePopup job_id={job_id} user={user}></SharePopup>
              </div>
              }
              { user && !user.isAnonymous && jobDetails?.results?.data && 
              <div className="col-auto align-baseline">
                <button className="btn btn-outline-primary" onClick={downloadFile}><Download/></button>
              </div>
              }
              { user && user.isAnonymous && jobDetails?.results?.data && job_id &&
              <div className="col-auto align-baseline">
                <Link to={"/register/?url=/reports/"+job_id} className="btn btn-outline-primary" disabled><Download/></Link>
              </div>
              }
            </>
            }
          </div>

          <div>
          <ul className="nav nav-tabs page-header-tabs jobDetailsMenu" style={{"flexWrap":"nowrap", "overflowX":"scroll", "overflowY":"hidden"}}>
            
            {
              reportParts.length>0 && reportParts.map( (reportPart,i)=> {

                return(
                  <li className="nav-item" key={"menu_"+i}>
                    <span style={{"whiteSpace": "nowrap"}}><a className="nav-link jobDetailsMenuNavLink" href={"#"+reportPart['id']}>
                      {reportPart['name']}
                    </a></span>
                  </li>
                )
              })
            }
            { (reportParts.length==0 && (jobDetails['status']!="Completed" || jobDetails['status']!="Failed") ) &&
              <li className="nav-item">
                  <a className="nav-link jobDetailsMenuNavLink" href="#"><Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /></a>
              </li>
            }


            <li className="nav-item">
              <a className="nav-link jobDetailsMenuNavLink" href="#log">
                Log
              </a>
            </li>
          </ul>
          </div>
        </div>
        <div className="job-results">


            {user && user.isAnonymous && 
            <div className="reportPart content">
              <div className="alert alert-soft-dark" role="alert">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-xl" src="/assets/svg/illustrations/oc-megaphone.svg" alt="Image Description" data-hs-theme-appearance="default"/>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h3 className="alert-heading mb-1">Attention!</h3>
                    <p className="mb-0">You are using guest access. See <a href={"/pricing/"}>pricing</a> or <Link to="/register/">start free trial</Link> to unlock all the data and get more reports!</p>
                  </div>
                </div>
              </div>
            </div>
            }



            { 
              // if there is a template then use that!
              // otherwise use the default report by part
              // if no parts, then show all as json-raw
              dipslayJobResults( jobDetails, templateVersion )
            }



            <div className="reportPart content" style={{"minHeight":"80vh"}}>
              <h1 id="log" className="pt-4">Log</h1>

              <div className="line">
                {jobDetails?.logs && jobDetails?.logs.length && <>
                  {jobDetails.logs.map((message, index) => {
                    return <div key={"log_"+message?.created}>{message.value? message.value : <>{JSON.stringify(message, null, 0)}</>}</div>;
                  })}
                </>}
                  {
                  !loadingSocket && <WebSocketCall socket={socketInstance} room={"room_job_"+job_id} />}
              </div>
              {(!jobDetails?.logs || jobDetails.status=="Processing") &&
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  
                </>
              }
              {!jobDetails?.results &&
              <p className="mt-5">
                Click REFRESH if page doesn't auto refresh:)
              </p>
              }

            </div>
        </div>
      </Layout>
    </>
  );
}

export default JobDetails;

import React, { useEffect, useState } from 'react';
import Layout from "../../components/Layout";
import Navbar from '../../components/Navbar';
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../pages/authentication/firebase";
import RecentActivity from "../../components/RecentActivity";

import axios from 'axios'

const AccountUsage = () => {

  const [user, loading, error] = useAuthState(auth);
  const [showTerms, setShowTerms] = useState(false);
  const [showActivity, setShowActivity] = useState(false);

  const navigate = useNavigate();
  const [userEntity, setUserEntity] = useState({})
  const [userActivity, setUserActivity] = useState([])
  
  const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  
  useEffect(()=>{
    if (loading) return;
    if (user) {

      axios.get(api_url + '/v1/user/activity', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserActivity(response.data)
        // jump to tools if no activity yet ( because just registered )
        if (response.data == 0){
          navigate("/tools/"+window.location.search) 
        } else {
          setShowActivity(true)
        };
      }).catch(error => {
        console.log(error)
      })

      axios.get(api_url+'/v1/user', {headers: {'Authorization':user.accessToken,'Refreshtoken':user.refreshToken}}).then(response => {
        setUserEntity(response.data.user_entity)
        if( !('terms_accepted_on' in response.data.user_entity) ){
          setShowTerms(true)
        }
      }).catch(error => {
        console.log(error)
      });
      
    };
    
  }, [user, loading])
  return (
    <>
    <Layout fullScreen={true}>
        <div className="content container-fluid" style={{"overflowY":"auto"}}>
          {showActivity? <RecentActivity userActivity={userActivity}/>: ""}
        </div>
    </Layout>
    </>
  );
};
  
export default AccountUsage;